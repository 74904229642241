import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";

function Mobilecontdroller(props) {
  function fromchanger() {
    props.setFormcontrol("block");
  }
  return (
    <>
      <div className="mobilecontrol">
        <div className="row">
          <div className="col-12 mobicon">
            <p>
              Please contact us with your question and needs, our
              <br className="fhu"></br> team would be delighted to assist you
              promptly.
            </p>
          </div>
          <div className="col-12 buttonmob">
            <div className="display-flex iconcenter">
              <a
                className="homecall textimg paddimg firsticon"
                href="https://www.facebook.com/adhventhahospital"
              >
                <img src="../../photo/facebook.png" alt="" />
              </a>
              <a
                className="homecall textimg paddimg firsticon"
                href="https://www.instagram.com/adhventhahospital/"
              >
                <img src="../../photo/instagram.png" alt="" />
              </a>
              <a
                className="homecall textimg paddimg firsticon"
                href="https://maps.app.goo.gl/NfNTYFuBij8Piqkn7"
              >
                <img src="../../photo/google-maps.png" alt="" />
              </a>
              <button
                onClick={fromchanger}
                style={{ border: "0px" }}
                className="homecall textimg paddimg firsticon backchan"
                href=""
              >
                <img src="../../photo/contacta.png" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="sooboxone">
          <div className="sooin">
            <a href="https://wa.me/9342595404?text=Hello, How can I help you??">
              <div className="soonon">
                <LazyLoadImage src="../../photo/whatsapp.png"></LazyLoadImage>
              </div>
            </a>
          </div>
          <div className="soointo">
            <a href="tel:9342595404">
              <div className="soonon">
                <LazyLoadImage src="../../photo/telephone.png"></LazyLoadImage>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(Mobilecontdroller);
