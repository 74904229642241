import { memo, useEffect, useRef } from "react";
import LazyLoad from "react-lazy-load";
import { Link, Outlet } from "react-router-dom";
import Fade from "react-reveal/Fade";
import { useState } from "react";

function Filebox({
  name,
  img,
  bodyes,
  content,
  setHello,
  num,
  setOne,
  setTwo,
  setThree,
  setThreea,
  threea,
  hello,
}) {
  const [numer, setNumer] = useState(0);
  return (
    <>
      <div className="col-xl-4 col-md-6 p-222">
        <Fade opacity>
          <div className="row hiders p-3 borderless relative">
            <Link
              onClick={() => {
                setNumer(numer + 1);
                {
                  if (numer % 2 == 0) {
                    setHello(num);
                    setNumer(numer + 1);
                  } else {
                    if (hello !== num) {
                      setHello(num);
                    } else {
                      setHello("");
                    }
                  }
                }
                setOne(img);
                setTwo(name);
                setThree(bodyes);
                setThreea(threea + 1);
              }}
              className="arrower"
            >
              <img
                src={
                  hello === num
                    ? "../../photo/error2.png"
                    : "../../photo/line.png"
                }
                alt=""
              />
            </Link>
            <div className="col-3 p-0  pt-1 higerli">
              <LazyLoad className="w-100">
                <div
                  style={{ backgroundImage: `url("${img}")` }}
                  className="w-100 alianer"
                ></div>
              </LazyLoad>
            </div>
            <div className="col-9 pt-1 pl-1 linetext h6ofser">
              <h6 style={{ fontSize: "18px" }}>{name}</h6>
              <p>{content}</p>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}
export default memo(Filebox);
