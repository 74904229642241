import { useState } from "react";
import { memo, useEffect, useRef } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
function Minibody({ img, content, name, classone, ques, num }) {
  if (img !== "") {
    return (
      <>
        <div className="rajendra">
          <div>
            <LazyLoad className="findwidther">
              <img src={img} alt="" />
            </LazyLoad>
          </div>
          {name === "" ? (
            <p>{content}</p>
          ) : num === 0 ? (
            <h6>{name}</h6>
          ) : (
            <h6 style={{ marginTop: "10px" }}>{name}</h6>
          )}
        </div>
        {name === "" ? <></> : <p>{content}</p>}
      </>
    );
  }
  if (ques === "") {
    return (
      <>
        <>
          <div className="col-12">
            <h5>{name}</h5>
            <p>{content}</p>
          </div>
        </>
      </>
    );
  } else {
    return (
      <>
        <>
          <div className="col-12">
            {name === "" ? <></> : <h5>{name}</h5>}
            <p>
              <strong>{`${ques}  `}</strong>
              {content}
            </p>
          </div>
        </>
      </>
    );
  }
}

function Displayflopup({
  bodyes,
  img,
  name,
  classone,
  hello,
  threea,
  setHello,
  setOne,
  setTwo,
  setThree,
  setThreea,
  num,
}) {
  const myrefa = useRef();
  function scrollerevent() {
    let numer = myrefa.current.offsetTop;

    function namey() {
      if (numer !== 0) {
        let numbers = numer;
        window.scrollTo({ top: `${numbers - 100}`, behavior: "instant" });
      }
    }
    namey();
  }
  useEffect(() => {
    scrollerevent();
  }, [threea]);
  const [numer, setNumer] = useState(0);
  return (
    <>
      <div ref={myrefa} className={`col-12 my-3 p-222 ${classone}`}>
        <div className="row bg-white  py-3 px-2">
          <div className="col-lg-6">
            <LazyLoad>
              <img className="w-100" src={img} alt="" />
            </LazyLoad>
          </div>
          <div
            style={{ position: "relative" }}
            className="col-lg-6 marginer992"
          >
            <h4 className="mb-3">{name}</h4>
            {bodyes.map((elem, num) => {
              const { img, content, name, ques } = elem;
              {
                return (
                  <>
                    <Minibody
                      img={img}
                      content={content}
                      name={name}
                      ques={ques}
                      num={num}
                    />
                  </>
                );
              }
            })}
            <div className="jsdcknbs">
              <img
                onClick={() => {
                  setHello("");
                }}
                className="imgererr"
                src="../photo/error2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(Displayflopup);
