import { memo, useContext } from "react";
import { data1 } from "../../App";
import { Link } from "react-router-dom";

function Newmobsecond(props) {
  const setServiceseta = useContext(data1);
  const menu = [
    {
      nmae: "Interventional Radiology",
      img: "../../photo/Radiologya.png",
      to: "/mainservice/radiology",
    },
    {
      nmae: "Orthopaedics",
      img: "../../photo/Orthopaedics.png",
      to: "/mainservice/orthopaedics",
    },
    {
      nmae: "Neurosurgery",
      img: "../../photo/Neurosurgerya.png",
      to: "/mainservice/neurosurgery",
    },
    {
      nmae: "Internal Medicine",
      img: "../../photo/medicine.png",
      to: "/mainservice/internalmedicine",
    },
    {
      nmae: "Cardiology",
      img: "../../photo/hart.png",
      to: "/mainservice/cardiology",
    },
    {
      nmae: "Paediatrics",
      img: "../../photo/Paediatrics.png",
      to: "/mainservice/paediatrics",
    },
    {
      nmae: "Plastic surgery",
      img: "../../photo/Plasticsurgery.png",
      to: "/mainservice/plasticsurgery",
    },
    {
      nmae: "General Surgery",
      img: "../../photo/generals.png",
      to: "/mainservice/generalsurgeon",
    },
    {
      nmae: "OB-GYN",
      img: "../../photo/overy.png",
      to: "/mainservice/obstetrics",
    },
    {
      nmae: "Anesthesiology",
      img: "../../photo/anesthesia.png",
      to: "/mainservice/anesthesiology",
    },
    {
      nmae: "Psychiatry",
      img: "../../photo/Psychiatry.png",
      to: "/mainservice/psychiatry",
    },
    {
      nmae: "Physiotherapy",
      img: "../../photo/Physiotherapy.png",
      to: "/mainservice/physiotherapy",
    },
    {
      nmae: "Urology",
      img: "../../photo/Urology.png",
      to: "/mainservice/urology",
    },
    {
      nmae: "Gastroenterology",
      img: "../../photo/stomach.png",
      to: "/mainservice/gastroenterology",
    },
  ];
  const changer = {
    display: `${props.second}`,
  };
  return (
    <>
      <div style={changer} className="secondmenu">
        <div className="container ">
          <div className="row">
            <div className="newfootlo mb-3 mt-3">
              <img src="../photo/Framelogo.png" alt="" />
            </div>
            <div className="col-12">
              <div className="newdirectli mb-3 innersfirst">
                <h4>DEPARTMENTS</h4>
              </div>
              {menu.map((elem) => {
                const { nmae, img, to } = elem;
                {
                  return (
                    <>
                      <Link
                        to={to}
                        onClick={() => {
                          setServiceseta(nmae);
                          props.setSecond("none");
                          props.setFirst("none");
                        }}
                        className="newlinkbox"
                      >
                        <span className="newliim footle">
                          <img src={img} alt="" />
                        </span>
                        <h6>{nmae}</h6>
                      </Link>
                    </>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            props.setSecond("none");
            props.setFirst("none");
          }}
          className="newlinkboxb"
        >
          <h6>Back</h6>
        </div>
      </div>
    </>
  );
}
export default memo(Newmobsecond);
