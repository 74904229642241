import { memo, useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import Filebox from "../Filebox";
import Fileofservice from "../Fileofservices";
import Displayflopup from "../Displayflopup";
import Functionmain from "../../Functionmain";
import Servicebannerinfo from "../Servicebannerinfo";
import { Displayflopupa } from "../Mainservicefun";

function Internalmedicine() {
  const [hello, setHello] = useState("");
  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [threea, setThreea] = useState(1);

  useEffect(() => {
    Functionmain();
  }, []);
  const menu = [
    {
      name: "20k+",
      num: "Happy Patients",
      img: "../../photo/Oncology.png",
    },
    {
      name: "13+",
      num: "OPD Departments",
      img: "../../photo/Oncology.png",
    },
    {
      name: "23+",
      num: "Experienced Doctors",
      img: "../../photo/Oncology.png",
    },
  ];
  return (
    <>
      {Fileofservice.map((elem) => {
        const { name, service, serviceline, infoline, doctor, content } = elem;
        let servicer = service.length;
        if (name === "Internal Medicine") {
          return (
            <>
              <Servicebannerinfo
                doctor={doctor}
                content={content}
                name={name}
                menu={menu}
              />
              <section className="pb-5 pt-2 backgrey">
                <div className="container">
                  <div className="centers">
                    {infoline !== "" ? <p>{infoline}</p> : <></>}
                    <h5>{serviceline}</h5>
                  </div>
                  <div className="row">
                    {service.map((objs, num) => {
                      const { name, img, content, bodyes } = objs;
                      return (
                        <>
                          <Filebox
                            name={name}
                            img={img}
                            content={content}
                            bodyes={bodyes}
                            setHello={setHello}
                            num={num}
                            setOne={setOne}
                            setTwo={setTwo}
                            setThree={setThree}
                            setThreea={setThreea}
                            threea={threea}
                            hello={hello}
                          />
                          <Displayflopupa
                            num={num}
                            bodyes={three}
                            hello={hello}
                            img={one}
                            name={two}
                            threea={threea}
                            setHello={setHello}
                            setOne={setOne}
                            setTwo={setTwo}
                            setThree={setThree}
                            setThreea={setThreea}
                            servicer={servicer}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>
            </>
          );
        }
      })}
    </>
  );
}

export default Internalmedicine;
