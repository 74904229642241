const Fileofservice = [
  {
    name: "Interventional Radiology",
    img: "",
    icon: "",
    content:
      "Interventional radiologists specialize in minimally invasive techniques to treat many conditions that once required open surgery with a large incision. They use small, hollow tubes (catheters), miniature instruments and imaging guidance to directly access arteries and veins without making large incisions.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Bhaskar MV",
        quali:
          "MBBS, MD, (Gold Medal), DNB,DM (NIMHANS),FRCR(London), NMANS(Singapore), EBIR,EDIR",
        experi: "15",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Interventional Oncology",
        img: "../photo/rr1.jpg",
        content:
          "Interventional Oncology (IO) is a subspecialty of interventional radiology that focuses...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "TACE (Trans Arterial Chemo Embolization) – for Liver tumours",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "TARE (Trans Arterial Radio Embolization) - for Liver tumours",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "PVE (Portal Vein Embolization) - for planned liver resection",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Ablation: MWA (MicroWave Ablation) / RFA(Radiofrequency Ablation) – liver, Kidney, Lung, Thyroid & Bone",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Percutaneous Transhepatic Biliary Drainage (PTBD) – Malignant biliary obstruction",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Percutaneous Biliary Stenting",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Pre-Operative Tumour Embolization -for highly vascular and large lesions",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Haemostatic Plug Biopsy – for highly vascular lesion /critical location",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Biopsy – from suspected tumours",
          },
        ],
      },
      {
        name: "Varicose vein Treatment",
        img: "../photo/rr2.jpg",
        content:
          "Larger varicose veins are generally treated with ligation and stripping, laser treatment...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "We use the latest highly advanced and skilled method to treat varicose veins. Quick recovery, under local anaesthesia, no obvious scars, no stiches (suture), no blood loss, completely done under image guidance (all the veins are treated under real time ultrasound doppler scan during the procedure and checked for completion of treatment - giving the best outcomes). We use highly advanced endovascular ablative methods like laser, radiofrequency, microwave, venaseal, fibrovein (sclerotherapy) depending on the case.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The primary consultation with Dr. Bhaskar MV and team will also involve doing a screening ultrasound doppler scan which will help you confirm and know the severity & extent of the disease; as well plan the best tailored treatment for each patient. Making it a successful treatment to achieve zero recurrence. The common tendency of individuals is to neglect the signs & symptoms of varicose veins and have their legs covered, yet consult the doctor only when it gets very troublesome. Appropriate early treatment will prevent from many worries like discoloured dark skin, non-healing wounds & ulcers, crampy leg pain, itching and prominent abnormal looking veins. We all would love to have clean, healthy and beautiful legs isn’t? consult the most distinctly skilled and experienced interventional radiologist to win over varicose veins.",
          },
        ],
      },
      {
        name: "Uterine Fibroids Treatment",
        img: "../photo/rr3.jpg",
        content:
          "Medications include: Gonadotropin-releasing hormone (GnRH) agonists. Medications called...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "Did your doctor or scan report say you have uterine fibroids? ",
            content:
              "Then by now, you would have had abundance of information over the internet / media and understood that uterine fibroids are common benign (non-cancerous) rings of muscle fibers located in the uterus which can vary in size, number and location.",
          },
          {
            img: "",
            name: "",
            ques: "Do you need to treat them? ",
            content:
              "Yes, only when they cause any of the symptoms like excessive menstrual bleeding (heavy or frequently), frequent urination, painful menstrual cycles, painful sexual intercourse, generalised sever pelvic pain or bloating sensation. Certainly these symptoms hinder daily activities of a women. If no symptoms, follow them up with yearly ultrasound scans.",
          },
          {
            img: "",
            name: "",
            ques: "Which treatment is best? ",
            content:
              "Getting directly to the discussion on its treatment, whether to get the uterus removed or have Uterine Fibroid Embolization (UFE) & save your uterus. Your primary consultation with Dr. Rohit Madhurkar and team will include a screening ultrasound pelvis scan to get a better insight, know the suitability, provide you all the fine details and obvious advantages of UFE to help you decide.",
          },
          {
            img: "",
            name: "",
            ques: "Uterine Fibroid Embolization (UFE) ",
            content:
              "is the most advanced minimally invasive way to treat your uterine fibroid related symptoms at the obvious advantage of not losing your normal uterine function, which is done on day care or overnight hospitalization. Other prominent advantages are no stiches(suture), blood less, scar less, and quick recovery to routine activity. Here the blood supply to the fibroid shutdown, hence shrinking the fibroids. The post procedure pain is avoided by medication & performing a nerve block which makes it a painless procedure (SHNB – Superior Hypogastric Nerve Block). We advocate “SAVE THE UTERES” campaign to avoid unnecessary removal of uterus. Also here are success stories from previous treated patients. Consult your efficient and experienced Interventional Radiologist to bring back life again.",
          },
        ],
      },
      {
        name: "Enlarged Prostate (BPH) Treatment",
        img: "../photo/rr4.jpg",
        content:
          "Treatment for BPH has long been medications and procedures...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Benign Prostatic Hyperplasia (BPH) as the name says it’s a non-cancerous enlargement of the prostate gland commonly seen in 1 in 3 men over the age of 55years. It needs treatment only if the symptoms are bothering you.",
          },
          {
            img: "",
            name: "",
            ques: "Are you having any of these symptoms? ",
            content:
              "Weak urine stream, frequent urination, dribbling after urination, urge to urinate, unknowing leakage of urine, frequent urination at night or painful urination. Then this is commonly because your enlarged prostate is pressing around your urethra (tube within the penis that passes urine out) and obstructing the normal passage of urine out of your body. Hence causing incomplete emptying of urine in the urinary bladder, leading to above symptoms.",
          },
          {
            img: "",
            name: "",
            ques: "Looking for a non-surgical permanent treatment? ",
            content:
              "Prostate Artery Embolization (PAE) is the way to go about. As a part of the primary consultation with Dr. Rohit Madhurkar and team will perform a screening ultrasound of the pelvis which will help you confirm the prostate enlargement and know how much residual urine is left behind in the urinary bladder after you pass urine. He will score your symptoms, confirm your diagnosis and discuss about the impactful advantages of the procedure.",
          },
          {
            img: "",
            name: "What are the Advantages? ",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "Prostate Artery Embolization (PAE) ",
            content:
              "is the most advanced non-surgical treatment option which is a day care procedure, under local anaesthesia, no urinary catherization, no stiches(suture), blood less, scar less, and quick recovery to routine activity in a day. Here the blood supply to the enlarged prostate is shutdown, hence shrinking the prostate gland mainly around the urethra. This procedure is very much technically sensitive and needs highly skilled and experienced Vascular Interventional Radiologist. Dr. Rohit Madhurkar is one among the few trained super specialist in India to provide highly successful state of the art minimally invasive treatment facilities. Consult your Interventional Radiologist and know more to have a trouble free urination.",
          },
        ],
      },
      {
        name: "Urology-Nephrology",
        img: "../photo/rr5.jpg",
        content:
          "Nephrology and Urology deal with kidneys and its problem. A nephrologist deals with...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "PAE (Prostate Artery Embolization) - for BPH (Benign Prostate Hypertrophy)",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Varicocele Embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Haemodialysis- AV Fistula management – fistuloplasty / stenting / thrombolysis",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "RAS (Renal Artery Stenting) – for stenosis – hypertension",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Renal AML / tumour embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Antegrade ureteric stenting",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Image Guided PCN (Percutaneous Nephrostomy)",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "TRUS Guided Prostate Biopsy",
          },
        ],
      },
      {
        name: "Emergency Intervention",
        img: "../photo/rr6.jpg",
        content:
          "Emergency intervention means the justified use of early interventions and/or otherwise...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Trauma bleeder Embolization – Liver, spleen, renal, pelvic etc.",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Embolization - for haematuria / bleeding lesions",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "GI (Gastro Intestinal) bleeder embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Pseudo Aneurysm management",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Portal Hypertension bleeder Intervention – TIPS / DIPS / BRTO",
          },
        ],
      },
      {
        name: "Pain Management Intervention",
        img: "../photo/rr7.jpg",
        content:
          "Interventional pain management is a method which utilizes pain blocking techniques...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Celiac / Lumbar / Sacral / Impar - neurolysis (block)",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Superior Hypogastric Nerve block – pelvic pain",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Joint injection for chronic pain",
          },
        ],
      },
      {
        name: "Arterial Intervention",
        img: "../photo/rr8.jpg",
        content:
          "These procedures entail placing a balloon and/or stent (a small, metal mesh tube) into...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "EVAR (Endovascular Aortic Repair) – Aortic Aneurysm",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Peripheral angioplasty / stenting – for PAD – iliac / femoral / tibial",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Cerebral Angiogram",
          },
        ],
      },
      {
        name: "Gastro Enterology",
        img: "../photo/rr9.jpg",
        content:
          "A gastroenterologist is a specialist with expertise in the disorders and diseases that affect...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "TIPSS (Transjugular Intrahepatic Portosystemic Shunting) - for portal hypertension sequelae",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "DIPS (Direct Intrahepatic Portal Shunt) – for portal hypertension sequelae/Budd Chiari syndrome",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "HVPG (Hepatic Venous Pressure Gradient) – for portal hypertension",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "TJLB (Trans Jugular Liver Biopsy) / uTJLB",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "IVC / Hepatic Vein – Plasty / Stenting – for Budd Chiari management",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "BRTO (Balloon-occluded Retrograde Transvenous Obliteration) – for gastric varices",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Partial Splenic embolization – for Hypersplenism / pancytopenia",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "GI (Gastro Intestinal) bleeder embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Percutaneous Feeding Gastrostomy",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Percutaneous Cholecystostomy",
          },
        ],
      },
      // {
      //     name:"Varicose Vein Management",
      //     img:"../photo/rr10.jpg",
      //     content:"Treatment for varicose veins may include self-care measures, compression stockings, and...",
      //     bodyes:[
      //         {
      //             img:"../photo/checkmark.png",
      //             name:"",
      //             ques:"",
      //             content:"EVLT – Endo Vascular Laser Therapy",
      //         },
      //         {
      //             img:"../photo/checkmark.png",
      //             name:"",
      //             ques:"",
      //             content:"VenaSeal",
      //         },
      //         {
      //             img:"../photo/checkmark.png",
      //             name:"",
      //             ques:"",
      //             content:"Sclerotherapy",
      //         },
      //     ],
      // },
      {
        name: "Endocrinology",
        img: "../photo/rr11.jpg",
        content:
          "Endocrinology is the study of hormones and endocrine glands and organs. ...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Thyroid Nodule Ablation (MWA / RFA) - for benign thyroid nodules",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Venous Sampling",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Portal venous sampling - for insulinomas",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Adrenal Venous Sampling –to confirm autonomous hormone production",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Petrosal Sinus Sampling – for lateralising microadenoma",
          },
        ],
      },
      {
        name: "Long Term Central Line Access",
        img: "../photo/rr12.jpg",
        content:
          "Long-term central venous access is important therapeutically for a multitude of reasons...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "PICC Line (Peripherally Inserted Central catheter",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Perm-cath / Tunnelled line",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Chemo Port",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Trans lumbar IVC catherization",
          },
        ],
      },
      {
        name: "Transplant Intervention",
        img: "../photo/rr13.jpg",
        content:
          "Transplantation is the technique in which a plant is moved from one location to other...",
        bodyes: [
          {
            img: "../photo/star.png",
            name: "Post Liver Transplant",
            ques: "",
            content: "",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "PTBD for stricture",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "HAT (Hepatic Artery Thrombosis) intervention",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Hepatic vein intervention",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Portal vein intervention",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Liver Biopsy",
          },
          {
            img: "../photo/star.png",
            name: "Post Renal Transplant",
            ques: "",
            content: "",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Renal artery stenosis stenting",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Antegrade uretic stricture stenting",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Renal Biopsy",
          },
          {
            img: "../photo/star.png",
            name: "Post Pancreas Transplant ",
            ques: "",
            content: "",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Pancreatic biopsy",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Vascular intervention",
          },
        ],
      },
      {
        name: "Obstetrics & Gynacology",
        img: "../photo/rr14.jpg",
        content:
          "Obstetrics and gynecology is a field thought of as traditionally serving women...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "UAE - Uterine Artery Embolization - PPH (Post-Partum Haemorrhage), adenomyosis etc",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "UFE - Uterine Fibroid Embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Ovarian Vein Embolization – for Pelvic congestion syndrome",
          },
        ],
      },
      {
        name: "Pulmonology",
        img: "../photo/rr15.jpg",
        content:
          "A pulmonologist diagnoses and treats diseases of the respiratory system....",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "BAE - Bronchial Artery Embolization - for Haemoptysis",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Mechanical Thrombectomy & Thrombolysis – Pulmonary Embolism",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Pulmonary Artery /AVM embolization",
          },
        ],
      },
      {
        name: "Orthopaedics",
        img: "../photo/rr16.jpg",
        content:
          "Your orthopaedic work will include the replacement of whole joints such...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "RFA (Radiofrequency Ablation) – for osteoid osteoma",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Pre-operative Embolization – for large vascular bone tumours",
          },
        ],
      },
      {
        name: "Venous Intervention",
        img: "../photo/rr17.jpg",
        content:
          "Venous interventions are minimally invasive treatment options for patients with blocked...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Central Venous stenting / plasty – for Subclavian / SVC stenosis etc",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "DVT (Deep Venous Thrombosis) Management - Mechanical Thrombectomy & Thrombolysis",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "IVC Filter Placement/Retrieval – to prevent pulmonary embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Varicose veins Treatment",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Peripheral Venous stenting / plasty – for iliac stenosis etc",
          },
        ],
      },
      {
        name: "Miscellaneous",
        img: "../photo/rr18.jpg",
        content:
          "Radiology may be divided into two different areas, diagnostic radiology and interventional...",
        bodyes: [
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Vascular Malformation Management – embolization / percutaneous injections",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "AVM (ArterioVenous Malformation) Embolization",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Intra Vascular Foreign Body Retrieval –for misplaced central line catheters / wires etc.",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Catheter Directed Thrombolysis",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Co2 Angiography",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content: "Drainage tube placement – for abscess / collections",
          },
          {
            img: "../photo/checkmark.png",
            name: "",
            ques: "",
            content:
              "Guided biopsy / FNA / LVP (Large Volume Paracentesis) / Diagnostic Aspirations",
          },
        ],
      },
    ],
  },
  {
    name: "Orthopaedics",
    img: "",
    icon: "",
    content:
      "The hospital elucidates its Orthopaedics service by adeptly diagnosing, treating, and rehabilitating musculoskeletal issues to restore optimal function and comfort.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Ambuj",
        quali: "MBBS, MD, Fellow in Diabec Foot Managament (FDFM)",
        experi: "8",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Mithun",
        quali: "MBBS, MD",
        experi: "8",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Abilash Srivatsav",
        quali: "MBBS, MD,Fellow in Pediatric Orthopedics",
        experi: "8",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Prajwal",
        quali: "MBBS, MD",
        experi: "8",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Knee Replacement & Arthroscopy",
        img: "../photo/ooo1.jpg",
        content:
          "Knee replacement, a transformative orthopedic intervention, is a...",
        bodyes: [
          {
            img: "",
            name: "Knee Replacement ",
            ques: "",
            content:
              "Knee replacement, also known as knee arthroplasty, is a surgical procedure aimed at alleviating chronic knee pain and improving joint function in individuals whose knees have been severely damaged by conditions like osteoarthritis, rheumatoid arthritis, or injury. During the procedure, the damaged portions of the knee joint, including the cartilage and bone, are replaced with artificial components made of metal and plastic. The surgery can involve either a partial knee replacement, which addresses only one part of the knee joint, or a total knee replacement, which involves replacing the entire knee joint. Knee replacement surgery aims to relieve pain, restore mobility, and enhance overall quality of life for individuals who have struggled with debilitating knee conditions.",
          },
          {
            img: "",
            name: "Knee Arthroscopy ",
            ques: "",
            content:
              "Knee arthroscopy is a minimally invasive surgical technique used for diagnosing and treating various knee joint problems. Unlike traditional open surgery, arthroscopy involves making small incisions and using a thin, flexible instrument called an arthroscope, which has a camera attached to it. This allows the surgeon to visualize the inside of the knee joint on a monitor and make precise interventions. Knee arthroscopy can be used to treat conditions such as torn meniscus (the cartilage that cushions the knee joint), damaged ligaments, loose fragments of bone or cartilage, and inflamed joint lining. The benefits of arthroscopy include smaller incisions, shorter recovery times, and reduced risk of complications compared to open surgery, making it a preferred choice for many knee-related issues.",
          },
        ],
      },
      {
        name: "Fracture Management",
        img: "../photo/ooo2.jpg",
        content:
          "Fracture management involves the medical treatment and care of broken bones with...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Fracture management involves the medical treatment and care of broken bones with the aim of promoting proper healing, minimizing complications, and restoring the affected bone's functionality. Fractures can range from simple cracks in the bone to complex breaks that penetrate the skin or cause significant displacement. The management approach depends on various factors, including the type of fracture, its location, the age and health of the patient, and the potential for associated injuries.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "In many cases, the initial step in fracture management involves immobilization. This is typically achieved through the application of casts, splints, or braces, which help stabilize the broken bone and prevent further movement that could hinder the healing process. Immobilization can last for several weeks, during which the bone gradually mends itself. For more severe fractures or cases where the bone alignment is compromised, surgical intervention may be required. Surgical methods can involve the use of metal plates, screws, rods, or external fixators to realign the bones and hold them in place.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pain management is another crucial aspect of fracture care. Pain relief can involve over-the-counter or prescription pain medications, as well as techniques such as elevation and ice application to reduce swelling and discomfort. Physical therapy may also be recommended once the bone starts healing to help regain strength, flexibility, and overall function in the affected area.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Regular follow-up appointments and imaging, such as X-rays, are essential to monitor the progress of bone healing and ensure that the treatment plan is effective. In some cases, complications like infections, delayed healing, or malnutrition (improper bone alignment during healing) may arise, requiring additional interventions.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "In summary, fracture management involves a multi-faceted approach that includes immobilization, potential surgical intervention, pain management, and rehabilitation. The specific approach varies based on the nature of the fracture and the individual patient's circumstances, with the ultimate goal of achieving optimal bone healing and restoring the patient's quality of life.",
          },
        ],
      },
      {
        name: "Hip Replacement & Arthroscopy",
        img: "../photo/ooo3.jpg",
        content:
          "Hip replacement is a transformative surgical procedure designed to alleviate pain and restore...",
        bodyes: [
          {
            img: "",
            name: "Hip Replacement ",
            ques: "",
            content:
              "Hip replacement, also known as hip arthroplasty, is a surgical procedure designed to alleviate pain, improve mobility, and enhance the overall quality of life for individuals with severe hip joint damage. This damage is often caused by conditions like osteoarthritis, rheumatoid arthritis, avascular necrosis, or hip fractures. During a hip replacement surgery, the damaged portions of the hip joint, including the femoral head (thigh bone) and the acetabulum (hip socket), are removed and replaced with artificial components made of metal, plastic, or ceramic materials. There are two main types of hip replacement procedures: total hip replacement, which involves replacing both the femoral head and the acetabulum, and partial hip replacement, which involves replacing only the femoral head. Hip replacement surgery aims to relieve pain, restore joint function, and improve the patient's overall mobility and quality of life.",
          },
          {
            img: "",
            name: "Hip Arthroscopy ",
            ques: "",
            content:
              "Hip arthroscopy is a minimally invasive surgical technique used to diagnose and treat various hip joint issues. It involves the insertion of a small, flexible instrument called an arthroscope into the hip joint through a small incision. The arthroscope is equipped with a camera that provides real-time images of the interior of the hip joint on a monitor, allowing the surgeon to visualize the joint structures and identify problems. Hip arthroscopy can be used to address conditions such as labral tears, femoroacetabular impingement (abnormal contact between the femoral head and hip socket), loose bodies, and cartilage damage. The surgeon can also perform certain corrective procedures during the arthroscopy, such as trimming excess bone or repairing torn tissues. Compared to traditional open surgery, hip arthroscopy offers the advantages of smaller incisions, reduced pain, shorter recovery times, and potentially better outcomes.",
          },
        ],
      },
      {
        name: "Pediatric Orthopedics",
        img: "../photo/ooo4.jpg",
        content:
          "Pediatric orthopedics is a specialized branch of medicine that focuses on the diagnosis...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatric orthopedics is a specialized branch of medicine that focuses on the diagnosis, treatment, and management of musculoskeletal conditions and injuries in children and adolescents. Pediatric orthopedic specialists are trained to address a wide range of developmental, congenital, and acquired orthopedic issues affecting the bones, joints, muscles, and connective tissues of young patients. These conditions can include growth plate injuries, scoliosis, limb deformities, fractures, joint infections, and neuromuscular disorders. Pediatric orthopedists use a combination of medical, surgical, and rehabilitative approaches tailored to the unique needs of growing bodies. Their goal is to ensure optimal musculoskeletal health, alleviate pain, improve function, and support the healthy development of children, helping them lead active and fulfilling lives.",
          },
        ],
      },
      {
        name: "Shoulder Replacement",
        img: "../photo/ooo5.jpg",
        content:
          "Shoulder replacement is a transformative surgical procedure aimed at restoring function...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Shoulder replacement is a transformative surgical procedure aimed at restoring function and alleviating pain in individuals with severely damaged shoulder joints. This complex intervention involves the replacement of the damaged ball-and-socket joint with a prosthetic implant, typically made of metal and plastic components. Shoulder replacement surgery is commonly recommended for individuals with conditions such as osteoarthritis, rheumatoid arthritis, or significant shoulder fractures that have not responded effectively to non-surgical treatments.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During the procedure, the surgeon removes the damaged portions of the shoulder joint, including the head of the humerus (upper arm bone) and the glenoid (shoulder socket). The prosthetic components are then meticulously positioned to replicate the natural joint's movement and function.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Shoulder replacement can result in significant pain relief and improved range of motion, allowing patients to regain the ability to perform daily tasks and engage in activities they once enjoyed.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Post-surgery, a period of rehabilitation is crucial to optimize the shoulder's function and strength. Physical therapy is typically prescribed to help patients regain mobility and muscle strength gradually.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Advances in surgical techniques and implant materials have led to more successful outcomes, with many patients experiencing improved quality of life and reduced pain after shoulder replacement. While it is a major surgical procedure, shoulder replacement offers the potential for enhanced mobility and an improved overall shoulder function for individuals who have struggled with chronic shoulder issues.",
          },
        ],
      },
      {
        name: "Shoulder Arthroscopy",
        img: "../photo/ooo6.jpg",
        content:
          "Shoulder arthroscopy is a minimally invasive surgical technique that provides a closer...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Shoulder arthroscopy is a minimally invasive surgical technique that provides a closer and more detailed view inside the shoulder joint. It involves the use of a tiny camera, called an arthroscope, which is inserted through small incisions around the shoulder. This camera captures real-time images of the joint's internal structures, including the cartilage, tendons, ligaments, and surrounding tissues.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The arthroscope allows orthopedic surgeons to diagnose a variety of shoulder conditions, such as rotator cuff tears, labral tears, and inflammation. It also serves as a tool for surgical intervention, as specialized instruments can be introduced through additional small incisions to repair or address the identified issues. The minimally invasive nature of shoulder arthroscopy often leads to reduced pain, shorter recovery times, and smaller scars compared to traditional open surgery.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Shoulder arthroscopy has become an invaluable diagnostic and therapeutic tool in orthopedics, enabling precise assessment and targeted treatment of shoulder problems. It offers the advantage of both visualization and intervention within the same procedure, allowing patients to potentially return to their daily activities more quickly and with less discomfort. The procedure has revolutionized how shoulder conditions are managed, providing a less invasive alternative for those seeking relief from shoulder pain and dysfunction.",
          },
        ],
      },
      {
        name: "Ankle Surgery",
        img: "../photo/ooo7.jpg",
        content:
          "Ankle surgery is a specialized medical procedure aimed at addressing a wide range of...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Ankle surgery is a specialized medical procedure aimed at addressing a wide range of issues affecting the ankle joint and surrounding structures. It involves surgical intervention to diagnose, treat, and correct conditions that may cause pain, instability, or restricted movement. Ankle surgeries can vary widely in their scope and complexity, from relatively minor procedures to complex reconstructions.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Surgeons may perform ankle surgery for various reasons, such as repairing ligament or tendon injuries, treating fractures, removing bone spurs, or addressing chronic conditions like arthritis. The surgical approach can range from traditional open surgery to minimally invasive techniques, such as arthroscopy, which uses a tiny camera to visualize and operate on the joint through small incisions. Ankle surgery aims to alleviate pain, restore stability, and improve the ankle's overall function, enabling patients to regain mobility and lead a more active and comfortable life.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Post-surgery, a comprehensive rehabilitation program is often prescribed to help patients regain strength, flexibility, and coordination in the ankle. Physical therapy and exercises play a crucial role in the recovery process, aiding in the restoration of normal joint function and reducing the risk of complications. The success of ankle surgery is influenced by factors such as the patient's overall health, the specific condition being treated, and adherence to post-operative care instructions. Ankle surgery serves as a valuable tool in orthopedics, offering individuals the potential to overcome ankle-related challenges and resume their daily activities with improved comfort and movement.",
          },
        ],
      },
      {
        name: "Elbow Surgery",
        img: "../photo/ooo8.jpg",
        content:
          "Elbow surgery is a medical procedure designed to diagnose, treat, and address a variety...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Elbow surgery is a medical procedure designed to diagnose, treat, and address a variety of conditions affecting the elbow joint and surrounding tissues. This surgical intervention becomes necessary when non-surgical treatments have proven ineffective in relieving pain, restoring function, or addressing issues such as instability or limited mobility. Elbow surgeries encompass a range of techniques and approaches tailored to the specific condition and individual needs.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Surgeons perform elbow surgery for various reasons, including repairing ligaments or tendons that have been damaged due to injury or overuse, addressing fractures or dislocations, and managing chronic conditions like tennis elbow or arthritis.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The surgical techniques employed can range from traditional open surgery to minimally invasive procedures such as arthroscopy, where a small camera is used to visualize and operate on the joint through tiny incisions. Elbow surgery aims to alleviate discomfort, restore joint stability, and improve overall function, allowing patients to regain the use of their arm and engage in daily activities without hindrance.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Following elbow surgery, a comprehensive rehabilitation program is often prescribed to optimize recovery and regain strength, flexibility, and range of motion. Physical therapy plays a pivotal role in helping patients achieve the best possible outcome, guiding them through exercises and movements tailored to their specific surgical procedure.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Adherence to the rehabilitation plan, along with close communication with the healthcare team, contributes to the success of the surgery. Elbow surgery offers individuals the opportunity to overcome elbow-related challenges and enjoy an improved quality of life, free from the limitations that their condition may have imposed.",
          },
        ],
      },
      {
        name: "Spine Surgery",
        img: "../photo/ooo9.jpg",
        content:
          "Spine surgery is a specialized medical procedure aimed at addressing a range of...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Spine surgery is a specialized medical procedure aimed at addressing a range of conditions affecting the spinal column, which includes the vertebrae, discs, nerves, and other supporting structures. This surgical intervention becomes necessary when non-surgical treatments have been exhausted and the condition is causing persistent pain, neurological symptoms, or functional limitations. Spine surgeries encompass a spectrum of techniques tailored to the specific issue and the individual patient's needs.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Surgeons perform spine surgery for various reasons, such as repairing herniated discs that compress spinal nerves, stabilizing vertebral fractures, correcting spinal deformities like scoliosis, or relieving spinal stenosis, a narrowing of the spinal canal.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The surgical approaches can vary from traditional open surgery to advanced minimally invasive techniques, which utilize specialized instruments and small incisions. Spine surgery aims to alleviate pain, restore spinal stability, and improve overall function, enabling patients to regain mobility and alleviate debilitating symptoms.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "After spine surgery, a comprehensive rehabilitation program is often integral to the recovery process. Physical therapy, exercises, and guided movement play a pivotal role in helping patients regain strength, flexibility, and proper posture. Rehabilitation also aids in preventing complications and optimizing the surgical outcome. Successful spine surgery relies on a collaborative approach between the surgical team, rehabilitation specialists, and the patient, who plays an active role in their recovery journey. Spine surgery presents an opportunity for individuals to address spinal issues that may have impeded their quality of life, with the goal of returning them to a more active and comfortable state.",
          },
        ],
      },
    ],
  },
  {
    name: "Neurosurgery",
    img: "",
    icon: "",
    content:
      "Neurosurgery, a cornerstone of our hospital's expertise, involves intricate surgical interventions to treat diverse neurological conditions, encompassing brain, spine, and nerve disorders. Our accomplished neurosurgeons employ cutting-edge techniques and technologies to address complexities like tumors, trauma, and degenerative diseases.",
    doctor: [
      {
        img: "../../photo/doctor.png",
        names: "Dr. Indira Devi",
        quali: "MBBS, MS, MCH (NIMHANS)",
        experi: "35",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Bharath R",
        quali: "MBBS, MCH(NIMHANS), Fellow Epilepsy surgery (USA)",
        experi: "12",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      // {
      //     name:"Brain Tumor Resection",
      //     img:"../../photo/lll1.jpg",
      //     content:"Brain tumor resection is a surgical procedure performed in neurosurgery to remove...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Brain tumor resection is a surgical procedure performed in neurosurgery to remove abnormal growths or tumors from the brain. This complex intervention aims to alleviate symptoms, reduce pressure on surrounding structures, and potentially eliminate cancerous or non-cancerous masses. During the procedure, a neurosurgeon carefully removes as much of the tumor as possible while minimizing damage to healthy brain tissue.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The process typically involves using advanced imaging techniques, such as MRI or CT scans, to precisely locate the tumor and plan the surgical approach. The surgeon then accesses the brain through a carefully planned incision, taking care to avoid critical areas that control essential functions. Depending on the tumor's location and characteristics, different surgical techniques may be employed, such as microsurgery or stereotactic guidance.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Brain tumor resection may be followed by other treatments, such as radiation therapy or chemotherapy, depending on the type of tumor and its aggressiveness. The goal of this procedure is to improve the patient's neurological function, alleviate symptoms like headaches or seizures, and potentially extend their overall survival. The success of brain tumor resection depends on factors like the tumor's size, location, and type, as well as the patient's overall health and response to treatment.",
      //         },
      //     ],
      // },
      // {
      //     name:"Spinal Fusion",
      //     img:"../../photo/lll2.jpg",
      //     content:"Spinal fusion is a surgical procedure performed in neurosurgery and orthopedics to stabilize...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Spinal fusion is a surgical procedure performed in neurosurgery and orthopedics to stabilize and join two or more vertebrae in the spine. This intervention aims to alleviate pain, correct spinal deformities, and restore spinal stability by preventing excessive movement between the fused vertebrae. During the procedure, the surgeon places bone grafts or implants between the vertebrae, which eventually fuse together over time.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Spinal fusion is often recommended for conditions such as spinal fractures, degenerative disc disease, scoliosis, and spinal instability. It can also be performed after the removal of spinal tumors or to treat certain types of herniated discs. The surgery typically involves making an incision and accessing the spine through a variety of approaches, which can include the front, back, or side of the body.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Various techniques, such as using bone grafts, metal rods, screws, or cages, may be employed to provide stability and facilitate the fusion process. Post-operative rehabilitation, including physical therapy and exercises, is crucial to optimizing the fusion and helping patients regain strength and mobility.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Spinal fusion can provide long-term relief from pain and improved spinal stability, but it is a major surgical procedure that requires careful consideration and evaluation by a medical team. The success of spinal fusion depends on factors like the patient's overall health, the specific condition being treated, and adherence to post-operative instructions.",
      //         },
      //     ],
      // },
      // {
      //     name:"Deep Brain Stimulation (DBS)",
      //     img:"../../photo/lll3.jpg",
      //     content:"Deep Brain Stimulation (DBS) is a neurosurgical procedure used to treat various neurological disorders...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Deep Brain Stimulation (DBS) is a neurosurgical procedure used to treat various neurological disorders, particularly movement disorders like Parkinson's disease, essential tremor, and dystonia. In DBS, electrodes are surgically implanted deep within specific areas of the brain. These electrodes emit controlled electrical impulses that modulate abnormal brain activity and help regulate movement and other neurological functions.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The procedure begins with the precise placement of electrodes using advanced imaging and mapping techniques. Once the electrodes are in position, they are connected to a small device, similar to a pacemaker, which is usually implanted under the skin near the collarbone. This device generates electrical impulses and can be adjusted externally by a healthcare professional to optimize symptom control.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"DBS aims to alleviate motor symptoms like tremors, rigidity, and bradykinesia, which are often refractory to medication. The degree of symptom improvement can vary, and the procedure may not be suitable for all patients. Regular follow-up visits are essential to fine-tune the stimulation settings and ensure the best outcomes.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"While DBS is generally safe and effective, it involves risks inherent to surgery and electrical stimulation. Therefore, careful patient selection, thorough pre-operative evaluations, and ongoing post-operative management are crucial to its success. DBS has significantly enhanced the quality of life for many individuals with movement disorders, offering improved symptom control and allowing them to regain more functional and active lives.",
      //         },
      //     ],
      // },
      // {
      //     name:"Microvascular Decompression",
      //     img:"../../photo/lll4.jpg",
      //     content:"Microvascular decompression (MVD) is a neurosurgical procedure used to relieve compression...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Microvascular decompression (MVD) is a neurosurgical procedure used to relieve compression of cranial nerves, particularly the trigeminal nerve, which is often associated with conditions like trigeminal neuralgia and hemifacial spasm. This technique involves placing a cushioning pad or Teflon sponge between the compressed nerve and the adjacent blood vessel, effectively alleviating irritation and reducing symptoms.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The procedure begins with a small incision behind the ear, exposing the area where the nerve and blood vessel meet. Using specialized microsurgical techniques, the surgeon carefully separates the nerve from the vessel and inserts the cushioning material to prevent future compression. This delicate approach aims to reduce nerve irritation and eliminate or significantly reduce the sharp, stabbing pain or muscle spasms experienced by the patient.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Microvascular decompression is often chosen when other treatment options, such as medications or nerve blocks, have proven ineffective in providing long-term relief. The success rate of MVD can be quite high, offering lasting symptom control and improved quality of life for many patients. However, as with any surgical procedure, there are risks involved, including potential complications related to anesthesia, bleeding, infection, or nerve injury.",
      //         },
      //     ],
      // },
      // {
      //     name:"Cerebrovascular Surgery",
      //     img:"../../photo/lll5.jpg",
      //     content:"Cerebrovascular surgery is a specialized branch of neurosurgery that focuses on surgical...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Cerebrovascular surgery is a specialized branch of neurosurgery that focuses on surgical interventions to treat conditions affecting the blood vessels in the brain and its surrounding structures. This field encompasses a wide range of procedures designed to address cerebrovascular disorders, including aneurysms, arteriovenous malformations (AVMs), stroke, and vascular stenosis.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The goal of cerebrovascular surgery is to restore normal blood flow, prevent bleeding or rupture of weakened vessels, and reduce the risk of neurological complications. Surgical techniques may involve clipping or coiling aneurysms to prevent rupture, removing abnormal blood vessels or masses, or bypassing blocked arteries to improve blood supply to the brain.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The procedures are often intricate and may involve microsurgery, advanced imaging, and intraoperative monitoring to ensure precision and minimize risk. Patient evaluation, imaging studies, and careful planning are essential to determine the most appropriate surgical approach for each case.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Cerebrovascular surgery requires a high level of expertise and specialized training due to the delicate nature of the brain and its blood vessels. The outcomes of these surgeries can significantly impact patients' neurological function and overall well-being. While the risks associated with cerebrovascular surgery include bleeding, infection, and potential neurological deficits, the potential benefits in terms of preventing serious complications and improving patients' quality of life are often substantial.                        ",
      //         },
      //     ],
      // },
      // {
      //     name:"Peripheral Nerve Surgery",
      //     img:"../../photo/lll6.jpg",
      //     content:"Peripheral nerve surgery is a specialized field within neurosurgery that focuses on surgical...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Peripheral nerve surgery is a specialized field within neurosurgery that focuses on surgical interventions to treat conditions affecting the peripheral nervous system, which includes nerves outside the brain and spinal cord. This field encompasses a range of procedures designed to address issues like nerve compression, entrapment, injuries, and disorders causing pain, weakness, or loss of sensation in the extremities.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The goal of peripheral nerve surgery is to alleviate symptoms, restore nerve function, and improve the patient's overall quality of life. Surgical techniques may involve decompressing compressed nerves, repairing damaged nerves, or transferring healthy nerves to restore function in cases of severe nerve injuries.",
      //         },
      //     ],
      // },
      // {
      //     name:"Spinal Cord Stimulators",
      //     img:"../../photo/lll7.jpg",
      //     content:"Spinal cord stimulators (SCS) are medical devices used in neurosurgery to manage...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Spinal cord stimulators (SCS) are medical devices used in neurosurgery to manage chronic pain, particularly pain that doesn't respond well to other treatments. These devices deliver low-level electrical impulses to the spinal cord, modifying pain signals and providing relief to individuals suffering from conditions like failed back surgery syndrome, complex regional pain syndrome, or neuropathic pain.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The procedure involves surgically implanting electrodes near the spinal cord, typically in the epidural space. These electrodes are connected to a small generator, similar to a pacemaker, which is implanted under the skin. The generator emits electrical impulses that interfere with pain signals, effectively replacing the sensation of pain with a mild tingling sensation called paresthesia.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Spinal cord stimulators aim to reduce pain perception, improve daily functioning, and enhance the overall quality of life for patients. Before the permanent implantation, a trial period using external electrodes is often conducted to assess the effectiveness of the therapy.",
      //         },
      //     ],
      // },
      // {
      //     name:"Epilepsy Surgery",
      //     img:"../../photo/lll8.jpg",
      //     content:"Epilepsy surgery is a specialized field within neurosurgery that focuses on surgical...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Epilepsy surgery is a specialized field within neurosurgery that focuses on surgical interventions to treat epilepsy, a neurological disorder characterized by recurrent seizures. This surgery is considered when seizures are not well controlled by medications and significantly impact the patient's quality of life.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The goal of epilepsy surgery is to identify and surgically remove the area of the brain responsible for initiating seizures, known as the epileptogenic zone. This can involve removing a small portion of the brain (resection), disconnecting abnormal brain tissue (disconnection), or placing electrodes for monitoring and mapping brain activity (intracranial monitoring) before surgery. Before surgery, thorough evaluation including neuroimaging, electroencephalography (EEG), and neuropsychological assessments is conducted to precisely locate the seizure focus and determine the feasibility of surgery. Patient selection is crucial, considering potential risks and benefits.",
      //         },
      //     ],
      // },
      // {
      //     name:"Minimally Invasive Techniques",
      //     img:"../../photo/lll9.jpg",
      //     content:"Minimally invasive techniques in medicine, including neurosurgery, involve performing...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Minimally invasive techniques in medicine, including neurosurgery, involve performing procedures with smaller incisions and advanced tools, reducing tissue damage and promoting quicker recovery. In neurosurgery, these techniques are used to diagnose and treat various neurological conditions with less disruption to surrounding tissues.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Using specialized instruments and guided imaging, procedures like endoscopy and laparoscopy offer precise visualization of internal structures. In neurosurgery, minimally invasive approaches include",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"Endoscopic Surgery ",
      //             content:"Using a thin tube with a camera (endoscope) inserted through small incisions to visualize and treat conditions such as hydrocephalus or pituitary tumors.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"Laparoscopic Techniques ",
      //             content:"Employing small incisions and a camera for procedures like spinal fusion or herniated disc repair.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Minimally invasive techniques offer advantages like reduced post-operative pain, shorter hospital stays, and faster return to normal activities. However, not all conditions can be treated minimally invasively, and careful patient selection is crucial. These techniques exemplify modern medical innovation, aiming to provide effective treatments while minimizing patient discomfort and enhancing recovery.",
      //         },
      //     ],
      // },
      // {
      //     name:"Pediatric Neurosurgery",
      //     img:"../../photo/lll10.jpg",
      //     content:"Pediatric neurosurgery is a highly specialized medical discipline dedicated to diagnosing...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Pediatric neurosurgery is a highly specialized medical discipline dedicated to diagnosing, treating, and managing neurological conditions and disorders in children. This branch of medicine focuses on the delicate and complex structures of the developing nervous system, encompassing the brain, spinal cord, and peripheral nerves. Pediatric neurosurgeons are skilled medical professionals who undergo rigorous training in both general surgery and neurosurgery, with a unique emphasis on addressing the distinct challenges posed by young patients.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"These specialists play a crucial role in the care of children with various neurosurgical issues, including brain tumors, spinal cord abnormalities, hydrocephalus, epilepsy, and craniosynostosis. The surgical interventions performed by pediatric neurosurgeons require a deep understanding of the intricate anatomy of growing brains and bodies. Their expertise extends beyond surgical skills; they collaborate closely with multidisciplinary teams, including pediatric neurologists, radiologists, and pediatricians, to ensure comprehensive and tailored treatment plans for each child.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Furthermore, pediatric neurosurgery is marked by its compassionate approach to patients and families. Given the unique emotional and developmental needs of pediatric patients, these neurosurgeons not only provide cutting-edge medical care but also prioritize clear communication and support for parents and guardians throughout the treatment journey. This holistic approach underscores the importance of addressing not only the physical health of the child but also their overall well-being.",
      //         },
      //     ],
      // },
      // {
      //     name:"Functional Neurosurgery",
      //     img:"../../photo/lll11.jpg",
      //     content:"Functional neurosurgery is a specialized branch of neurosurgery that focuses on...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Functional neurosurgery is a specialized branch of neurosurgery that focuses on altering or modulating the function of the nervous system to treat neurological and psychiatric disorders. Unlike traditional neurosurgery, which often involves structural interventions like tumor removal or repairing physical abnormalities, functional neurosurgery primarily targets neural circuits and networks to alleviate symptoms and improve the quality of life for patients.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"One of the key techniques within functional neurosurgery is deep brain stimulation (DBS), which involves implanting electrodes into specific areas of the brain. These electrodes emit controlled electrical impulses that help regulate abnormal neuronal activity associated with conditions like Parkinson's disease, essential tremor, dystonia, and even severe cases of obsessive-compulsive disorder (OCD) and epilepsy. DBS offers a remarkable tool for symptom management, with the ability to fine-tune stimulation parameters to achieve optimal therapeutic effects.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Another approach in functional neurosurgery is lesioning, where targeted destruction of specific brain tissue is carried out to disrupt abnormal neural pathways. Lesioning techniques, such as radiofrequency ablation or stereotactic radiosurgery, can be employed to treat conditions like chronic pain, movement disorders, and trigeminal neuralgia, providing relief by interrupting the transmission of pain signals or dysfunctional neural activity.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Functional neurosurgery requires a profound understanding of neuroanatomy, neural circuitry, and the intricate interplay between brain regions. Neurosurgeons specializing in this field work closely with neurologists, neuropsychologists, and other specialists to carefully assess each patient's condition and determine the most appropriate intervention to enhance neural function and restore a higher quality of life.",
      //         },
      //     ],
      // },
      {
        name: "Emergency and Neurotrauma",
        img: "../../photo/lll12.jpg",
        content:
          "Emergency neurotrauma involves the immediate medical response to injuries that affect the...",
        bodyes: [
          {
            img: "../photo/artificial-intelligence.png",
            name: "Traumatic Skull fractures ",
            ques: "",
            content:
              "The skull can break, or fracture, if it is subject to a direct and forceful impact. The underlying cause of a skull fracture is a head trauma that is significant enough to break at least one bone.",
          },
          {
            img: "../photo/artificial-intelligence.png",
            name: "Traumatic brain injuries ",
            ques: "",
            content:
              "A traumatic brain injury can result when the head suddenly and violently hits an object or when an object pierces the skull and enters brain tissue. Mild injury may result in a brief change in mental state or consciousness, while severe injury may result in extended periods of unconsciousness, coma or even death. Many patients with moderate or severe head injuries are taken directly from the emergency room to the operating room and surgery is performed to remove a large hematoma or contusion that is significantly compressing the brain or raising the pressure within the skull. ",
          },
          {
            img: "../photo/artificial-intelligence.png",
            name: "Traumatic Spinal injuries ",
            ques: "",
            content:
              "A spinal cord injury is damage to the spinal cord that causes temporary or permanent changes in its function. Symptoms may include loss of muscle function, sensation, or autonomic function in the parts of the body served by the spinal cord below the level of the injury.",
          },
        ],
      },
      {
        name: "Neuro-oncology",
        img: "../../photo/lll13.jpg",
        content:
          "Neuro-oncology is a specialized field of medicine that focuses on the diagnosis, treatment...",
        bodyes: [
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Simple and complex brain tumors ",
            ques: "",
            content:
              "A brain tumor is an abnormal growth of cells inside the brain or skull; some are benign, others malignant. Tumors can grow from the brain tissue itself (primary), or cancer from elsewhere in the body can spread to the brain (metastasis). Treatment options vary depending on the tumor type, size and location.",
          },
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Skull base tumors ",
            ques: "",
            content:
              "A skull base tumor is an abnormal growth in the part of the brain that meets the base of the skull. Most skull base tumors are benign and don't spread to other parts of the body. But even benign ones, which grow slowly, can cause serious damage.",
          },
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Pituitary gland tumors ",
            ques: "",
            content:
              "A pituitary gland tumor is a group of abnormal cells that grows out of control in your pituitary gland. Most of these tumors are not cancerous. The tumors can cause serious problems, either because of their size or because they make extra hormones that your body doesn’t need.",
          },
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Spinal tumors ",
            ques: "",
            content:
              "Tumors of the brain and spinal cord are abnormal growths of tissue found inside the skull or the bony spinal column. Tumors that originate in the brain or spinal cord are called primary tumors. Symptoms of brain tumors include headaches, seizures, nausea and vomiting, poor vision or hearing, changes in behavior, unclear thinking, and unsteadiness. Spinal cord tumor symptoms include pain, numbness, and paralysis",
          },
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Brain stem tumors ",
            ques: "",
            content:
              "Brain stem glioma is a type of central nervous system tumor. This type of tumor begins when healthy cells in the brain stem change and grow out of control, forming a mass called a tumor. A tumor can be cancerous or benign.",
          },
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Pediatric brain tumors ",
            ques: "",
            content:
              "Pediatric brain tumors are masses or growths of abnormal cells that occur in a child's brain or the tissue and structures that are near it. Many different types of pediatric brain tumors exist — some are noncancerous and some are cancerous",
          },
          {
            img: "../photo/artificial-intelligence (1).png",
            name: "Peripheral nerve sheath tumors ",
            ques: "",
            content:
              "Peripheral nerve sheath tumor is a tumor that develops in the protective lining that covers nerves. The common symptom is often a lump or mass that increases in size, sometimes causing pain or a tingling sensation.",
          },
        ],
      },
      {
        name: "Neurovascular diseases",
        img: "../../photo/lll14.jpg",
        content:
          "Neurovascular diseases refer to a group of conditions that involve the blood vessels of the...",
        bodyes: [
          {
            img: "../photo/brain.png",
            name: "Single and multiple brain aneurysms ",
            ques: "",
            content:
              "A cerebral aneurysm is a weak or thin spot on an artery in the brain that balloons or bulges out and fills with blood. The bulging aneurysm can put pressure on the nerves or brain tissue. It may also burst or rupture, spilling blood into the surrounding tissue. A ruptured aneurysm can cause serious health problems such as hemorrhagic stroke, brain damage, coma, and even death.",
          },
          {
            img: "../photo/brain.png",
            name: "Vascular malformations ",
            ques: "",
            content:
              "A brain arteriovenous malformation (AVM) is a tangle of abnormal blood vessels connecting arteries and veins in the brain. A brain AVM disrupts this vital process. An arteriovenous malformation can develop anywhere in your body but occurs most often in the brain or spine.The cause of AVMs is not clear. Most people are born with them, but they can occasionally form later in life.",
          },
          {
            img: "../photo/brain.png",
            name: "Stroke intervention and thrombolysis ",
            ques: "",
            content:
              "Thrombolytic medicines are approved for the emergency treatment of stroke and heart attack. The most commonly used drug for thrombolytic therapy is tissue plasminogen activator",
          },
          {
            img: "../photo/brain.png",
            name: "Cerebrovascular disorders ",
            ques: "",
            content:
              "Cerebrovascular disease includes stroke, carotid stenosis, vertebral stenosis and intracranial stenosis, aneurysms, and vascular malformations. Restrictions in blood flow may occur from vessel narrowing clot formation, blockage or blood vessel rupture.",
          },
          {
            img: "../photo/brain.png",
            name: "Carotid stenosis and stenting ",
            ques: "",
            content:
              "In carotid stenting, a long, hollow catheter is threaded through the arteries to the narrowed carotid artery in the neck. A metal mesh tube (stent) is inserted into the vessel to serve as a scaffold that helps prevent the artery from narrowing again.",
          },
          {
            img: "../photo/brain.png",
            name: "Cerebral venous thrombosis ",
            ques: "",
            content:
              "Cerebral venous thrombosis (CVT) is a blood clot of a cerebral vein in the brain. This vein is responsible for draining blood from the brain. If blood collects in this vein, it will begin to leak into brain tissues and cause a hemorrhage or severe brain swelling",
          },
        ],
      },
      {
        name: "Functional neurosurgery",
        img: "../../photo/lll15.jpg",
        content:
          "Functional neurosurgery is a specialized field within neurosurgery that focuses on the...",
        bodyes: [
          {
            img: "../photo/psychology.png",
            name: "Parkinsonism ",
            ques: "",
            content:
              "A skull base tumor is an abnormal growth in the part of the brain that meets the base of the skull. Most skull base tumors are benign and don't spread to other parts of the body. But even benign ones, which grow slowly, can cause serious damage",
          },
          {
            img: "../photo/psychology.png",
            name: "Movement disorders ",
            ques: "",
            content:
              "A pituitary gland tumor is a group of abnormal cells that grows out of control in your pituitary gland. Most of these tumors are not cancerous. The tumors can cause serious problems, either because of their size or because they make extra hormones that your body doesn’t need.",
          },
          {
            img: "../photo/psychology.png",
            name: "Epilepsy and other seizure disorders ",
            ques: "",
            content:
              "Tumors of the brain and spinal cord are abnormal growths of tissue found inside the skull or the bony spinal column. Tumors that originate in the brain or spinal cord are called primary tumors.",
          },
          {
            img: "../photo/psychology.png",
            name: "Spasticity ",
            ques: "",
            content:
              "Tumors of the brain and spinal cord are abnormal growths of tissue found inside the skull or the bony spinal column. Tumors that originate in the brain or spinal cord are called primary tumors.",
          },
          {
            img: "../photo/psychology.png",
            name: "Chronic pain disorder ",
            ques: "",
            content:
              "Tumors of the brain and spinal cord are abnormal growths of tissue found inside the skull or the bony spinal column. Tumors that originate in the brain or spinal cord are called primary tumors.",
          },
          {
            img: "../photo/psychology.png",
            name: "Deep brain stimulation surgery for other disorders ",
            ques: "",
            content:
              "Tumors of the brain and spinal cord are abnormal growths of tissue found inside the skull or the bony spinal column. Tumors that originate in the brain or spinal cord are called primary tumors.",
          },
        ],
      },
      {
        name: "Spine degeneration",
        img: "../../photo/lll16.jpg",
        content:
          "Spine degeneration, also known as spinal degeneration or degenerative disc disease, is a...",
        bodyes: [
          {
            img: "../photo/brain (1).png",
            name: "Spondylosis and spondylolisthesis",
            ques: "",
            content:
              "Spondylolysis is the most common cause of isthmic spondylolisthesis, in which one vertebral body is slipped forward over another. Isthmic spondylolisthesis is the most common cause of back pain in adolescents; however, most adolescents with spondylolisthesis do not actually experience any symptoms or pain. Cases of either neurological deficits or paralysis are exceedingly rare, and for the most part it is not a dangerous condition. The most common symptom is back and/or leg pain that limits a patient's activity level.",
          },
          {
            img: "../photo/brain (1).png",
            name: "Disc prolapse",
            ques: "",
            content:
              "A herniated disk refers to a problem with cushions (disks) that sit between the individual bones (vertebrae) that stack to make your spine. A herniated disk occurs when some of the nucleus pushes out through a tear in the annulus. A herniated disk, which can occur in any part of the spine, can irritate a nearby nerve. Depending on where the herniated disk is, it can result in pain, numbness or weakness in an arm or leg.",
          },
          {
            img: "../photo/brain (1).png",
            name: "Facet blocks",
            ques: "",
            content:
              "A lumbar facet joint block is an injection of local anesthetic into one or more of the small joints located along the side of each vertebrae on both sides of the spine in the lower part of the back. The major indications for facet injections include strong clinical suspicion of the facet syndrome, focal tenderness over the facet joints, low back pain with normal radiological findings, postlaminectomy syndrome with no evidence of arachnoiditis or recurrent disc disease",
          },
          {
            img: "../photo/brain (1).png",
            name: "Epidural nerve blocks",
            ques: "",
            content:
              "Epidural nerve blocks are corticosteroid injections which are used to decrease pain and inflammation caused by a herniated disc, sciatica, or spinal stenosis. Epidural nerve block treatments safely offer substantial pain relief without surgery. Epidural nerve blocks may be used to treat: Herniated Disc.",
          },
          {
            img: "../photo/brain (1).png",
            name: "Minimally invasive neuro surgeries",
            ques: "",
            content:
              "Minimally invasive keyhole and endoscopic surgery is now possible for most brain and skull base tumors. Keyhole surgery is the concept of safely removing brain & skull base tumors through smaller, more precise openings that minimize collateral damage to surrounding scalp, brain, blood vessels, and nerves",
          },
          {
            img: "../photo/brain (1).png",
            name: "Endoscopic spine surgeries",
            ques: "",
            content:
              "Endoscopic Spine Surgery is a state-of-the-art minimally-invasive spine surgery system that has revolutionized the treatment of back and neck disorders. It utilizes small tubes through millimeter long skin cuts, assisted with a high-definition camera, called Endoscope.",
          },
        ],
      },
      {
        name: "Paediatric neurosurgery",
        img: "../../photo/lll17.jpg",
        content:
          "Pediatric neurosurgery is a specialized branch of medical surgery that focuses on the...",
        bodyes: [
          {
            img: "../photo/patient-1.png",
            name: "Hydrocephalus ",
            ques: "",
            content:
              "Hydrocephalus. Hydrocephalus is a buildup of fluid in the brain, or more precisely, cerebrospinal fluid in the ventricular system, which causes potentially damaging increased pressure in the head. Most children who have it are born with it.",
          },
          {
            img: "../photo/patient-1.png",
            name: "Split cord malformation ",
            ques: "",
            content:
              "Split spinal cord malformation (SSCM) is a rare form of spinal dysraphism in which a person is born with splitting, or duplication , of the spinal cord. It may be characterized by complete or incomplete division of the spinal cord, resulting in two 'hemicords.",
          },
          {
            img: "../photo/patient-1.png",
            name: "Craniosynostosis ",
            ques: "",
            content:
              "Craniosynostosis is a birth defect in which the bones in a baby's skull join together too early. This happens before the baby's brain is fully formed. As the baby's brain grows, the skull can become more misshapen.",
          },
        ],
      },
    ],
  },
  {
    name: "Internal Medicine",
    img: "",
    icon: "",
    content:
      "Our Internal Medicine department offers specialized care for adults, emphasizing prevention, diagnosis, and management of diverse medical conditions. Our skilled internists focus on your overall well-being, providing personalized treatment plans and thorough assessments. From routine check-ups to complex cases, we are dedicated to your health and collaborate with other specialties to ensure comprehensive care. Trust us for expert adult healthcare.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Wahid",
        quali: "MBBS, MD, (Diabetology)",
        experi: "5",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Vikram",
        quali: "MBBS, MD (Fellowship Geriatrics)",
        experi: "5",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Diabetology",
        img: "../photo/eee9.jpg",
        content:
          "Diabetology is a specialized field of medicine that focuses on the study, diagnosis...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diabetology is a specialized field of medicine that focuses on the study, diagnosis, management, and treatment of diabetes mellitus, a chronic metabolic disorder characterized by high levels of blood sugar (glucose). Diabetologists are medical professionals who specialize in the care of individuals with diabetes, ensuring they receive appropriate medical attention, education, and support to effectively manage their condition and prevent complications.",
          },
          {
            img: "",
            name: "Diabetes mellitus is primarily classified into three main types",
            ques: "1) Type 1 Diabetes ",
            content:
              "This autoimmune condition occurs when the body's immune system mistakenly attacks and destroys the insulin-producing cells in the pancreas. As a result, people with Type 1 diabetes require lifelong insulin therapy to regulate their blood sugar levels.",
          },
          {
            img: "",
            name: "",
            ques: "2) Type 2 Diabetes ",
            content:
              "This form of diabetes is characterized by insulin resistance, where the body's cells do not respond effectively to insulin, and the pancreas gradually loses its ability to produce sufficient insulin. Lifestyle modifications, oral medications, injectable medications, and sometimes insulin therapy are used to manage Type 2 diabetes.",
          },
          {
            img: "",
            name: "",
            ques: "3) Gestational Diabetes ",
            content:
              "This type of diabetes develops during pregnancy and is characterized by elevated blood sugar levels. It usually resolves after childbirth, but it increases the risk of Type 2 diabetes in both the mother and child.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diabetologists work closely with other healthcare professionals, such as endocrinologists, nutritionists, and nurses, to develop comprehensive treatment plans for individuals with diabetes. Treatment plans often involve a combination of medications, lifestyle changes (such as diet and exercise), blood sugar monitoring, and education to help patients manage their condition effectively and reduce the risk of complications such as cardiovascular disease, kidney problems, nerve damage, and eye issues.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Regular check-ups and ongoing management are essential for people with diabetes to maintain their health and prevent complications. Diabetologists play a vital role in guiding patients through the complexities of diabetes management and promoting their overall well-being.",
          },
        ],
      },
      {
        name: "Hypertension",
        img: "../photo/eee7.jpg",
        content:
          "Hypertension, commonly known as high blood pressure, is a medical condition...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hypertension, commonly known as high blood pressure, is a medical condition characterized by elevated pressure in the arteries that carry blood from the heart to the rest of the body. Blood pressure is measured in millimeters of mercury (mmHg) and is recorded as two numbers: systolic pressure over diastolic pressure. The systolic pressure is the higher number and represents the force of blood against artery walls when the heart contracts and pumps blood. The diastolic pressure is the lower number and represents the pressure in the arteries when the heart is resting between beats.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hypertension occurs when the blood pressure consistently remains higher than normal over an extended period of time. Normal blood pressure is typically around 120/80 mmHg, where 120 represents the systolic pressure and 80 represents the diastolic pressure. Hypertension is usually diagnosed when blood pressure readings consistently exceed 130/80 mmHg.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Chronic hypertension can have detrimental effects on the cardiovascular system and overall health. It puts extra strain on the arteries, heart, and other organs, increasing the risk of serious health problems such as heart disease, stroke, kidney damage, and more.",
          },
          {
            img: "",
            name: "There are two main types of hypertension",
            ques: "1) Primary Hypertension ",
            content:
              "Also known as essential hypertension, this is the most common type and often develops over time with no identifiable underlying cause. It is influenced by a combination of genetic factors, age, lifestyle choices (such as diet, physical activity, and stress), and environmental factors.",
          },
          {
            img: "",
            name: "",
            ques: "2) Secondary Hypertension ",
            content:
              "This type is caused by an underlying medical condition, such as kidney disease, hormonal disorders, or certain medications. Treating the underlying cause can often help manage secondary hypertension.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Lifestyle modifications and, in some cases, medications are typically recommended to manage and control hypertension. Lifestyle changes may include adopting a heart-healthy diet (such as the DASH diet), engaging in regular physical activity, managing stress, limiting alcohol intake, and quitting smoking.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Regular blood pressure monitoring and working closely with a healthcare provider are crucial for managing hypertension and reducing the associated health risks. It's important to keep blood pressure under control to minimize the risk of serious complications and maintain overall well-being.",
          },
        ],
      },
      {
        name: "Thyroid",
        img: "../photo/eee8.jpg",
        content:
          "The thyroid is a butterfly-shaped gland located in the front of the neck, just below the...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The thyroid is a butterfly-shaped gland located in the front of the neck, just below the Adam's apple. It is a vital part of the endocrine system, which produces hormones that regulate various bodily functions. The thyroid gland plays a crucial role in metabolism, growth, and energy regulation. It produces two primary hormones: thyroxine (T4) and triiodothyronine (T3). These hormones influence the rate at which cells use energy and help maintain the body's overall balance, or homeostasis.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Thyroid function is controlled by the hypothalamus-pituitary-thyroid (HPT) axis. The hypothalamus releases thyrotropin-releasing hormone (TRH), which prompts the pituitary gland to release thyroid-stimulating hormone (TSH). TSH then signals the thyroid gland to produce and release T3 and T4 into the bloodstream. These hormones are crucial for the proper functioning of various organs, including the heart, brain, and muscles.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Disorders of the thyroid gland can lead to various health issues. Hypothyroidism occurs when the thyroid produces insufficient amounts of T3 and T4, leading to symptoms such as fatigue, weight gain, and sensitivity to cold. On the other hand, hyperthyroidism results from an overactive thyroid that produces excessive amounts of these hormones, causing symptoms like weight loss, rapid heartbeat, and anxiety. Conditions like Hashimoto's thyroiditis and Graves' disease are autoimmune disorders that affect the thyroid and can contribute to these imbalances. Proper diagnosis and treatment, often involving medication, dietary changes, and, in some cases, surgery, are essential for maintaining thyroid health and overall well-being.",
          },
        ],
      },

      {
        name: "Infectious Diseases",
        img: "../../photo/eee11.jpg",
        content:
          "Infectious diseases are a critical area of focus within the field of internal medicine...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Infectious diseases are a critical area of focus within the field of internal medicine. Internal medicine doctors, also known as internists, often play a central role in diagnosing, managing, and treating various infectious diseases. Here's an overview of how infectious diseases are addressed within internal medicine ",
          },
          {
            img: "",
            name: "",
            ques: "1) Diagnosis ",
            content:
              "Internists are skilled at diagnosing infectious diseases by evaluating patient symptoms, medical history, and conducting relevant diagnostic tests. These tests may include blood work, cultures, molecular tests (such as PCR), imaging studies, and more.",
          },
          {
            img: "",
            name: "",
            ques: "2) Treatment ",
            content:
              "Internists prescribe appropriate medications to treat infections. This can involve antibiotics for bacterial infections, antiviral medications for viral infections, antifungal drugs for fungal infections, and antiparasitic medications for parasitic infections.",
          },
          {
            img: "",
            name: "",
            ques: "3) Antibiotic Stewardship ",
            content:
              "Internists promote responsible antibiotic use to prevent antibiotic resistance. They choose antibiotics carefully based on the type of infection and the susceptibility of the causative agent.",
          },
          {
            img: "",
            name: "",
            ques: "4) Infection Prevention and Control ",
            content:
              "Internists emphasize infection control measures to prevent the spread of infections, especially in healthcare settings. They educate patients and colleagues about proper hygiene, isolation protocols, and vaccination.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Internists often have a broad understanding of how infectious diseases can interact with underlying health conditions, making them well-equipped to provide holistic care. In cases requiring specialized expertise, they may refer patients to infectious disease specialists while continuing to manage overall patient care.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Overall, infectious diseases form a crucial part of internal medicine, and internists are trained to diagnose, treat, and prevent a wide range of infectious diseases to improve patient health and well-being.",
          },
        ],
      },

      {
        name: "Endocrine Disorders",
        img: "../../photo/eee10.jpg",
        content:
          "Endocrine disorders, encompassing conditions related to hormonal imbalances and...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Endocrine disorders, encompassing conditions related to hormonal imbalances and dysfunction, constitute a significant domain within the practice of internal medicine. Internal medicine doctors, or internists, are skilled in diagnosing and managing a spectrum of endocrine disorders, including diabetes, thyroid dysfunction, adrenal and pituitary gland disorders, as well as hormonal imbalances affecting reproductive and metabolic health. They play a pivotal role in prescribing hormone replacement therapies, guiding lifestyle modifications, and monitoring patients' overall well-being. By collaborating with endocrinologists and other specialists as needed, internists ensure comprehensive and tailored care for patients with endocrine disorders, aiming to restore hormonal equilibrium, alleviate symptoms, and prevent potential complications.",
          },
        ],
      },
      {
        name: "Medication Management",
        img: "../../photo/eee1.jpg",
        content:
          "Medication management is a critical aspect of medical care within Internal Medicine...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Medication management is a critical aspect of medical care within Internal Medicine. It involves the precise prescription, administration, monitoring, and adjustment of medications to ensure optimal treatment outcomes. Skilled internists carefully consider a patient's medical history, existing conditions, potential interactions, and individual responses to medications when creating a personalized treatment plan.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Internists prescribe various types of medications to address a wide range of health conditions, including hypertension, diabetes, heart disease, infections, autoimmune disorders, and more. They play a pivotal role in ensuring the appropriate dosage and frequency of medication intake while monitoring for potential side effects or adverse reactions.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Medication management extends beyond initial prescriptions. Internists routinely review and adjust medications as needed based on a patient's progress and changing health status. This proactive approach helps prevent complications, optimize therapeutic benefits, and improve overall patient well-being.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Effective medication management requires open communication between patients and their healthcare providers. It empowers patients to understand their treatment plans, ask questions, and report any changes or concerns regarding their medication regimen. This collaborative approach fosters a safer and more successful healthcare experience, ultimately contributing to better health outcomes.",
          },
        ],
      },
      {
        name: "Lifestyle Modifications",
        img: "../../photo/eee2.jpg",
        content:
          "Lifestyle modifications are fundamental changes individuals make to their daily habits...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Lifestyle modifications are fundamental changes individuals make to their daily habits and behaviors to improve their overall health and well-being. In the context of Internal Medicine, these changes are often recommended by healthcare professionals, including internists, to prevent, manage, or treat various medical conditions.",
          },
          {
            img: "",
            name: "Key lifestyle modifications include",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "Dietary Changes ",
            content:
              "Internists advise on adopting a balanced and nutritious diet tailored to specific health needs. This may involve reducing unhealthy fats, sugar, and sodium, increasing fiber intake, and incorporating more fruits, vegetables, lean proteins, and whole grains.",
          },
          {
            img: "",
            name: "",
            ques: "Regular Exercise ",
            content:
              "Internists emphasize the importance of physical activity for maintaining cardiovascular health, managing weight, and improving overall fitness. Customized exercise plans may be recommended based on individual health goals and limitations.",
          },
          {
            img: "",
            name: "",
            ques: "Stress Management ",
            content:
              "Techniques like mindfulness, meditation, deep breathing, and yoga are suggested to help manage stress, which can impact various aspects of health.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "By making sustainable lifestyle changes, individuals can positively impact their health and potentially reduce the need for medication or more invasive medical interventions. Internists collaborate closely with patients, guiding them toward making choices that contribute to long-term health and well-being.",
          },
        ],
      },
      {
        name: "Preventive Screenings",
        img: "../../photo/eee3.jpg",
        content:
          "Preventive screenings are medical tests or examinations that are conducted on...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Preventive screenings are medical tests or examinations that are conducted on individuals who are generally healthy and have no symptoms of a specific medical condition. The purpose of preventive screenings is to detect potential health issues early, before they become more serious or symptomatic, allowing for timely intervention and treatment. These screenings can help in identifying risk factors, monitoring health, and promoting overall well-being. The specific screenings recommended can vary based on factors such as age, gender, family history, and individual risk factors.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "It's important to note that medical guidelines and recommendations can change over time, so it's always best to consult with a healthcare professional or primary care physician to determine the appropriate preventive screenings for your specific situation. However, here are some common preventive screenings that are often recommended",
          },
          {
            img: "",
            name: "",
            ques: "Blood Pressure Screening ",
            content:
              "Regular blood pressure checks are important to monitor for hypertension (high blood pressure), which is a significant risk factor for heart disease, stroke, and other health issues.",
          },
          {
            img: "",
            name: "",
            ques: "Cholesterol Screening ",
            content:
              "Checking cholesterol levels helps assess the risk of heart disease and other cardiovascular conditions.",
          },
          {
            img: "",
            name: "",
            ques: "Blood Glucose Testing ",
            content:
              "This helps detect diabetes or prediabetes, which can have serious health implications if left untreated.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Remember that these are general guidelines, and individual recommendations may vary. Your healthcare provider will help determine which screenings are appropriate for you based on your medical history, risk factors, and age. Preventive screenings play a crucial role in maintaining good health and preventing potential health problems from becoming more serious.",
          },
        ],
      },
      {
        name: "Vaccinations",
        img: "../../photo/eee4.jpg",
        content:
          "Vaccinations, also known as immunizations, are crucial medical interventions designed...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Vaccinations, also known as immunizations, are crucial medical interventions designed to protect individuals and communities from a range of infectious diseases. They work by stimulating the immune system to produce an immune response against specific pathogens, such as viruses or bacteria, without causing the actual disease. This response equips the immune system with the ability to recognize and fight off the pathogens if the individual is exposed to them in the future.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Vaccinations have played a significant role in reducing the prevalence and impact of many life-threatening diseases. They have led to the complete eradication of smallpox and have substantially reduced the incidence of diseases like polio, measles, mumps, rubella, and more. By achieving widespread immunity within populations through vaccination, the transmission of diseases can be disrupted, leading to fewer cases and ultimately preventing outbreaks.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Herd immunity, also known as community immunity, is a critical concept in vaccination. When a high percentage of a population is immune to a disease, either through vaccination or previous infection, the spread of the disease is limited. This not only protects those who are directly vaccinated but also those who may be unable to receive vaccines due to medical conditions or other reasons. Vaccinations have proven to be one of the most effective and cost-efficient public health measures, preventing suffering, hospitalizations, and deaths while contributing to the overall well-being of societies.",
          },
        ],
      },
      // {
      //     name:"Diagnostic Tests",
      //     img:"../../photo/eee5.jpg",
      //     content:"Diagnostic tests are medical procedures conducted to identify, confirm, or monitor...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Diagnostic tests are medical procedures conducted to identify, confirm, or monitor various health conditions in individuals. These tests are essential tools used by healthcare professionals to gather critical information about a patient's health and to make informed decisions regarding treatment and care.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"One category of diagnostic tests involves laboratory tests, which analyze samples of bodily fluids such as blood, urine, or tissue. These tests provide valuable insights into a person's overall health and specific organ functions. For example, blood tests can measure levels of various substances, aiding in the detection of conditions like diabetes, kidney disease, or infections. DNA tests delve into a person's genetic makeup, helping diagnose hereditary diseases and assess predisposition to certain disorders.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Imaging studies constitute another vital aspect of diagnostic tests, enabling visualization of internal structures and abnormalities within the body. Techniques such as X-rays create detailed images of bones and tissues, facilitating the identification of fractures or lung problems. Advanced methods like MRI use powerful magnets to generate detailed images of soft tissues, assisting in the diagnosis of conditions affecting the brain, spinal cord, and joints. These imaging tools offer valuable insights into the structural integrity and functioning of organs and systems.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Lastly, functional tests and specialized procedures provide insights into how specific organs or systems are functioning. Electrocardiograms (ECGs or EKGs) measure heart activity, diagnosing irregular heart rhythms and other cardiac issues. Endoscopy involves inserting a camera-equipped tube into the body to visualize internal structures like the gastrointestinal tract. These diagnostic procedures help healthcare professionals accurately assess health conditions, tailor treatments, and monitor progress over time, ultimately contributing to improved patient outcomes and well-being.",
      //         },
      //     ],
      // },
      // {
      //     name:"Chronic Disease Management",
      //     img:"../../photo/eee6.jpg",
      //     content:"Chronic disease management is a comprehensive and ongoing approach aimed at...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Chronic disease management is a comprehensive and ongoing approach aimed at effectively addressing and controlling long-term medical conditions that persist over time. These conditions, often characterized by their non-curable nature, demand consistent medical attention and personalized strategies to enhance the patient's quality of life and mitigate potential complications.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"At the core of chronic disease management is accurate diagnosis and assessment, which lay the foundation for a tailored treatment plan. This may involve a thorough evaluation of the patient's medical history, symptoms, and diagnostic tests to gain a holistic understanding of the condition. Based on this assessment, medical professionals can prescribe appropriate medications, therapies, and interventions to manage symptoms, slow disease progression, and improve overall health.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Lifestyle modifications are another integral component of chronic disease management. Patients are encouraged to make sustainable changes to their daily routines, encompassing dietary choices, physical activity levels, and stress management techniques. These adjustments play a pivotal role in maintaining stable health and preventing exacerbations of the chronic condition. By empowering individuals to take control of their health through informed decision-making and active self-care practices, chronic disease management strives to enhance the individual's well-being and foster a sense of empowerment in their journey toward better health.",
      //         },
      //     ],
      // },
    ],
  },
  {
    name: "Cardiology",
    img: "",
    icon: "",
    content:
      "At our hospital, we provide comprehensive cardiology care. Our team of experienced cardiologists employs advanced diagnostics, personalized treatments, and interventions when necessary. We prioritize preventive strategies, guiding patients toward heart-healthy lifestyles through education. In emergencies like heart attacks, our swift response is crucial. Patient well-being is paramount, and our holistic approach ensures thorough care for improved heart health and enhanced quality of life.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Disha Shetty",
        quali: "MBBS, MD, DM(Gold Medal Jayadeva Instute of Cardiology)",
        experi: "4",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Cardiovascular Disease",
        img: "../photo/mmm10.jpg",
        content:
          "Cardiovascular disease (CVD) is a group of disorders that affect the heart and blood...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Cardiovascular disease (CVD) is a group of disorders that affect the heart and blood vessels, leading to conditions such as coronary artery disease, heart failure, and stroke. It's one of the leading causes of morbidity and mortality worldwide. CVD encompasses a range of issues, including the buildup of fatty deposits (plaques) in the arteries, narrowing blood vessels, and impairing blood flow. This can result in reduced oxygen and nutrient supply to vital organs, especially the heart and brain. Major risk factors for CVD include high blood pressure, high cholesterol levels, smoking, diabetes, obesity, and a sedentary lifestyle. Atherosclerosis, the accumulation of plaques in arteries, is a central process underlying many CVDs. Symptoms vary based on the specific condition but can include chest pain, shortness of breath, fatigue, and palpitations. Early detection, lifestyle modifications (healthy diet, exercise, not smoking), medication, and in some cases, medical procedures or surgeries are vital in preventing and managing CVD, reducing its impact on individuals and public health.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Efforts to combat CVD involve both primary prevention, focusing on risk factor reduction in healthy individuals, and secondary prevention, targeting those who already have CVD to prevent progression and complications. Cardiovascular disease management often involves a multidisciplinary approach, with cardiologists, internists, dietitians, and other healthcare professionals working collaboratively to tailor treatment plans to each patient's unique needs. Public health initiatives, education campaigns, and research continue to play pivotal roles in addressing cardiovascular disease's global impact, aiming to reduce its incidence and improve outcomes for those affected.",
          },
        ],
      },
      {
        name: "Angioplasty",
        img: "../photo/mmm1.jpg",
        content:
          "Angioplasty is a medical procedure used to treat blocked or narrowed blood vessels,...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Angioplasty is a medical procedure used to treat blocked or narrowed blood vessels, typically arteries, in order to restore proper blood flow. The procedure is commonly performed to address coronary artery disease (CAD), which is the narrowing of the arteries that supply blood to the heart muscle. Angioplasty can help alleviate symptoms like chest pain (angina) and reduce the risk of heart attack.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During angioplasty, a thin and flexible tube called a catheter is inserted into a blood vessel, often through the groin or wrist. The catheter is carefully guided to the site of the blockage using imaging techniques such as X-rays. Once in position, a small balloon at the tip of the catheter is inflated. This inflation compresses the plaque or fatty deposits that have accumulated on the artery walls, widening the artery and restoring blood flow.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "In some cases, a stent may also be used. A stent is a small mesh-like tube that is placed over the deflated balloon. When the balloon is inflated, the stent expands and remains in place to help keep the artery open even after the balloon is deflated and removed. This provides ongoing support to the artery and reduces the risk of re-narrowing.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Angioplasty is generally considered a minimally invasive procedure, meaning it involves only a small incision or entry point and often has a shorter recovery time compared to traditional open-heart surgery. It is an important treatment option for individuals with blocked or narrowed arteries, helping to improve blood flow and overall heart health. However, like any medical procedure, angioplasty comes with potential risks and benefits, and the decision to undergo it should be made in consultation with a qualified healthcare professional.",
          },
        ],
      },
      // {
      //     name:"Pacemaker Implantation",
      //     img:"../photo/mmm2.jpg",
      //     content:"Pacemaker implantation is a medical procedure used to treat certain heart conditions...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Pacemaker implantation is a medical procedure used to treat certain heart conditions by placing a small device called a pacemaker inside the body. A pacemaker is designed to regulate and control the heart's rhythm, ensuring that it beats at a steady and appropriate pace. This procedure is commonly performed on individuals who have a slow heart rate (bradycardia) or irregular heart rhythms (arrhythmias) that could potentially lead to symptoms like dizziness, fainting, fatigue, or even life-threatening complications.",
      //         },
      //         {
      //             img:"",
      //             name:"During the pacemaker implantation procedure, a skilled cardiologist or electrophysiologist (a doctor who specializes in heart rhythm disorders) typically performs the following steps ",
      //             ques:"",
      //             content:"",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"Preparation ",
      //             content:"The patient is given local anesthesia to numb the area where the pacemaker will be inserted, usually near the collarbone. In some cases, general anesthesia may be used.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"Incision ",
      //             content:"A small incision is made to create a pocket beneath the skin for the pacemaker device.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"Lead Placement ",
      //             content:"One or more thin, insulated wires (leads) are carefully threaded through a vein and into the heart. These leads are equipped with electrodes that detect the heart's electrical activity and deliver electrical impulses to regulate the heartbeat.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Following the procedure, patients are usually monitored for a short period to ensure the pacemaker is functioning correctly and to observe any initial changes in heart rhythm. Most individuals can return to their normal activities with certain restrictions, and they are typically provided with information on how to care for the incision site and the pacemaker itself.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Pacemaker implantation is a well-established and effective treatment that can significantly improve a person's quality of life by maintaining a steady heart rate. Modern pacemakers are often equipped with advanced features, such as rate-responsive pacing (adjusting heart rate based on physical activity) and remote monitoring capabilities, which allow healthcare providers to track the device's performance and the patient's heart rhythm remotely. As with any medical procedure, pacemaker implantation comes with potential risks and benefits, and decisions about its necessity are made in consultation with a qualified medical professional.",
      //         },
      //     ],
      // },
      {
        name: "Intravascular Imaging",
        img: "../photo/mmm3.jpg",
        content:
          "Intravascular imaging refers to a set of advanced medical techniques that allow...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Intravascular imaging refers to a set of advanced medical techniques that allow healthcare professionals to visualize the inside of blood vessels, particularly arteries, using specialized imaging tools and catheters that are inserted directly into the blood vessels. These techniques provide detailed and real-time information about the condition of the blood vessel walls, the presence of plaque or blockages, and other abnormalities. Intravascular imaging plays a crucial role in diagnosing and guiding treatment for various cardiovascular conditions.",
          },
          {
            img: "",
            name: "There are two primary types of intravascular imaging ",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "1) Intravascular Ultrasound (IVUS)",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "IVUS uses sound waves to create cross-sectional images of the blood vessel walls. A tiny ultrasound probe is attached to the tip of a catheter, which is inserted into the blood vessel. The probe emits sound waves that bounce off the vessel walls and create detailed images that help visualize the vessel's structure, size, and the extent of plaque buildup. IVUS is valuable for assessing the severity of blockages, measuring vessel dimensions, and guiding interventions like angioplasty and stent placement.",
          },
          {
            img: "",
            name: "",
            ques: "2) Optical Coherence Tomography (OCT)",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "OCT utilizes light waves to produce high-resolution, detailed images of blood vessel walls. A catheter with a specialized light source and sensor is inserted into the vessel, and light waves are directed at the vessel walls. The reflected light is then used to create precise cross-sectional images. OCT provides exceptional clarity, enabling visualization of even smaller structures within the vessel walls, such as thin layers of plaque or the arrangement of stent struts after implantation. This level of detail assists in precise diagnosis and optimal treatment planning.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Intravascular imaging is commonly performed during cardiac catheterization procedures, particularly when angioplasty or stent placement is considered. It allows healthcare providers to make informed decisions about the type of intervention needed and the appropriate size and placement of stents. By directly visualizing the vessel walls and assessing the severity of blockages, intravascular imaging helps improve the accuracy and effectiveness of procedures, reducing the risk of complications and ensuring optimal outcomes for patients.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Overall, intravascular imaging provides valuable insights into the condition of blood vessels, aiding in the diagnosis and management of cardiovascular diseases. It enhances the precision of interventions, contributes to personalized treatment plans, and ultimately improves patient care.",
          },
        ],
      },
      {
        name: "Minimally Invasive Surgery",
        img: "../photo/mmm8.jpg",
        content:
          "Minimally invasive surgery (MIS) is an innovative approach to performing surgical...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Minimally invasive surgery (MIS) is an innovative approach to performing surgical procedures with the goal of reducing trauma to the body, minimizing pain, accelerating recovery, and achieving outcomes similar to those of traditional open surgery. This technique involves using smaller incisions and specialized tools, often guided by advanced imaging technology, to access and treat the targeted area. MIS is employed across various medical specialties, including general surgery, orthopedics, gynecology, urology, and cardiovascular surgery.",
          },
          {
            img: "",
            name: "Key features of minimally invasive surgery include ",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "1) Small Incisions ",
            content:
              "Instead of the large incisions required in traditional open surgery, MIS typically involves small incisions, often only a few millimeters in size. These smaller incisions lead to reduced scarring, less blood loss, and a lower risk of infection.",
          },
          {
            img: "",
            name: "",
            ques: "2) Specialized Instruments ",
            content:
              "Minimally invasive procedures utilize specialized instruments designed for precise manipulation and visualization within confined spaces. These instruments may include endoscopes (long, flexible tubes with cameras), laparoscopic tools, and robotic-assisted devices.",
          },
          {
            img: "",
            name: "",
            ques: "3) Lower Complication Rates ",
            content:
              "Due to reduced tissue manipulation and trauma, minimally invasive surgery is associated with lower rates of complications, such as infections and wound healing issues.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Common examples of minimally invasive surgical techniques include laparoscopic appendectomy, arthroscopic joint surgery, robotic-assisted prostatectomy, and endoscopic heart surgery. While not all conditions or patients are suitable for MIS, advancements in technology and surgical expertise continue to expand the range of procedures that can be performed using minimally invasive techniques. It's important for patients to discuss their options with a qualified medical professional to determine the most appropriate approach for their specific condition.",
          },
        ],
      },
      {
        name: "Balloon Valvoplasty",
        img: "../photo/mmm4.jpg",
        content:
          "Balloon valvoplasty is a specialized medical procedure aimed at treating narrowed heart...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Balloon valvoplasty is a specialized medical procedure aimed at treating narrowed heart valves, a condition known as valve stenosis. This minimally invasive intervention involves the use of a catheter equipped with an inflatable balloon that is guided to the site of the narrowed valve. Once in position, the balloon is gently inflated, effectively widening the valve opening by compressing the obstructing tissue or calcium deposits. This procedure helps improve blood flow through the valve, alleviating symptoms like shortness of breath, chest pain, and fatigue.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Balloon valvoplasty is commonly used to address stenosis in the mitral valve (mitral stenosis) and the aortic valve (aortic stenosis). It is particularly beneficial for individuals who are not suitable candidates for open-heart surgery or who wish to avoid more invasive approaches. While balloon valvoplasty can provide immediate relief, it may not always offer a permanent solution, and follow-up assessments are crucial to monitor valve function over time.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The decision to undergo balloon valvoplasty is made after a thorough evaluation by a cardiologist. Risks associated with the procedure, although relatively low, include vessel damage, bleeding, infection, or the possibility of the stenosis recurring. Despite these considerations, balloon valvoplasty offers a valuable option for enhancing heart valve function, improving quality of life, and minimizing recovery time compared to traditional surgical interventions.",
          },
        ],
      },
      // {
      //     name:"Device Closure",
      //     img:"../photo/mmm5.jpg",
      //     content:"Device closure is a specialized medical procedure used to treat certain congenital heart...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Device closure is a specialized medical procedure used to treat certain congenital heart defects without the need for open-heart surgery. This minimally invasive approach involves the placement of a device, often a small umbrella-like structure, to close an abnormal opening or hole in the heart. The procedure is typically performed in a catheterization lab under the guidance of advanced imaging techniques such as fluoroscopy.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"During device closure, a thin and flexible catheter is inserted into a blood vessel, usually in the groin, and carefully threaded up to the heart. Once the catheter reaches the defect site, the device is positioned and deployed.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Over time, the body's natural tissue grows around the device, effectively sealing the hole or closing the defect. This innovative technique is commonly used to address conditions like atrial septal defects (ASDs), patent foramen ovale (PFO), and certain ventricular septal defects (VSDs).",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Device closure offers several advantages over traditional surgical methods, including shorter recovery times, reduced risk of infection, and minimal scarring. Candidates for device closure are carefully evaluated by a team of healthcare professionals, often including pediatric cardiologists and interventional cardiologists, to determine the suitability of the procedure. While the majority of patients experience successful closure and improved heart function, follow-up visits are important to monitor the device's integration and overall cardiac health.",
      //         },
      //     ],
      // },
      // {
      //     name:"Tavi/tavr",
      //     img:"../photo/mmm6.jpg",
      //     content:"Transcatheter Aortic Valve Implantation (TAVI), also known as Transcatheter Aortic Valve...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Transcatheter Aortic Valve Implantation (TAVI), also known as Transcatheter Aortic Valve Replacement (TAVR), is an advanced medical procedure designed to treat aortic valve stenosis—a condition where the aortic valve becomes narrowed, restricting blood flow from the heart. TAVI/TAVR provides an alternative to traditional open-heart surgery, particularly for individuals who are considered high-risk or ineligible for surgery due to various health factors.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"During the TAVI/TAVR procedure, a catheter is guided through a blood vessel, typically in the groin or chest, and advanced to the heart. A replacement valve, often made from biological tissue or a combination of materials, is compressed onto a balloon at the tip of the catheter. Once in the appropriate position within the diseased aortic valve, the balloon is inflated, expanding the new valve and pushing aside the old, narrowed valve. The new valve then takes over the critical task of regulating blood flow from the heart to the rest of the body.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"TAVI/TAVR offers several advantages, including reduced trauma, shorter hospital stays, quicker recovery times, and less post-operative discomfort compared to traditional surgery. It's important to note that TAVI/TAVR is a specialized procedure, and patient eligibility is determined through comprehensive evaluations by a team of medical experts, which may include interventional cardiologists and cardiac surgeons. While TAVI/TAVR has revolutionized the treatment of aortic valve stenosis, ongoing medical supervision and follow-up care remain integral to ensure the longevity of the replacement valve and the patient's overall cardiovascular health.",
      //         },
      //     ],
      // },
      // {
      //     name:"Bypass Surgery",
      //     img:"../photo/mmm7.jpg",
      //     content:"Coronary artery bypass surgery, commonly referred to as bypass surgery or coronary...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Coronary artery bypass surgery, commonly referred to as bypass surgery or coronary artery bypass graft (CABG) surgery, is a surgical procedure used to treat severe coronary artery disease (CAD), a condition where the blood vessels supplying the heart muscle become narrowed or blocked by a buildup of plaque. Bypass surgery aims to restore proper blood flow to the heart muscle by creating new pathways for blood to bypass the blocked arteries.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"During bypass surgery, the patient is placed under general anesthesia, and the surgeon makes an incision in the chest to access the heart. The heart is temporarily stopped, and a heart-lung machine is used to maintain circulation while the surgeon performs the procedure. The surgeon harvests a healthy blood vessel, often from the patient's leg (saphenous vein) or chest (internal mammary artery), to use as a graft.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"The graft is then carefully attached to the aorta (the main artery leaving the heart) and to the coronary artery beyond the blockage, creating a detour for blood to flow around the blocked section. Depending on the severity and location of the blockages, multiple grafts may be performed during a single surgery. Once the grafts are in place and blood flow is restored, the heart is restarted, and the incisions are closed.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Bypass surgery can significantly improve blood flow to the heart muscle, relieve symptoms such as chest pain (angina), and reduce the risk of heart attack. It is typically recommended for individuals with severe CAD who have not responded well to other treatments, such as medication or lifestyle changes.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"While bypass surgery is a major surgical procedure, advances in medical technology and techniques have made it a relatively safe and effective option. Recovery involves a hospital stay, followed by a period of rehabilitation and lifestyle adjustments to promote heart health and prevent further blockages. Regular medical follow-ups are essential to monitor the patient's progress and ensure the long-term success of the procedure.",
      //         },
      //     ],
      // },

      // {
      //     name:"Heart Valve Surgery",
      //     img:"../photo/mmm9.jpg",
      //     content:"Heart valve surgery is a specialized medical procedure performed to repair or replace...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Heart valve surgery is a specialized medical procedure performed to repair or replace malfunctioning heart valves. The heart has four valves—tricuspid, mitral, pulmonary, and aortic—that regulate blood flow in and out of its chambers. When these valves become diseased or damaged due to conditions like valve stenosis (narrowing) or regurgitation (leakage), heart valve surgery may be necessary to restore proper function and maintain healthy blood circulation.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"There are two main approaches to heart valve surgery: repair and replacement. Valve repair involves techniques to restore the valve's original shape and function, often by removing excess tissue, suturing, or reinforcing the valve with artificial materials. Valve replacement, on the other hand, entails removing the damaged valve and replacing it with a prosthetic valve. Prosthetic valves can be either mechanical (made of durable materials) or biological (made from animal or human tissues).",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"During the surgery, the patient is typically placed under general anesthesia, and the chest is opened to access the heart. The heart may be temporarily stopped, and a heart-lung machine takes over the task of pumping blood while the surgeon works on the valves. Once the repair or replacement is completed, blood flow is restored, the heart is restarted, and the chest is closed. Minimally invasive techniques, such as robotic-assisted surgery or catheter-based approaches, may also be used for certain valve procedures, resulting in smaller incisions, less trauma, and faster recovery.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Heart valve surgery aims to alleviate symptoms like shortness of breath, fatigue, and chest pain, while also improving overall heart function and quality of life. The decision to undergo heart valve surgery is based on a thorough evaluation by a team of cardiovascular specialists who consider factors such as the type and severity of valve disease, the patient's overall health, and any previous treatments. While the surgery carries some risks, advancements in surgical techniques and prosthetic valves have led to improved outcomes and enhanced longevity for patients who undergo heart valve surgery.",
      //         },
      //     ],
      // },
    ],
  },
  {
    name: "Pediatrics",
    img: "",
    icon: "",
    content:
      "At Adhventha Hospital, we take pride in providing exceptional and compassionate care for our youngest patients. Our dedicated team of pediatric specialists is committed to delivering the highest quality medical treatment tailored specifically to the unique needs of children, from infancy through adolescence.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Haridharshan G.J",
        quali: "MBBS, MD",
        experi: "6",
      },
      {
        img: "../../photo/doctor.png",
        names: "Dr. Varsha Manohar",
        quali: "MBBS, MD, Fellow In Pediatrics Neurology",
        experi: "5",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "General Paediatrics",
        img: "../photo/qqq1.jpg",
        content:
          "General pediatrics is a medical specialty that focuses on the care and well-being of...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "General pediatrics is a medical specialty that focuses on the care and well-being of infants, children, and adolescents. Pediatricians are medical doctors who specialize in this field and are trained to diagnose, treat, and prevent a wide range of medical conditions that affect young patients. Their expertise covers the physical, emotional, and social development of children from birth through adolescence.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatricians play a crucial role in monitoring children's growth and development, providing vaccinations to prevent diseases, and addressing various health concerns. They address both acute illnesses, such as infections and injuries, as well as chronic conditions like asthma, diabetes, and developmental disorders. Additionally, pediatricians emphasize preventive care, educating parents and caregivers about healthy lifestyle choices, nutrition, and safety practices to promote the overall well-being of children. They often work in collaboration with other specialists to ensure comprehensive and specialized care when needed.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "In their practice, pediatricians create a child-friendly and supportive environment that takes into account the unique needs of young patients. Effective communication with children and their parents is a cornerstone of pediatric care, allowing pediatricians to establish trust, alleviate concerns, and provide guidance for maintaining optimal health as children grow and develop.",
          },
        ],
      },
      {
        name: "Infectious Diseases",
        img: "../photo/qqq2.jpg",
        content:
          "Infectious diseases encompass a broad category of illnesses caused by microorganisms...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Infectious diseases encompass a broad category of illnesses caused by microorganisms such as bacteria, viruses, fungi, and parasites. These diseases can spread from person to person, through contact with contaminated surfaces, through the air, or through vectors like insects. Infectious diseases can range from mild and self-limiting conditions to severe and life-threatening illnesses.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The field of infectious diseases involves the study, diagnosis, treatment, and prevention of these illnesses. Infectious disease specialists are medical doctors who specialize in this area and play a critical role in understanding the causes of infections, tracking outbreaks, and developing strategies to control their spread. They also work to educate the public and healthcare professionals about effective hygiene practices, vaccination, and appropriate use of antibiotics to combat drug resistance.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosing infectious diseases often involves clinical evaluation, laboratory tests, and advanced imaging techniques. Once diagnosed, treatment strategies may include antibiotics, antiviral medications, antifungal drugs, or other targeted therapies. Prevention of infectious diseases is a crucial aspect, often achieved through vaccination, public health campaigns, and promoting good hygiene practices. In recent years, the global community has witnessed the significance of infectious disease control with events like the COVID-19 pandemic, highlighting the vital role of infectious disease experts in safeguarding public health.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content: "",
          },
        ],
      },
      {
        name: "Lung Diseases - Asthma & Allergy",
        img: "../photo/qqq4.jpg",
        content: "Lung diseases encompass a diverse range of...",
        bodyes: [
          {
            img: "../photo/inhaler.png",
            name: "Asthma",
            ques: "",
            content:
              "Lung diseases encompass a diverse range of conditions that affect the respiratory system, including the lungs and airways. These diseases can result from infections, environmental factors, genetic predisposition, lifestyle choices, and other underlying health conditions. They can range from acute illnesses like pneumonia to chronic conditions such as asthma, chronic obstructive pulmonary disease (COPD), and lung cancer.",
          },
          {
            img: "../photo/allergies.png",
            name: "Allergy",
            ques: "",
            content:
              "Lung diseases encompass a diverse range of conditions that affect the respiratory system, including the lungs and airways. These diseases can result from infections, environmental factors, genetic predisposition, lifestyle choices, and other underlying health conditions. They can range from acute illnesses like pneumonia to chronic conditions such as asthma, chronic obstructive pulmonary disease (COPD), and lung cancer.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Lung diseases encompass a diverse range of conditions that affect the respiratory system, including the lungs and airways. These diseases can result from infections, environmental factors, genetic predisposition, lifestyle choices, and other underlying health conditions. They can range from acute illnesses like pneumonia to chronic conditions such as asthma, chronic obstructive pulmonary disease (COPD), and lung cancer.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pulmonologists play a crucial role in diagnosing and managing lung diseases. They are medical doctors who specialize in the treatment of respiratory conditions. Pulmonologists use a combination of clinical evaluation, imaging studies, pulmonary function tests, and procedures like bronchoscopy to accurately diagnose lung disorders. They then design personalized treatment plans that may include medications, pulmonary rehabilitation, lifestyle modifications, oxygen therapy, and in some cases, surgical interventions such as lung transplantation.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Bronchoscopy is a diagnostic and therapeutic procedure used to examine the airways and lungs. It involves the insertion of a thin, flexible tube with a camera (bronchoscope) through the mouth or nose, down the throat, and into the airways. This procedure allows healthcare professionals, including pulmonologists (lung specialists), to visualize the airways, collect samples of tissue and fluids, and perform various interventions. Bronchoscopy aids in diagnosing lung diseases, identifying abnormalities, and guiding treatments.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Lung diseases can have a significant impact on a person's quality of life, and early diagnosis and management are key to improving outcomes. The field of pulmonology continually evolves with advancements in diagnostic tools, treatment modalities, and research efforts aimed at better understanding and addressing various lung conditions.",
          },
        ],
      },
      {
        name: "Pediatrics Neurology",
        img: "../photo/qqq9.jpg",
        content:
          "Pediatric neurology is a specialized medical field dedicated to the diagnosis, treatment...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatric neurology is a specialized medical field dedicated to the diagnosis, treatment, and management of neurological disorders and conditions in children and adolescents. Neurological disorders involve abnormalities or dysfunctions of the nervous system, which includes the brain, spinal cord, nerves, and muscles. Pediatric neurologists are highly trained medical professionals who work to understand and address a wide range of conditions that affect the developing nervous system. These conditions encompass a diverse spectrum, such as epilepsy, developmental delays, cerebral palsy, neuromuscular disorders, migraine, neurogenetic disorders, and more. Pediatric neurologists use their expertise to provide accurate diagnoses, develop personalized treatment plans, and offer guidance and support to both the young patients and their families. The field requires a deep understanding of the intricate interactions within the nervous system during childhood, as well as the specific challenges and considerations that arise when treating neurological disorders in a pediatric population.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatric neurology involves a comprehensive approach to care, often collaborating with other medical specialists, therapists, and healthcare professionals to provide holistic treatment and support. This can include employing diagnostic tools like electroencephalography (EEG), brain imaging (MRI, CT scans), and genetic testing to identify the underlying causes of neurological issues. Treatment strategies may involve a combination of medication, physical therapy, occupational therapy, speech therapy, and dietary interventions, depending on the condition. The ultimate goal of pediatric neurology is to optimize neurological health, manage symptoms, improve quality of life, and promote the best possible neurodevelopmental outcomes for children and adolescents facing neurological challenges.",
          },
        ],
      },
      {
        name: "Pediatrics Sleep Medicine",
        img: "../photo/qqq10.jpg",
        content:
          "Pediatric sleep medicine is a specialized medical field focused on diagnosing...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatric sleep medicine is a specialized medical field focused on diagnosing, evaluating, and treating sleep-related disorders and disturbances in infants, children, and adolescents. Sleep plays a crucial role in a child's growth, development, and overall well-being, and disruptions in sleep can have a significant impact on physical, cognitive, and emotional health. Pediatric sleep medicine professionals, often working in multidisciplinary teams, address a wide range of conditions such as sleep apnea, insomnia, narcolepsy, restless leg syndrome, night terrors, and circadian rhythm disorders. They utilize various diagnostic tools, including sleep studies (polysomnography), medical histories, and assessments to tailor treatment plans that may involve behavioral interventions, lifestyle modifications, medication, and counseling to ensure optimal sleep quality and duration, leading to improved overall health and quality of life for young patients.",
          },
        ],
      },
      {
        name: "Sleep Medication For Adolescents",
        img: "../photo/qqq6.jpg",
        content: "The use of sleep medications for adolescents should...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The use of sleep medications for adolescents should be approached with caution and under the guidance of a qualified healthcare professional. Adolescents may experience sleep disturbances due to various factors, including stress, lifestyle habits, and underlying medical conditions. In some cases, when non-pharmacological interventions (such as improving sleep hygiene, reducing screen time before bed, and maintaining a consistent sleep schedule) have not been effective, healthcare providers might consider prescribing sleep medications.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Commonly used sleep medications for adolescents may include medications like melatonin, which is a hormone that helps regulate the sleep-wake cycle. Melatonin supplements can be useful for certain sleep disorders, but their use should be discussed with a doctor, as the appropriate dosage and timing are important.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Prescription medications like certain types of sedative-hypnotics might be considered in specific cases under medical supervision, but their use in adolescents is generally limited and carefully monitored due to potential side effects and concerns about developing dependencies.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "It's crucial for healthcare providers to conduct a thorough evaluation of the adolescent's sleep patterns, overall health, and any potential underlying issues before considering sleep medication. The goal should always be to address the root causes of sleep disturbances and promote healthy sleep habits whenever possible, with medication as a last resort and only when the benefits outweigh the potential risks.",
          },
        ],
      },
      {
        name: "Paediatric Psychiatry And Counselling",
        img: "../photo/qqq3.jpg",
        content: "Pediatric psychiatry and counseling focus on addressing...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatric psychiatry and counseling focus on addressing the mental, emotional, and behavioral well-being of children and adolescents. These fields are dedicated to understanding and treating a wide range of psychological issues that young individuals may face, helping them develop into mentally healthy and resilient adults.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pediatric psychiatrists are medical doctors who specialize in diagnosing and treating mental health disorders in children and adolescents. They have a deep understanding of the unique developmental stages, cognitive processes, and emotional challenges that young individuals experience. These specialists can provide comprehensive assessments, prescribe medications if necessary, and offer evidence-based therapies tailored to the specific needs of each child or teenager.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Counseling for children and adolescents is often provided by child psychologists, therapists, social workers, or counselors. This form of support involves talking through challenges and emotions in a safe and confidential environment. Counselors utilize various therapeutic techniques to help young individuals cope with stress, anxiety, depression, behavioral issues, trauma, and family-related problems. Play therapy, cognitive-behavioral therapy, family therapy, and art therapy are just a few examples of the approaches used to engage and help young patients express themselves and develop effective coping strategies.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Both pediatric psychiatrists and counselors play a vital role in early intervention and prevention of mental health issues, as addressing these challenges during childhood and adolescence can have a profound impact on long-term well-being. Their work often involves collaboration with parents, caregivers, schools, and other healthcare professionals to create holistic treatment plans that support the overall health and development of the child or adolescent.",
          },
        ],
      },
      {
        name: "Rheumatology (Joint disorders)",
        img: "../photo/qqq5.jpg",
        content:
          "Rheumatology is a medical specialty that focuses on the diagnosis and treatment of...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Rheumatology is a medical specialty that focuses on the diagnosis and treatment of disorders affecting the musculoskeletal system, particularly joints, muscles, bones, and connective tissues. Rheumatologists are medical doctors who specialize in this field and are experts in managing a wide range of conditions, collectively known as rheumatic diseases. These disorders can range from autoimmune diseases like rheumatoid arthritis and lupus to degenerative conditions like osteoarthritis.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Rheumatologists play a critical role in diagnosing these conditions, as many rheumatic diseases can be complex and challenging to identify. They use a combination of clinical evaluation, imaging studies (such as X-rays and MRI scans), and laboratory tests to determine the underlying cause of symptoms like joint pain, stiffness, swelling, and reduced mobility.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Once a diagnosis is made, rheumatologists work closely with patients to develop tailored treatment plans. These plans often incorporate a combination of medication, physical therapy, lifestyle modifications, and patient education. The goal is to manage pain, reduce inflammation, improve joint function, and enhance overall quality of life. In cases of autoimmune rheumatic diseases, such as rheumatoid arthritis, targeted therapies that modulate the immune response may also be employed.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Rheumatology is a rapidly evolving field, with ongoing research into the underlying mechanisms of various rheumatic conditions and the development of new treatment options. Advances in biologic therapies and disease-modifying drugs have revolutionized the management of many rheumatic diseases, providing patients with improved symptom control and long-term outcomes.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Given the diverse nature of rheumatic diseases and their potential impact on an individual's well-being, early diagnosis and intervention by a rheumatologist can significantly improve the prognosis and overall quality of life for those affected.",
          },
        ],
      },
    ],
  },
  {
    name: "Plastic Surgery",
    img: "",
    icon: "",
    content:
      "At Adhventha Hospital, we are proud to offer a comprehensive range of advanced plastic surgery procedures designed to enhance both your natural beauty and your confidence. Our skilled team of board-certified plastic surgeons is committed to delivering personalized, safe, and exceptional care to help you achieve your aesthetic goals.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Somashekar",
        quali: "MBBS, DNB, MCH",
        experi: "15",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Deepak M.R",
        quali: "MBBS, MS, MCH",
        experi: "15",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Suresh k",
        quali: "MBBS, MS, MCH, (AIMS)",
        experi: "5",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Breast augmentation",
        img: "../photo/vvv2.jpg",
        content:
          "Breast augmentation reduction are two common types of plastic surgery...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Breast augmentation reduction are two common types of plastic surgery procedures that focus on altering the size and shape of a woman’s breasts. Let’s take a closer look at each procedure",
          },
          {
            img: "",
            name: "",
            ques: "Breast Augmentation ",
            content:
              "Breast augmentation, also known as augmentation mammoplasty, is a surgical procedure aimed at increasing the size and fullness of the breasts. This is achieved by inserting breast implants under the breast tissue or chest muscles.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Types of breast implants: a. Saline implants: These implants are filled with sterile saltwater solution. b. Silicone implants: These implants consist of silicone gel, which closely resembles the feel of natural breast tissue. c. Gummy bear implants: These are a type of silicone implant that maintains its shape even if the shell is broken.",
          },
          {
            img: "",
            name: "",
            ques: "Breast Reduction ",
            content:
              "Breast reduction, or reduction mammoplasty, is a surgical procedure that aims to decrease the size and weight of overly large breasts. The surgery involves removing excess breast tissue, fat, and skin to achieve a more proportionate breast size.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The recovery period for both procedures can vary depending on the extent of the surgery. Patients usually experience some soreness, swelling, and bruising following the operation. They may need to wear a supportive surgical bra during the healing process. Most individuals can return to their daily activities gradually within a few weeks, although it may take several months for the final results to be fully apparent.",
          },
        ],
      },

      {
        name: "Diabetic foot",
        img: "../photo/vvv4.jpg",
        content:
          "Diabetic foot, a complication of diabetes mellitus, refers to a range of potentially...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diabetic foot, a complication of diabetes mellitus, refers to a range of potentially serious foot-related issues that can arise due to prolonged and poorly managed high blood sugar levels. It is a complex condition that encompasses various medical problems, including neuropathy (nerve damage), peripheral vascular disease (blood vessel damage), and impaired wound healing. Diabetic foot problems can lead to infections, ulcers, and in severe cases, even amputation. Proper management and preventative measures are crucial to minimize the risk and impact of diabetic foot complications.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Neuropathy, a common consequence of diabetes, often affects the sensory nerves in the feet, leading to reduced or absent sensation. This can result in an individual not noticing injuries or pressure points, which, when left untreated, can progress to ulcers. Peripheral vascular disease, another diabetic complication, diminishes blood flow to the extremities, impairing the body's ability to heal and fight infection. Combined with neuropathy, this can lead to the development of non-healing ulcers that are prone to infections.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diabetic foot ulcers are open sores that form due to a combination of neuropathy, vascular issues, and pressure points. These ulcers are particularly concerning because they can become infected easily, and infections can spread rapidly due to compromised circulation. In severe cases, uncontrolled infections can lead to tissue death (gangrene), which may necessitate amputation to prevent the infection from spreading further.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Preventative measures play a crucial role in managing diabetic foot complications. Maintaining good blood sugar control through proper diabetes management, including medication, diet, and exercise, is essential. Regular foot care, including daily inspection, moisturizing, and proper nail care, can help prevent issues from arising. Diabetic individuals should avoid walking barefoot and should wear well-fitting shoes to reduce the risk of injuries. Seeking prompt medical attention for any foot injuries or abnormalities is vital to prevent the progression of complications. A multidisciplinary approach involving endocrinologists, podiatrists, vascular specialists, and wound care experts is often necessary to effectively manage and minimize the impact of diabetic foot problems.",
          },
        ],
      },
      {
        name: "Lipoma",
        img: "../photo/vvv7.jpg",
        content:
          "A lipoma is a common benign (non-cancerous) growth of fat cells that forms...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A lipoma is a common benign (non-cancerous) growth of fat cells that forms underneath the skin. It typically feels soft to the touch and is usually painless. Lipomas can occur anywhere on the body, often appearing as small, movable lumps just beneath the skin's surface. They vary in size, and while they're usually harmless, they might be bothersome cosmetically or if they press on nerves.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "In most cases, lipomas do not require treatment unless they cause discomfort, pain, or functional issues. A doctor can diagnose a lipoma through physical examination and, if necessary, imaging tests. Surgical removal might be considered if the lipoma becomes bothersome, continues to grow, or causes symptoms. However, it's essential to consult a medical professional for proper evaluation and guidance regarding lipomas and their management.",
          },
        ],
      },
      {
        name: "Ganglion",
        img: "../photo/vvv8.jpg",
        content:
          "A ganglion cyst is a non-cancerous, fluid-filled lump that typically forms near joints or...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A ganglion cyst is a non-cancerous, fluid-filled lump that typically forms near joints or tendons, most commonly in the wrists or hands. These cysts are usually harmless and often painless, though they can cause discomfort or restrict movement if they press on nerves or tendons. Ganglion cysts are believed to form due to the leakage of fluid from a joint or tendon sheath into a sac-like structure.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis is usually based on physical examination, but sometimes imaging tests like ultrasound or MRI may be used to confirm the diagnosis and rule out other conditions. Treatment options include observation if the cyst is not causing significant discomfort, or aspiration (removing fluid with a needle) to reduce the size of the cyst. In some cases, surgical removal might be considered if the cyst is painful, large, or causing functional limitations. However, it's important to consult a medical professional for proper evaluation and guidance on managing ganglion cysts.",
          },
        ],
      },
      {
        name: "Skin grafting",
        img: "../photo/vvv9.jpg",
        content:
          "Skin grafting is a surgical procedure in which skin is transplanted from one area of the...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Skin grafting is a surgical procedure in which skin is transplanted from one area of the body (the donor site) to another area that has lost skin due to injury, burns, surgery, or other medical conditions. The primary goal of skin grafting is to promote wound healing, reduce pain, and improve the appearance and function of the recipient site.",
          },
          {
            img: "",
            name: "There are two main types of skin grafts ",
            ques: "1) Autograft",
            content:
              "In an autograft, skin is taken from the patient's own body, usually from a healthy area such as the thigh or buttocks. This is the most common type of skin graft and is less likely to be rejected by the body's immune system.",
          },
          {
            img: "",
            name: "",
            ques: "2) llograft or Homograft ",
            content:
              "In this type, skin is taken from a donor, often a cadaver. Allografts are temporary and are used to cover large areas of damaged skin while the body heals or to prepare the wound bed for a future autograft.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The procedure involves removing the skin from the donor site, preparing the recipient site (cleaning and sometimes debriding the wound), and placing the donor skin onto the recipient area. The graft is secured in place with stitches, staples, or adhesive dressings. Over time, new blood vessels from the recipient site grow into the graft, providing it with nourishment and facilitating healing.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Skin grafts are commonly used in cases of severe burns, chronic non-healing wounds, skin cancer excisions, and other traumatic injuries. The success of the graft depends on proper wound care and the body's ability to integrate the transplanted skin.",
          },
        ],
      },
      {
        name: "Bedsore",
        img: "../photo/vvv1.jpg",
        content:
          "Bedsores are localized injuries to the skin and underlying tissues that occur due to...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Bedsores are localized injuries to the skin and underlying tissues that occur due to prolonged pressure on the skin. They are particularly common in individuals who are bedridden, wheelchair-bound, or have limited mobility. Bedsores can range from mild to severe, and in severe cases, they can become deep and extensive wounds that may require surgical intervention.",
          },
          {
            img: "",
            name: "Here are some ways plastic surgery can be used in the treatment of severe bedsores ",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "Flap Surgery ",
            content:
              "In some cases, a plastic surgeon may use flap surgery to repair and close large, deep wounds caused by severe bedsores. Flap surgery involves moving healthy tissue, including skin, fat, and sometimes muscle, from one part of the body to the wound site to cover and heal the area. This helps to promote wound healing and reduce the risk of infection.",
          },
          {
            img: "",
            name: "",
            ques: "Skin Grafting ",
            content:
              "Skin grafting is another surgical option used to treat bedsores. During this procedure, healthy skin is taken from an unaffected area of the patient’s body (the donor site) and transplanted onto the wound site. The transplanted skin acts as a covering for the wound and promotes healing.",
          },
          {
            img: "",
            name: "",
            ques: "Tissue Expansion ",
            content:
              "In some cases, tissue expansion may be used. This involves placing a balloon-like device called a tissue expander under the skin near the wound site. Over time, the expander is gradually filled with saline solution to stretch the skin and create excess tissue. The expanded tissue can then be used to cover the wound and facilitate healing.",
          },
          {
            img: "",
            name: "",
            ques: "Debridement ",
            content:
              "Debridement is a procedure that may be performed before or in conjunction with plastic surgery. It involves the removal of dead or infected tissue from the wound site to promote healing and prevent the spread of infection.",
          },
        ],
      },
      {
        name: "Burn management",
        img: "../photo/vvv3.jpg",
        content:
          "When someone sustains burn injuries, especially severe ones, plastic surgeons are often...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "When someone sustains burn injuries, especially severe ones, plastic surgeons are often involved in various stages of treatment to optimize the healing process and improve the appearance and function of the affected area. Here are some aspects of burn management in which plastic surgery is commonly involved.",
          },
          {
            img: "",
            name: "",
            ques: "1) Acute Burn Care ",
            content:
              "In the immediate aftermath of a burn injury, the primary goal is to stabilize the patient and prevent further damage. Plastic surgeons may be part of the multidisciplinary team providing initial emergency care. They may perform surgical procedures to debride (remove dead tissue) the burned area and apply skin grafts or other dressings to promote wound healing.",
          },
          {
            img: "",
            name: "",
            ques: "2) Skin Grafts ",
            content:
              "Skin grafting is a common procedure used in burn management. It involves transplanting healthy skin from one area of the body (donor site) to the burned area (recipient site). This helps to close the wound, reduce pain, and prevent infection. Plastic surgeons have expertise in performing skin grafts and choosing the most suitable technique based on the severity and location of the burn.",
          },
          {
            img: "",
            name: "",
            ques: "3) Scar Management ",
            content:
              "After a burn injury, scar formation is inevitable. Plastic surgeons can employ various techniques to manage and improve the appearance of scars, such as scar revision surgery, laser treatments, and silicone gel sheets. Scar management is essential for improving the patient’s quality of life and functional outcomes.",
          },
          {
            img: "",
            name: "",
            ques: "4) Functional Restoration ",
            content:
              "Severe burns can lead to contractures, where the skin and underlying tissues tighten, restricting movement. Plastic surgeons can perform procedures to release contractures and restore function to affected body parts.",
          },
        ],
      },
      {
        name: "Ear deformity & Ear lobe repair",
        img: "../photo/vvv5.jpg",
        content:
          "Ear deformity correction refers to medical procedures and interventions aimed at...",
        bodyes: [
          {
            img: "",
            name: "Ear deformity correction ",
            ques: "",
            content:
              "Ear deformity correction refers to medical procedures and interventions aimed at correcting abnormalities or irregularities in the shape, size, or positioning of the ears. These deformities can be congenital (present at birth) or acquired due to trauma or other factors. The most common congenital ear deformity is known as microtia, where the outer ear is underdeveloped or absent. Other deformities might involve asymmetry, prominent ears, or conditions like lop ear where the top of the ear folds downward. Ear deformity correction procedures aim to improve aesthetics, enhance self-esteem, and in some cases, restore hearing function.",
          },
          {
            img: "",
            name: "Ear lobe repair ",
            ques: "",
            content:
              "Earlobe repair, also known as earlobe reconstruction or earlobe surgery, is a cosmetic procedure performed to correct deformities or damage to the earlobes. These issues can arise due to various factors, such as trauma, wearing heavy earrings over time, or congenital abnormalities. Earlobe repair aims to restore the natural appearance of the earlobes and, in some cases, allows individuals to resume wearing earrings.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The procedure is typically straightforward and can often be performed in a doctor's office under local anesthesia. During the surgery, the surgeon carefully trims away any excess or damaged tissue and then sutures the edges of the earlobe back together. This creates a clean and neat closure that heals over time. For cases where the earlobe has been stretched or elongated, the surgeon might perform a more complex repair by reshaping the tissue and providing additional support to prevent future stretching.",
          },
        ],
      },
      {
        name: "Vaginoplasty",
        img: "../photo/vvv10.jpg",
        content:
          "Earlobe repair, also known as earlobe reconstruction or earlobe surgery, is a cosmetic...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Vaginoplasty is a surgical procedure that involves creating or reconstructing a vagina. It is typically performed as part of gender confirmation surgery for transgender women or individuals assigned male at birth who wish to align their physical appearance with their gender identity. Vaginoplasty can also be performed for other medical reasons, such as correcting congenital abnormalities or addressing injuries.",
          },
          {
            img: "",
            name: "",
            ques: "There are different techniques for vaginoplasty, and the specific approach used can vary depending on factors like the patient's anatomy, goals, and surgeon's expertise. Some common techniques include",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "1) Penile Inversion Technique ",
            content:
              "This is one of the most common techniques for vaginoplasty. It involves using the penile skin and tissue to create the vaginal lining. The penile shaft is inverted and used to form the vaginal canal. The scrotal skin is often used to create the labia.",
          },
          {
            img: "",
            name: "",
            ques: "2) Colon (Sigmoid) Vaginoplasty ",
            content:
              " In this approach, a section of the colon (sigmoid colon) is used to create the vaginal canal. This technique is often chosen when there is a need for additional depth or when the penile skin is insufficient.",
          },
          {
            img: "",
            name: "",
            ques: "3) Peritoneal Pull-Through Technique ",
            content:
              "This technique involves using a portion of the peritoneum (the lining of the abdominal cavity) to create the vaginal lining. It can be used in combination with other techniques.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Vaginoplasty is a complex and delicate surgery that typically involves multiple stages. In addition to creating the vaginal canal and labia, other steps might include creating a clitoral hood and, if desired, shaping the labia majora and minora for a more natural appearance.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "It's important to note that vaginoplasty is a major surgical procedure that carries risks and potential complications, like any surgery. Patients considering vaginoplasty should thoroughly discuss their options, expectations, and potential risks with qualified medical professionals experienced in gender-affirming surgeries.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Keep in mind that my information is based on knowledge available up until September 2021, and there may have been developments or changes in surgical techniques, practices, or understanding since that time.",
          },
        ],
      },
    ],
  },
  {
    name: "General Surgery",
    img: "",
    icon: "",
    content:
      "Welcome to Adhventha Hospital, where we are dedicated to providing top-tier general surgery services to cater to a wide range of surgical needs. Our team of skilled and experienced general surgeons is committed to delivering exceptional care, utilizing the latest advancements in surgical techniques and technology.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Muni Reddy",
        quali: "MBBS, MS, MCH",
        experi: "13",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Shaik Ahmed",
        quali: "MBBS, MS",
        experi: "8",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Appendectomy",
        img: "../photo/zzz1.jpg",
        content:
          "An appendectomy is a surgical procedure performed to remove the appendix, a smal...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "An appendectomy is a surgical procedure performed to remove the appendix, a small, finger-shaped pouch attached to the large intestine. The most common reason for an appendectomy is appendicitis, which is the inflammation of the appendix often caused by a blockage or infection. If left untreated, an inflamed appendix can lead to a potentially life-threatening condition known as appendiceal rupture, which can cause infection and abscess formation.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During an appendectomy, the surgeon makes an incision in the lower right abdomen, known as the McBurney's point, or may use minimally invasive techniques such as laparoscopy. In laparoscopic appendectomy, small incisions are made through which a camera and specialized surgical instruments are inserted. This approach often results in less postoperative pain, faster recovery, and smaller scars.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The surgeon then carefully detaches and removes the inflamed appendix, taking care to prevent any spillage of its contents. After removal, the area is thoroughly cleaned, and if necessary, any abscesses or infected tissues are drained. The incisions are closed with sutures or staples, and the patient is monitored during the recovery period. Most patients can leave the hospital within a day or two after surgery, although full recovery may take a few weeks.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Appendectomy is a common and well-established procedure that effectively treats appendicitis and prevents potentially serious complications. Prompt medical attention and surgery are crucial for preventing the spread of infection and ensuring a swift recovery. It's important for individuals experiencing symptoms of appendicitis, such as abdominal pain, fever, and nausea, to seek immediate medical care to determine if an appendectomy is necessary.",
          },
        ],
      },
      {
        name: "Hernia Repair",
        img: "../photo/zzz2.jpg",
        content:
          "Hernia repair is a surgical procedure aimed at correcting a hernia, which occurs when...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hernia repair is a surgical procedure aimed at correcting a hernia, which occurs when an organ or tissue pushes through a weakened or damaged area of the abdominal wall. Hernias can develop in various parts of the body, but they most commonly occur in the abdomen. The procedure involves returning the protruding organ or tissue to its proper place and reinforcing the weakened abdominal wall to prevent recurrence.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During hernia repair, the surgeon typically makes an incision near the hernia site or may opt for a minimally invasive approach using laparoscopic techniques. In the traditional open surgery, the hernia is carefully pushed back into the abdominal cavity, and the weakened area is reinforced using sutures or a surgical mesh. In laparoscopic hernia repair, small incisions are made, and a camera and specialized instruments are used to guide the repair. A mesh may also be placed to provide additional support to the weakened area. This approach often results in less postoperative pain, faster recovery, and smaller scars.",
          },
        ],
      },
      {
        name: "Gallbladder Surgery (Cholecystectomy)",
        img: "../photo/zzz3.jpg",
        content: "Gallbladder surgery, also known as cholecystectomy...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gallbladder surgery, also known as cholecystectomy, is a surgical procedure performed to remove the gallbladder, a small organ located beneath the liver. The gallbladder plays a role in storing bile, a digestive fluid produced by the liver, and releasing it into the small intestine to aid in digestion. Gallbladder surgery is commonly conducted when the gallbladder becomes inflamed or when gallstones, which are hard deposits that form in the gallbladder, cause pain, infection, or other complications.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "There are two main types of gallbladder surgery: open cholecystectomy and laparoscopic cholecystectomy. In open cholecystectomy, a single, larger incision is made in the abdomen to access and remove the gallbladder. In laparoscopic cholecystectomy, several small incisions are made, and a tiny camera and specialized instruments are used to guide the surgeon in removing the gallbladder. This minimally invasive approach often leads to quicker recovery, less pain, and smaller scars.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gallbladder surgery provides relief from the discomfort and health risks associated with gallbladder problems. After the surgery, patients may experience changes in digestion, such as tolerance to certain foods, but these typically improve over time. Most patients can resume normal activities within a few weeks, and the removal of the gallbladder does not significantly impact the body's ability to digest food. Gallbladder surgery is considered safe and effective, with the majority of patients experiencing improved quality of life and a reduction in gallbladder-related symptoms.",
          },
        ],
      },
      {
        name: "Colon Surgery",
        img: "../photo/zzz4.jpg",
        content:
          "Colon surgery, also known as colorectal surgery, involves surgical procedures...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Colon surgery, also known as colorectal surgery, involves surgical procedures performed on the colon (large intestine) and rectum. These surgeries may be necessary to treat various conditions, including colorectal cancer, diverticulitis, inflammatory bowel disease (such as Crohn's disease and ulcerative colitis), and certain cases of bowel obstruction or perforation. The primary goal of colon surgery is to remove or repair damaged or diseased portions of the colon and rectum, restore proper bowel function, and alleviate symptoms or potential complications.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The specific type of colon surgery performed depends on the underlying condition and its severity. In cases of colorectal cancer, a portion of the colon containing the tumor may be removed in a procedure known as a colectomy. The surgeon will also assess nearby lymph nodes to determine if the cancer has spread. For diverticulitis or inflammatory bowel disease, surgery may involve removing the affected segment of the colon to relieve inflammation and prevent further complications. In some instances, a colostomy or ileostomy might be created, where a portion of the intestine is brought through the abdominal wall to allow waste to exit the body into an external bag. This can be temporary or permanent, depending on the situation. Minimally invasive techniques, such as laparoscopic surgery, are often used to reduce the size of incisions and promote faster recovery.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Colon surgery is a complex procedure that requires careful preoperative evaluation, skilled surgical techniques, and postoperative management. Risks associated with the surgery include infection, bleeding, anesthesia-related complications, and the potential for bowel dysfunction. Recovery time varies depending on the extent of the surgery and the patient's overall health. Following surgery, patients typically require a period of rest, pain management, and gradual reintroduction of solid foods. A comprehensive approach involving collaboration between the surgeon, anesthesiologist, and medical team is essential to ensure the best possible outcomes for individuals undergoing colon surgery.",
          },
        ],
      },
      {
        name: "Thyroidectomy",
        img: "../photo/zzz5.jpg",
        content:
          "Thyroidectomy is a surgical procedure involving the removal of all or part of the thyroid...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Thyroidectomy is a surgical procedure involving the removal of all or part of the thyroid gland, a butterfly-shaped gland located in the front of the neck. This procedure is often performed to treat various thyroid conditions, including thyroid cancer, thyroid nodules, hyperthyroidism (overactive thyroid), and goiter (enlarged thyroid).",
          },
          {
            img: "",
            name: "There are different types of thyroidectomy procedures, depending on the extent of thyroid gland removal",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "Total Thyroidectomy",
            content:
              "In this procedure, the entire thyroid gland is removed. It is commonly recommended for thyroid cancer or cases of severe hyperthyroidism where medications or other treatments have been ineffective.",
          },
          {
            img: "",
            name: "",
            ques: "Subtotal or Partial Thyroidectomy",
            content:
              "In this procedure, only a portion of the thyroid gland is removed. It may be chosen for cases of smaller thyroid nodules, less aggressive thyroid cancers, or when preserving thyroid function is a consideration.",
          },
          {
            img: "",
            name: "",
            ques: "Hemithyroidectomy",
            content:
              "This involves removing one lobe of the thyroid gland and is often performed when a thyroid nodule is suspicious for cancer or causing compression symptoms.",
          },
        ],
      },
      {
        name: "Breast Surgery",
        img: "../photo/zzz6.jpg",
        content:
          "Breast surgery refers to a range of surgical procedures performed on the breasts for...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Breast surgery refers to a range of surgical procedures performed on the breasts for various reasons, including medical, cosmetic, or reconstructive purposes. The most common types of breast surgery include breast augmentation, breast reduction, breast lift (mastopexy), and breast reconstruction.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Breast augmentation, also known as augmentation mammoplasty, involves the placement of implants to enhance the size and shape of the breasts. This procedure is often chosen by individuals seeking to increase breast volume or achieve a more balanced body proportion. Breast reduction, on the other hand, is performed to reduce the size and weight of overly large breasts, alleviating physical discomfort and improving overall body contour. It can also help alleviate back, shoulder, and neck pain caused by the weight of heavy breasts.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Breast lift (mastopexy) is aimed at addressing sagging breasts by removing excess skin and reshaping the breast tissue to achieve a more youthful and lifted appearance. This procedure is often chosen by individuals whose breasts have lost firmness due to factors such as pregnancy, breastfeeding, or aging. Breast reconstruction is a crucial surgical option for individuals who have undergone mastectomy (breast removal) as part of cancer treatment. Reconstruction procedures involve recreating a natural-looking breast using implants, tissue from other parts of the body, or a combination of both. These surgeries aim to restore a sense of normalcy and self-confidence for individuals who have undergone breast removal.",
          },
        ],
      },
      {
        name: "Hemorrhoidectomy",
        img: "../photo/zzz7.jpg",
        content:
          "Hemorrhoidectomy is a surgical procedure aimed at the removal of hemorrhoids, which...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hemorrhoidectomy is a surgical procedure aimed at the removal of hemorrhoids, which are swollen and inflamed blood vessels in the anal and rectal area. Hemorrhoids can cause pain, discomfort, itching, and bleeding, and when conservative treatments such as dietary changes, fiber supplements, and topical creams fail to provide relief, a hemorrhoidectomy may be recommended. This surgery is typically performed under general or regional anesthesia and involves excising the hemorrhoid tissue.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During a hemorrhoidectomy, the surgeon makes incisions around the hemorrhoidal tissue and carefully removes it. The procedure can be done using various techniques, including traditional excision, stapled hemorrhoidopexy, or laser surgery. Traditional excision involves cutting out the hemorrhoidal tissue and suturing the incision site. Stapled hemorrhoidopexy, also known as the PPH procedure (Procedure for Prolapse and Hemorrhoids), involves stapling the hemorrhoidal tissue to its normal position within the anal canal. Laser surgery uses a focused laser beam to vaporize or coagulate the hemorrhoidal tissue.",
          },
        ],
      },
      {
        name: "Soft Tissue Surgery",
        img: "../photo/zzz8.jpg",
        content:
          "Soft tissue surgery is a branch of surgical practice that involves procedures on tissues...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Soft tissue surgery is a branch of surgical practice that involves procedures on tissues other than bones and joints, encompassing organs, muscles, blood vessels, skin, and other connective tissues. This type of surgery can be performed for a variety of medical, cosmetic, and functional reasons. Soft tissue surgery is often used to treat conditions such as tumors, infections, trauma, congenital anomalies, and hernias.",
          },
          {
            img: "",
            name: "Common examples of soft tissue surgery include ",
            ques: "Tumor Removal",
            content:
              "Soft tissue tumors, both benign and malignant, can be surgically removed. This may involve excising a skin lesion, removing a lipoma (benign fatty tumor), or performing complex procedures to remove larger tumors in various parts of the body.",
          },
          {
            img: "",
            name: "",
            ques: "Hernia Repair",
            content:
              "Hernias occur when an organ or tissue pushes through a weak spot or opening in the surrounding muscle or connective tissue. Soft tissue surgery is used to repair hernias, which commonly occur in the abdomen or groin.",
          },
          {
            img: "",
            name: "",
            ques: "Skin Grafts",
            content:
              "Skin grafting is a soft tissue surgery technique used to transplant healthy skin from one area of the body to another, often used to treat burns, chronic wounds, or surgical defects.",
          },
          {
            img: "",
            name: "",
            ques: "Liposuction",
            content:
              "A cosmetic soft tissue procedure, liposuction involves the removal of excess fat deposits from underneath the skin using suction.",
          },
        ],
      },
      {
        name: "Excision of Skin Lesions",
        img: "../photo/zzz9.jpg",
        content:
          "Excision of skin lesions is a common surgical procedure performed to remove abnormal...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Excision of skin lesions is a common surgical procedure performed to remove abnormal or potentially harmful growths, lumps, or lesions from the skin. Skin lesions can include moles, cysts, lipomas, warts, skin tags, and certain types of skin cancers. The primary goal of this procedure is to accurately and completely remove the lesion while minimizing scarring and ensuring proper healing.",
          },
          {
            img: "",
            name: "The excision process typically involves the following steps ",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "Preoperative Assessment",
            content:
              "Before the procedure, the surgeon evaluates the lesion to determine its size, location, and characteristics. In some cases, a biopsy might be performed prior to excision to confirm the nature of the lesion. The patient's medical history and any potential risks are also taken into consideration.",
          },
          {
            img: "",
            name: "",
            ques: "Surgical Removal ",
            content:
              "During the procedure, the area around the lesion is cleaned and numbed with a local anesthetic to ensure the patient's comfort. The surgeon then carefully cuts around and beneath the lesion using a scalpel or other surgical instrument, ensuring that the entire lesion, including any underlying tissue, is removed. The excised tissue is usually sent to a laboratory for further examination to rule out any malignancy.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Recovery from excision of skin lesions typically involves keeping the wound clean and dry, following any postoperative instructions provided by the surgeon, and attending follow-up appointments to monitor healing. Mild discomfort, swelling, and bruising may occur after the procedure, but these usually subside over time. It's important for patients to promptly report any signs of infection, excessive bleeding, or unusual changes at the surgical site. Excision of skin lesions is generally a safe and effective procedure, providing both diagnostic and therapeutic benefits by removing potentially harmful or bothersome growths while promoting the overall health of the skin.",
          },
        ],
      },
    ],
  },
  {
    name: "Obstetrics & Gynaecology",
    img: "",
    icon: "",
    content:
      "We are delighted to offer a comprehensive range of Obstetrics & Gynaecology services, tailored to support women's health at every stage of life.",
    doctor: [
      {
        img: "../../photo/doctor.png",
        names: "Dr. Sapna G",
        quali: "MBBS, DGO, DNB, FMAS",
        experi: "22",
      },
      {
        img: "../../photo/doctor.png",
        names: "Dr. Pooja R",
        quali: "MBBS, MS",
        experi: "3",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Painless HSG Test",
        img: "../photo/xxx8.jpg",
        content:
          "A painless Hysterosalpingogram (HSG) test is a specialized medical procedure designed...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A painless Hysterosalpingogram (HSG) test is a specialized medical procedure designed to assess the condition of a woman's reproductive organs, particularly the uterus and fallopian tubes. It is commonly recommended for individuals experiencing fertility challenges or suspected reproductive issues. During the procedure, a contrast agent is gently introduced into the uterus through the cervix. This contrast material is then tracked using X-ray imaging as it flows through the uterus and into the fallopian tubes. The resulting images provide valuable insights into the shape, size, and overall health of these reproductive structures, helping healthcare providers identify potential abnormalities, blockages, or other factors that could affect fertility.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "To ensure the procedure is as painless as possible, healthcare professionals often take several steps. First, patients might be advised to take a mild pain reliever, like ibuprofen, prior to the procedure to preemptively manage discomfort. Additionally, a local anesthetic can be applied to numb the cervix, helping to minimize any sensations during the process. Relaxation techniques, such as deep breathing or mindfulness, can also be utilized to reduce anxiety and promote a more comfortable experience.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Despite the potential for mild discomfort, many women find the HSG test to be well-tolerated and relatively brief. By implementing these strategies and fostering open communication with healthcare providers, individuals can undergo an HSG test with minimal pain, gaining valuable insights into their reproductive health without enduring undue discomfort.",
          },
        ],
      },
      {
        name: "Vaginal Scan",
        img: "../photo/xxx7.jpg",
        content:
          "A vaginal scan, also known as a transvaginal ultrasound or endovaginal ultrasound...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A vaginal scan, also known as a transvaginal ultrasound or endovaginal ultrasound, is a medical imaging procedure used to visualize the internal structures of the pelvis, particularly the uterus, ovaries, and surrounding tissues. It is commonly employed for diagnostic purposes in gynecology and obstetrics. During a vaginal scan, a small, wand-like device called a transducer is gently inserted into the vagina. The transducer emits sound waves that bounce off the internal organs, creating real-time images on a monitor. This provides detailed information about the size, shape, texture, and position of the reproductive organs.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The procedure is typically performed in a private, comfortable examination room. Before the scan, the patient is usually asked to empty their bladder to allow for better visualization of the pelvic structures. The transducer is covered with a disposable sheath and a lubricating gel to facilitate insertion and reduce any potential discomfort. The transducer is then carefully inserted into the vagina, allowing the healthcare provider to obtain close-up images of the pelvic organs. The procedure is generally well-tolerated and only takes a few minutes to complete.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Vaginal scans offer several advantages. They provide high-resolution images, allowing for better assessment of subtle changes or abnormalities in the pelvic region. Because the transducer is placed close to the organs of interest, this type of ultrasound is particularly useful for evaluating early pregnancy, monitoring follicle development during fertility treatments, and diagnosing conditions such as ovarian cysts, uterine fibroids, or endometrial abnormalities. While some individuals might experience slight discomfort during the insertion of the transducer, the procedure is usually quick and less invasive compared to other imaging methods. Communication with the healthcare provider is key; patients should express any concerns or discomfort they might experience during the procedure.",
          },
        ],
      },
      {
        name: "Aparoscopic Surgery For All Gynaecological Diseases",
        img: "../photo/xxx1.jpg",
        content:
          "Laparoscopic surgery, also known as minimally invasive surgery...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laparoscopic surgery, also known as minimally invasive surgery or keyhole surgery, has revolutionized the field of gynecology by offering a less invasive approach to diagnosing and treating various gynecological diseases. This advanced surgical technique involves making small incisions and using specialized instruments and a camera to visualize and operate inside the abdominal and pelvic cavities. It offers several advantages over traditional open surgery, including reduced pain, shorter hospital stays, quicker recovery, and smaller scars.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laparoscopic surgery is utilized for a wide range of gynecological conditions, including but not limited to",
          },
          {
            img: "",
            name: "",
            ques: "1) Endometriosis ",
            content:
              "Laparoscopy is often the gold standard for diagnosing and treating endometriosis, a condition in which tissue similar to the lining of the uterus grows outside the uterus. Surgeons can remove these abnormal growths and scar tissue, alleviating pain and improving fertility.",
          },
          {
            img: "",
            name: "",
            ques: "2) Ovarian Cysts ",
            content:
              "Large or persistent ovarian cysts can be removed laparoscopically, preserving the ovary and minimizing the impact on fertility.",
          },
          {
            img: "",
            name: "",
            ques: "3) Fibroids ",
            content:
              "Laparoscopic myomectomy involves the removal of uterine fibroids while preserving the uterus, which can be particularly important for women who desire future pregnancies.",
          },
          {
            img: "",
            name: "",
            ques: "4) Ectopic Pregnancy ",
            content:
              "An ectopic pregnancy, where the embryo implants outside the uterus (typically in the fallopian tube), requires surgical intervention to remove the pregnancy and repair any damage. Laparoscopy is often the preferred method.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laparoscopic surgery requires specialized training and equipment, and not all gynecological conditions are suitable for this approach. Patients' individual medical history, the severity of the condition, and the surgeon's expertise will determine the appropriate treatment approach. As technology and surgical techniques continue to advance, laparoscopic surgery remains a valuable tool in the arsenal of treatments for gynecological diseases, offering improved outcomes and enhanced patient experiences.",
          },
        ],
      },
      {
        name: "Hysteroscopic Surgery",
        img: "../photo/xxx2.jpg",
        content:
          "Hysteroscopic surgery is a minimally invasive procedure used to diagnose and treat...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hysteroscopic surgery is a minimally invasive procedure used to diagnose and treat various gynecological conditions by examining the inside of the uterus using a hysteroscope, a thin, lighted tube with a camera attached to it. This technique allows gynecologists to visualize the uterine cavity and perform surgical interventions without the need for traditional open surgery. Hysteroscopy can be both diagnostic, providing valuable insights into uterine abnormalities, and therapeutic, allowing for the removal of polyps, fibroids, or other tissue growths that might cause symptoms.",
          },
          {
            img: "",
            name: "1) Hysteroscopic Myomectomy ",
            ques: "",
            content:
              "Hysteroscopic myomectomy is a minimally invasive surgical procedure used to remove uterine fibroids that are located within the uterine cavity. Uterine fibroids are noncancerous growths that can develop in and around the uterus, causing symptoms such as heavy menstrual bleeding, pelvic pain, and pressure. Hysteroscopic myomectomy is a specialized technique designed to address fibroids that are causing significant symptoms and are accessible through the cervix.",
          },
          {
            img: "",
            name: "2) Adhesiolysis ",
            ques: "",
            content:
              "Adhesiolysis is a surgical procedure performed to remove or release adhesions, which are bands of scar tissue that can form between tissues and organs within the body. Adhesions can develop as a result of surgery, inflammation, infection, or trauma. They can cause organs or tissues that are normally separate to become stuck together, leading to pain, reduced mobility, and other complications.",
          },
          {
            img: "",
            name: "3) Septum Resection ",
            ques: "",
            content:
              "Septum resection, also known as hysteroscopic septoplasty or uterine septum removal, is a surgical procedure performed to correct a uterine septum. A uterine septum is a congenital condition where a wedge-shaped piece of tissue divides the uterine cavity partially or completely, creating a partition within the uterus. This condition can lead to fertility problems, recurrent miscarriages, and other reproductive health issues.",
          },
          {
            img: "",
            name: "4) Tubal Cannulation ",
            ques: "",
            content:
              "Tubal cannulation is a medical procedure used to open blocked fallopian tubes, which are the tubes that connect the ovaries to the uterus. The procedure is typically performed to address tubal blockages that are causing infertility or preventing the passage of eggs and sperm, thus hindering natural conception.",
          },
          {
            img: "",
            name: "5) Endometrial Resection ",
            ques: "",
            content:
              "Endometrial resection is a surgical procedure performed to treat heavy or abnormal uterine bleeding by removing or thinning the lining of the uterus, known as the endometrium. This procedure is often used as a treatment option when medical therapies have been unsuccessful in managing the excessive bleeding. It can provide significant relief to women who experience heavy menstrual periods (menorrhagia) or other uterine bleeding disorders.",
          },
        ],
      },
      {
        name: "Thermachoice Uterine Ablation Therapy",
        img: "../photo/xxx3.jpg",
        content: "ThermaChoice® uterine ablation therapy is a medical...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "Thermachoice uterine ablation therapy (Non operative treatment for bleeding disorders of uterus)",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "ThermaChoice uterine ablation therapy is a medical procedure used to treat excessive or abnormal uterine bleeding, a condition known as menorrhagia. This minimally invasive technique aims to reduce or eliminate heavy menstrual bleeding without the need for major surgery or hysterectomy (removal of the uterus). It is typically recommended for women who have completed their childbearing and desire a non-surgical option to manage their menstrual symptoms.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The procedure involves the use of a specialized balloon device that is inserted into the uterus through the cervix. The balloon is then filled with a heated fluid, typically a sterile saline solution. The heat from the fluid is carefully controlled and evenly distributed throughout the uterine lining. This controlled thermal energy causes the endometrial tissue (the lining of the uterus) to be destroyed or ablated, resulting in reduced menstrual bleeding.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "ThermaChoice® uterine ablation therapy is performed on an outpatient basis and is usually completed within a short period of time, often less than 30 minutes. Patients are usually given local anesthesia or mild sedation to minimize discomfort during the procedure. Recovery time is generally short, and most women can return to their normal activities within a few days.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "It's important to note that while this procedure can be highly effective in reducing menstrual bleeding and improving quality of life for many women, it might not be suitable for everyone. The success of the procedure can vary based on factors such as the underlying cause of the heavy bleeding, the size and shape of the uterus, and individual patient characteristics.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Before considering ThermaChoice® uterine ablation therapy, women should undergo a thorough evaluation by a gynecologist to ensure that this treatment is appropriate for their specific situation. Other treatment options and potential risks should also be discussed to make an informed decision about managing their menorrhagia effectively.",
          },
        ],
      },
      {
        name: "Gynaecological Oncology",
        img: "../photo/xxx4.jpg",
        content:
          "Gynecological oncology is a specialized field of medicine focused on the diagnosis...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gynecological oncology is a specialized field of medicine focused on the diagnosis, treatment, and management of cancers that affect the female reproductive system. This includes cancers of the uterus, ovaries, cervix, vagina, and vulva. Gynecological oncologists are medical doctors who specialize in this area and are trained to provide comprehensive care for women with gynecological cancers.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gynecological cancers can present unique challenges due to their location and the complex nature of the female reproductive organs. Gynecological oncologists play a crucial role in early detection, accurate diagnosis, and developing personalized treatment plans that consider both the physical and emotional well-being of patients. They collaborate closely with other specialists such as radiation oncologists, medical oncologists, pathologists, and radiologists to provide multidisciplinary care.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The field of gynecological oncology covers a range of treatments, including surgery, chemotherapy, radiation therapy, targeted therapies, and immunotherapy. The specific treatment approach depends on factors such as the type and stage of the cancer, the patient's overall health, and their treatment preferences. Surgical interventions are often a key component of treatment and may involve removing tumors, affected organs, and nearby lymph nodes.",
          },
        ],
      },
    ],
  },
  {
    name: "Anesthesiology & Critical Care",
    img: "",
    icon: "",
    content:
      "Welcome to Adhventha Hospital, where we take pride in offering exceptional Anaesthesiology & Critical Care services to ensure your safety, comfort, and well-being during surgical procedures and in critical medical situations.",
    doctor: [
      {
        img: "../../photo/doctor.png",
        names: "Dr. Tulsi Nagesh",
        quali: "MBBS, DA",
        experi: "35",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Krishna",
        quali: "MBBS, MD",
        experi: "12",
      },
      {
        img: "../../photo/doctoro.png",
        names: "Dr. lokesh",
        quali: "MBBS, MD",
        experi: "15",
      },
      {
        img: "../../photo/doctor.png",
        names: "Dr. Bhavya",
        quali: "MBBS, MD",
        experi: "10",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "General Anesthesia",
        img: "../photo/ddd1.jpg",
        content:
          "General anesthesia is a medical technique used to induce a state of controlled...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "General anesthesia is a medical technique used to induce a state of controlled unconsciousness and loss of sensation in a patient, allowing them to undergo surgical procedures without experiencing pain or discomfort. It involves a combination of medications and specialized monitoring to create a temporary state of unconsciousness and immobility, enabling surgeons to perform complex and invasive procedures safely and effectively.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "General anesthesia is commonly used for major surgeries that would otherwise be too painful or traumatic to endure while awake. It allows surgical teams to work in a controlled environment without patient movement or discomfort, reducing the risk of complications and ensuring the patient's overall comfort and well-being. After the surgery is completed, the administration of anesthesia is carefully discontinued, and the patient gradually awakens as the effects of the medications wear off. The patient is then closely monitored during the recovery period to ensure a smooth transition back to consciousness and to address any potential side effects.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During general anesthesia, an anesthesiologist administers a combination of intravenous medications and inhaled gases to induce unconsciousness and ensure that the patient remains completely unaware and unresponsive to pain. The anesthesiologist carefully monitors the patient's vital signs, including heart rate, blood pressure, oxygen levels, and breathing, throughout the procedure to ensure their safety and well-being. The medications used in general anesthesia have the dual purpose of inducing unconsciousness and maintaining a stable physiological state.",
          },
        ],
      },
      {
        name: "Regional Anesthesia",
        img: "../photo/ddd2.jpg",
        content:
          "Regional anesthesia is a specialized medical technique used to numb a specific region...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Regional anesthesia is a specialized medical technique used to numb a specific region of the body, allowing patients to undergo surgical procedures or medical interventions without feeling pain in that area. Unlike general anesthesia, which induces a state of unconsciousness, regional anesthesia targets a localized area while the patient remains awake and aware. This approach offers both effective pain relief and a reduced risk of some of the side effects associated with general anesthesia.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During regional anesthesia, an anesthesiologist injects a local anesthetic medication near specific nerves that supply sensation to the targeted area. This blocks nerve signals and temporarily prevents pain signals from reaching the brain. Common types of regional anesthesia include epidurals, which are commonly used for childbirth, and nerve blocks, which can be administered for various procedures such as orthopedic surgeries. Regional anesthesia allows patients to remain conscious and communicate with the surgical team while being free from pain in the operative region.",
          },
        ],
      },
      {
        name: "Local Anesthesia",
        img: "../photo/ddd3.jpg",
        content:
          "Local anesthesia is a specialized medical technique used to numb a specific area of the...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Local anesthesia is a specialized medical technique used to numb a specific area of the body to prevent pain during minor surgical or medical procedures. Unlike general anesthesia, which induces unconsciousness, or regional anesthesia, which numbs a larger region, local anesthesia focuses on a small, localized area while the patient remains awake and aware. This approach is commonly used for procedures that do not require the patient to be unconscious or when sedation might not be necessary.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "During local anesthesia, a local anesthetic medication is injected directly into the tissues surrounding the targeted area. This blocks nerve signals and temporarily prevents the sensation of pain from reaching the brain. The patient can often feel pressure or movement during the procedure but does not experience pain. Local anesthesia is frequently used for procedures such as minor skin surgeries, dental work, and small joint aspirations.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Local anesthesia offers several advantages, including a quicker recovery time compared to general anesthesia, a reduced risk of complications associated with being unconscious, and the ability for patients to remain alert and communicate with the medical team during the procedure. It is particularly beneficial for patients who might have underlying health conditions that make general anesthesia risky or those who prefer to avoid the grogginess or potential side effects of sedation.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "However, it's important to note that local anesthesia has its limitations. It may not be suitable for larger or more invasive procedures, and its effectiveness can vary based on individual pain tolerance and the specific procedure being performed. The administration of local anesthesia requires skill and precision to ensure proper placement and adequate pain relief. The choice of anesthesia method, whether local, regional, or general, is determined by the nature of the procedure, the patient's medical history, and their comfort level.",
          },
        ],
      },
      {
        name: "Pain Management",
        img: "../photo/ddd7.jpg",
        content:
          "Pain management is a comprehensive medical approach to alleviate, control, and...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pain management is a comprehensive medical approach to alleviate, control, and improve the quality of life for individuals experiencing acute or chronic pain. Pain can arise from various sources, including injuries, medical conditions, surgeries, or other underlying factors. The goal of pain management is not just to eliminate pain but also to enhance physical functioning, emotional well-being, and overall quality of life.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pain management techniques vary depending on the type, severity, and underlying cause of the pain. They can include both pharmacological and non-pharmacological approaches. Pharmacological methods involve the use of medications, such as over-the-counter pain relievers, nonsteroidal anti-inflammatory drugs (NSAIDs), opioids (for severe pain), and adjuvant medications that target specific aspects of pain. However, due to concerns about potential side effects and the risk of dependence, opioids are often prescribed cautiously and under close supervision.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Non-pharmacological pain management approaches encompass a range of therapies, including physical therapy, acupuncture, chiropractic care, massage, cognitive-behavioral therapy (CBT), relaxation techniques, meditation, and more. These methods aim to address pain by promoting healing, reducing muscle tension, improving mobility, and enhancing the body's natural pain-relieving mechanisms.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A personalized pain management plan is typically developed in collaboration with healthcare providers, considering the individual's medical history, pain intensity, and underlying conditions. The approach may involve a combination of treatments to provide the most effective relief while minimizing potential risks. Regular reassessment and adjustment of the pain management plan are important to ensure ongoing effectiveness and address any changes in the patient's condition.",
          },
        ],
      },
      {
        name: "Mechanical Ventilation",
        img: "../photo/ddd4.jpg",
        content:
          "Mechanical ventilation is a crucial medical intervention used to assist or replace a...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Mechanical ventilation is a crucial medical intervention used to assist or replace a patient's breathing when they are unable to breathe adequately on their own. This life-saving technique involves the use of a mechanical ventilator, a sophisticated medical device that delivers a controlled flow of oxygen-enriched air into the patient's lungs and helps remove carbon dioxide, a waste gas produced during respiration. Mechanical ventilation is employed in a variety of clinical settings, including intensive care units (ICUs), operating rooms, and emergency departments, to support patients with respiratory failure, severe lung conditions, or those undergoing major surgeries.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The process of mechanical ventilation begins with the insertion of a breathing tube, known as an endotracheal tube, into the patient's airway. This tube is connected to the mechanical ventilator, which delivers air and oxygen at carefully controlled pressures and volumes. The ventilator can be programmed to adjust the respiratory rate, tidal volume (the amount of air delivered with each breath), and other parameters to match the patient's specific needs. Mechanical ventilation ensures that the body receives enough oxygen and allows the lungs to rest, which can be especially crucial in cases of acute respiratory distress or when the patient's lung function is compromised. As the underlying condition improves, the patient may be gradually weaned off the ventilator and transitioned back to spontaneous breathing.",
          },
        ],
      },
      {
        name: "Hemodynamic Monitoring",
        img: "../photo/ddd5.jpg",
        content:
          "Hemodynamic monitoring is a specialized medical practice that involves the continuous...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hemodynamic monitoring is a specialized medical practice that involves the continuous measurement and assessment of the dynamic functions of the cardiovascular system, including the heart and blood vessels. This monitoring provides critical information about a patient's blood circulation, heart function, and fluid status, helping healthcare professionals make informed decisions in the management of critically ill patients. By closely tracking key hemodynamic parameters, such as blood pressure, heart rate, cardiac output, and central venous pressure, healthcare providers can assess the overall cardiovascular performance, detect abnormalities, and guide interventions to optimize patient care.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hemodynamic monitoring techniques may involve invasive methods, such as the insertion of catheters into blood vessels or the heart, to directly measure pressures and volumes. This allows for accurate and real-time assessment of the heart's pumping ability and the efficiency of blood circulation. Non-invasive methods, including electrocardiography (ECG) and echocardiography, are also utilized to provide valuable insights into cardiac function. Hemodynamic monitoring is particularly crucial in critical care settings, such as intensive care units (ICUs) and operating rooms, where precise and timely information about the cardiovascular system is essential for guiding treatment strategies and maintaining hemodynamic stability.",
          },
        ],
      },
      {
        name: "Intravenous Medications",
        img: "../photo/ddd6.jpg",
        content:
          "Intravenous (IV) medications refer to drugs that are administered directly into a...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Intravenous (IV) medications refer to drugs that are administered directly into a patient's bloodstream through a vein. This method of drug delivery offers several advantages, including rapid onset of action, precise dosage control, and the ability to deliver medications to the systemic circulation quickly. IV medications are commonly used in various medical settings, such as hospitals, clinics, and emergency departments, to treat a wide range of conditions and provide immediate relief or therapy.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The process of administering IV medications involves inserting a sterile IV catheter or needle into a suitable vein, usually in the arm or hand. The medication is then introduced into the bloodstream, where it quickly spreads throughout the body and exerts its therapeutic effects. IV medications can include antibiotics to treat infections, pain relievers, fluids and electrolytes to restore hydration and electrolyte balance, anti-inflammatory drugs, chemotherapy agents for cancer treatment, and medications to manage conditions like heart failure or shock.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Healthcare professionals carefully select the appropriate IV medication based on the patient's condition, medical history, and treatment goals. Dosage and infusion rates are precisely calculated to achieve the desired therapeutic outcomes while minimizing the risk of adverse effects.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              " IV medication administration requires close monitoring of the patient's vital signs and response to the treatment. This allows healthcare providers to make necessary adjustments to the dosage or infusion rate as needed. Intravenous medications play a vital role in delivering timely and effective medical interventions, particularly in critical care situations where rapid treatment is essential for patient well-being.",
          },
        ],
      },
    ],
  },
  {
    name: "Psychiatry",
    img: "",
    icon: "",
    content:
      "Welcome to Adhventha Hospital, where we understand the importance of mental well-being and offer a range of compassionate and expert Psychiatry care services to support you on your journey to mental health and emotional wellness. Our dedicated team of skilled psychiatrists and mental health professionals is committed to providing personalized care that addresses your unique needs.",
    doctor: [
      {
        img: "../../photo/doctor.png",
        names: "Dr. Anupama",
        quali: "MBBS, MD(CSDMISER)",
        experi: "5",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Psychotherapy (Talk Therapy)",
        img: "../photo/rrr1.jpg",
        content:
          "Psychotherapy, often referred to as talk therapy, is a therapeutic approach that involves...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Psychotherapy, often referred to as talk therapy, is a therapeutic approach that involves conversations between a trained mental health professional and an individual, couple, family, or group. The primary goal of psychotherapy is to help individuals address emotional and psychological challenges, improve their mental well-being, and develop effective coping strategies. It provides a safe and confidential space for individuals to explore their thoughts, feelings, behaviors, and relationships in order to gain insight and make positive changes.",
          },
          {
            img: "",
            name: "There are several types of psychotherapy, each with its own techniques and focus",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "1. Cognitive-Behavioral Therapy (CBT)",
            ques: "",
            content:
              "CBT is a widely used form of psychotherapy that helps individuals identify and change negative thought patterns and behaviors that contribute to their mental health issues. It is effective for various conditions, such as depression, anxiety disorders, and phobias.",
          },
          {
            img: "",
            name: "2. Psychodynamic Therapy",
            ques: "",
            content:
              "This therapy focuses on exploring unconscious thoughts and unresolved conflicts that may be contributing to emotional distress. Psychodynamic therapy aims to increase self-awareness and insight into how past experiences impact current thoughts and behaviors.",
          },
        ],
      },
      {
        name: "Medication",
        img: "../photo/rrr2.jpg",
        content:
          "Medication is a crucial component of medical treatment that involves the use of...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Medication is a crucial component of medical treatment that involves the use of pharmaceutical substances to prevent, alleviate, or manage various health conditions and symptoms. Medications are prescribed by healthcare professionals, such as doctors or nurse practitioners, based on a thorough assessment of the patient's medical history, diagnosis, and specific needs. They come in various forms, including pills, tablets, capsules, liquids, injections, creams, and patches, and can target a wide range of health issues.",
          },
          {
            img: "",
            name: "Medications serve several purposes",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "1. Symptom Relief",
            ques: "",
            content:
              "Medications are often prescribed to alleviate symptoms of various medical conditions, such as pain, fever, inflammation, or coughing. Analgesics, antipyretics, anti-inflammatories, and antitussives are examples of medications used for symptom relief.",
          },
          {
            img: "",
            name: "2. Disease Management",
            ques: "",
            content:
              "Medications play a crucial role in managing chronic diseases and conditions, such as diabetes, hypertension, asthma, and mental health disorders. These medications help control the condition, prevent complications, and improve the patient's quality of life.",
          },
          {
            img: "",
            name: "3. Infection Treatment",
            ques: "",
            content:
              "Antibiotics and antiviral medications are used to treat bacterial and viral infections, respectively. These medications target and eliminate or inhibit the growth of harmful microorganisms.",
          },
        ],
      },
      {
        name: "Psychotherapeutic",
        img: "../photo/rrr8.jpg",
        content:
          "Psychotherapeutic refers to the use of various therapeutic techniques and approaches...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Psychotherapeutic refers to the use of various therapeutic techniques and approaches to treat psychological and emotional issues in individuals. It involves the interaction between a trained mental health professional and a client or patient, with the goal of alleviating symptoms, improving emotional well-being, and promoting personal growth and development.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Psychotherapy can take many different forms, each with its own theoretical foundation and methods. Some common psychotherapeutic approaches include",
          },
          {
            img: "",
            name: "",
            ques: "1) Cognitive Behavioral Therapy (CBT)",
            content:
              "Focuses on identifying and challenging negative thought patterns and behaviors to promote healthier cognitive and emotional responses.",
          },
          {
            img: "",
            name: "",
            ques: "2) Psychodynamic Therapy",
            content:
              "Explores the unconscious mind and its influence on thoughts and behaviors, often delving into unresolved conflicts and past experiences.",
          },
          {
            img: "",
            name: "",
            ques: "3) Humanistic Therapy",
            content:
              "Emphasizes self-actualization, personal growth, and the importance of the individual's subjective experience. Person-centered therapy, a type of humanistic therapy developed by Carl Rogers, is a well-known example.",
          },
          {
            img: "",
            name: "",
            ques: "4) Behavioral Therapy",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "These are just a few examples, and there are many more psychotherapeutic approaches used by mental health professionals depending on the client's needs, the nature of the issue, and the therapist's expertise. It's important to note that psychotherapy is typically conducted by trained professionals, such as psychologists, psychiatrists, social workers, counselors, or licensed therapists. The choice of approach often depends on the individual's preferences, the therapist's expertise, and the specific issues being addressed.",
          },
        ],
      },
      // {
      //     name:"Electroconvulsive Therapy (ECT)",
      //     img:"../photo/rrr3.jpg",
      //     content:"Electroconvulsive Therapy (ECT) is a medical procedure used to treat severe mental...",
      //     bodyes:[
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"Electroconvulsive Therapy (ECT) is a medical procedure used to treat severe mental health disorders, particularly severe depression, bipolar disorder, and sometimes schizophrenia, that have not responded well to other forms of treatment. ECT involves the controlled induction of a generalized seizure in the brain through the application of electric currents. This procedure is performed under carefully monitored medical conditions and often in collaboration with a psychiatrist, anesthesiologist, and nursing staff.",
      //         },
      //         {
      //             img:"",
      //             name:"",
      //             ques:"",
      //             content:"During ECT, the patient is first administered a short-acting general anesthetic to ensure they are unconscious and unaware during the procedure. Muscle relaxants are also used to prevent any physical movement during the induced seizure. Electrodes are placed on specific locations on the patient's scalp, and a controlled electrical current is passed through the brain for a fraction of a second, causing a brief seizure. This seizure activity is thought to trigger changes in brain chemistry that can alleviate symptoms of severe mental illness.",
      //         },
      //     ],
      // },
      {
        name: "Transcranial Magnetic Stimulation (TMS)",
        img: "../photo/rrr4.jpg",
        content: "Transcranial Magnetic Stimulation (TMS) is a non-invasive...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Transcranial Magnetic Stimulation (TMS) is a non-invasive medical procedure used to treat certain mental health disorders, particularly depression that has not responded adequately to other treatments. TMS involves the use of electromagnetic fields to stimulate specific areas of the brain associated with mood regulation. This procedure is performed under the guidance of trained medical professionals, often in collaboration with psychiatrists or neurologists. During a TMS session, the patient is seated in a comfortable chair, and a specially designed electromagnetic coil is placed on the scalp over the targeted brain region. The coil delivers focused magnetic pulses that pass through the skull and induce small electrical currents in the brain. These magnetic pulses are painless and typically cause minimal discomfort. The repetitive nature of the pulses is thought to stimulate the neural circuits involved in mood regulation and help alleviate symptoms of depression.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "TMS is usually administered as a series of sessions over several weeks, with daily treatments lasting around 20-30 minutes each. The exact treatment protocol, including the number of sessions and their duration, varies depending on the patient's condition and response. TMS has been found to be particularly effective for individuals with treatment-resistant depression, offering an alternative when traditional treatments like medication and psychotherapy have not been successful. The side effects of TMS are generally mild and temporary, with some patients reporting mild scalp discomfort or headache. Overall, TMS represents a promising avenue for managing certain mental health disorders with a focus on minimal invasiveness and improved quality of life for patients.",
          },
        ],
      },
      {
        name: "Hospitalization or Residential Treatment",
        img: "../photo/rrr5.jpg",
        content: "Hospitalization or residential treatment refers to...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hospitalization or residential treatment refers to a structured and intensive form of care where individuals with severe or acute medical, psychiatric, or behavioral conditions receive around-the-clock monitoring, support, and treatment within a specialized facility. This level of care is typically recommended when outpatient treatments, such as therapy or medication, have not been sufficient to address the individual's needs and when there is a significant risk to their health or well-being. Hospitalization or residential treatment can take place in various settings, including general hospitals, psychiatric hospitals, or dedicated residential treatment centers.",
          },
          {
            img: "",
            name: "During Hospitalization or Residential Treatment",
            ques: "",
            content:
              "Upon admission to a hospital or residential treatment facility, the individual undergoes a comprehensive assessment to determine their specific needs and create an individualized treatment plan. This plan may include medical interventions, psychiatric care, therapy sessions, medication management, and activities designed to promote physical and emotional well-being. The treatment team, which may consist of doctors, nurses, therapists, and other healthcare professionals, closely monitors the individual's condition and progress throughout their stay.",
          },
          {
            img: "",
            name: "When Is It Recommended",
            ques: "",
            content:
              "Hospitalization or residential treatment is often recommended when an individual's condition is severe, poses a risk to their safety, or requires intensive intervention. This can include situations such as acute psychiatric crises, severe substance use disorders, self-harm, suicidal ideation, or other life-threatening conditions. Residential treatment can also be beneficial for individuals with chronic mental health conditions that have not improved with outpatient care, or for those who require a highly structured and supportive environment to make progress.",
          },
        ],
      },
      {
        name: "Support Groups",
        img: "../photo/rrr6.jpg",
        content:
          "Support groups are gatherings of individuals who come together to share their...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Support groups are gatherings of individuals who come together to share their experiences, challenges, and emotions related to a specific topic, condition, or life situation. These groups provide a safe and empathetic environment where participants can connect with others who understand their struggles. Support groups can cover a wide range of topics, such as mental health issues, chronic illnesses, grief and loss, addiction recovery, parenting, and more.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The primary purpose of support groups is to offer mutual support, validation, and a sense of belonging. Members have the opportunity to share their stories, listen to others, and offer empathy and encouragement. By connecting with individuals who have faced similar challenges, participants often find comfort in knowing they are not alone in their experiences. Support groups can also provide practical advice, coping strategies, and a space to learn from the experiences and insights of others. Additionally, support groups can play a role in reducing feelings of isolation and promoting mental and emotional well-being, making them valuable resources for individuals seeking understanding and companionship on their journey.",
          },
        ],
      },
      {
        name: "Lifestyle Changes",
        img: "../photo/rrr7.jpg",
        content:
          "Lifestyle changes refer to intentional modifications in one's daily habits, behaviors, and...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Lifestyle changes refer to intentional modifications in one's daily habits, behaviors, and routines that aim to improve overall well-being, health, and quality of life. These changes encompass various aspects of life, including physical activity, nutrition, sleep, stress management, social interactions, and more. Making positive lifestyle changes can have a significant impact on both physical and mental health, leading to long-term benefits and a greater sense of vitality.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Engaging in regular physical activity is a fundamental lifestyle change that promotes cardiovascular health, strengthens muscles, and boosts mood. Adopting a balanced and nutritious diet rich in fruits, vegetables, lean proteins, and whole grains supports optimal bodily function and energy levels. Prioritizing sufficient and restful sleep contributes to cognitive function, emotional well-being, and overall resilience. Effective stress management techniques, such as mindfulness, meditation, or engaging in hobbies, can reduce the negative effects of chronic stress. Positive social interactions and maintaining meaningful relationships enhance emotional support and foster a sense of connection. Lifestyle changes are most effective when approached gradually, with a focus on sustainable habits, and can lead to a more fulfilling and healthier life.",
          },
        ],
      },
    ],
  },
  {
    name: "Physiotherapy",
    img: "",
    icon: "",
    content:
      "Welcome to Adhventha Hospital, where we take pride in offering comprehensive and specialized Physiotherapy care services to help you regain strength, mobility, and overall well-being. Our dedicated team of highly skilled and experienced physiotherapists is committed to providing personalized care that supports your physical recovery and enhances your quality of life.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Arvind",
        quali: "BPT, MPT, Fellow in Neuromuscular Therapy",
        experi: "10",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Therapeutic Exercises",
        img: "../photo/kkk1.jpg",
        content:
          "Therapeutic exercises are specialized physical activities designed and prescribed by...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Therapeutic exercises are specialized physical activities designed and prescribed by healthcare professionals, such as physical therapists or occupational therapists, to aid in the rehabilitation, recovery, and improvement of a person's physical function, mobility, strength, flexibility, and overall well-being. These exercises are tailored to an individual's specific needs, taking into account their medical condition, injury, or functional limitations. The primary goal of therapeutic exercises is to enhance or restore physical abilities, reduce pain, prevent further injury, and improve the person's overall quality of life.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Therapeutic exercises can encompass a wide range of activities, including stretching, strengthening, cardiovascular conditioning, balance training, and functional movements. These exercises can be performed using various techniques, equipment, and modalities, depending on the person's condition and treatment plan. For example, therapeutic exercises might involve resistance bands, weights, stability balls, or even specific body movements designed to target specific muscle groups or address particular functional deficits.",
          },
        ],
      },
      {
        name: "Pain Management",
        img: "../photo/kkk8.jpg",
        content:
          "Pain management is a comprehensive approach to alleviate, minimize, or control pain...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pain management is a comprehensive approach to alleviate, minimize, or control pain experienced by individuals suffering from acute or chronic conditions. It encompasses a variety of medical, psychological, and therapeutic strategies aimed at improving the quality of life and overall well-being of those dealing with pain. Pain management focuses not only on addressing the physical sensation of pain but also on addressing its emotional, psychological, and social impacts.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Medical interventions in pain management can include medication management, where healthcare professionals prescribe and monitor the use of analgesic medications such as nonsteroidal anti-inflammatory drugs (NSAIDs), opioids, and nerve-specific medications. These medications aim to reduce pain intensity and improve the individual's ability to function. However, pain management extends beyond pharmacological approaches.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Physical and rehabilitative therapies are often integral to pain management. Physical therapy, occupational therapy, and specialized exercises help improve muscle strength, flexibility, and joint mobility, promoting better functional capacity and reducing pain. Additionally, modalities such as heat therapy, cold therapy, ultrasound, and electrical stimulation can play a role in pain relief and tissue healing. Psychological approaches, such as cognitive-behavioral therapy (CBT), relaxation techniques, and mindfulness, are valuable components of pain management. These therapies help individuals develop coping mechanisms, manage stress, and alter their perception of pain, leading to better emotional and psychological well-being.",
          },
        ],
      },
      {
        name: "Postural Education",
        img: "../photo/kkk6.jpg",
        content:
          "Postural education involves teaching individuals about the significance of maintaining...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Postural education involves teaching individuals about the significance of maintaining proper body alignment and positioning during various activities. This education focuses on raising awareness of posture-related habits and their impact on musculoskeletal health. By providing guidance on optimal postural habits, individuals can learn to prevent discomfort, reduce the risk of injuries, and enhance overall physical well-being. Postural education often includes instruction on proper sitting, standing, and lifting techniques, as well as ergonomic adjustments to workspaces. This education empowers individuals to make informed choices about their posture, promoting long-term musculoskeletal health and improved daily functioning.",
          },
        ],
      },
      {
        name: "Gait and Movement Training",
        img: "../photo/kkk5.jpg",
        content:
          "Gait and movement training is a specialized approach used in physical therapy and...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gait and movement training is a specialized approach used in physical therapy and rehabilitation to improve an individual's walking pattern (gait) and overall movement mechanics. It focuses on assessing, analyzing, and addressing any abnormalities, imbalances, or limitations in how a person walks and moves. Gait and movement training is beneficial for individuals with a wide range of conditions, including musculoskeletal injuries, neurological disorders, post-surgical recovery, and gait disorders.",
          },
          {
            img: "",
            name: "The process of gait and movement training typically involves several key steps ",
            ques: "",
            content: "",
          },
          {
            img: "",
            name: "",
            ques: "Assessment",
            content:
              "A thorough evaluation is conducted by a trained healthcare professional, such as a physical therapist, to assess the individual's gait and movement patterns. This assessment may include observing the person's walking, analyzing joint and muscle movements, assessing balance and coordination, and identifying any asymmetries or deviations from normal gait.",
          },
          {
            img: "",
            name: "",
            ques: "Gait Training",
            content:
              "Individuals undergo targeted gait training sessions where they work on improving their walking pattern. This may involve practicing proper heel-to-toe stride, maintaining an upright posture, optimizing arm swing, and coordinating movements between the upper and lower body.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gait and movement training plays a vital role in enhancing mobility, function, and quality of life for individuals with movement challenges. It aims to restore normal movement patterns, reduce pain, prevent further complications, and promote independence in daily activities. Through guided training and therapeutic interventions, individuals can achieve more efficient and effective gait and movement patterns, allowing them to move with greater ease and confidence.",
          },
        ],
      },
      {
        name: "Rehabilitation after Surgery",
        img: "../photo/kkk9.jpg",
        content:
          "Rehabilitation after surgery, also known as post-operative rehabilitation, is a structured...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Rehabilitation after surgery, also known as post-operative rehabilitation, is a structured and personalized process involving therapeutic interventions aimed at facilitating recovery, restoring physical function, and optimizing overall well-being following a surgical procedure. It encompasses a range of medical, rehabilitative, and therapeutic approaches, including exercises, physical therapy, pain management strategies, and lifestyle modifications, tailored to the specific surgical procedure and individual's needs. The goal of post-operative rehabilitation is to reduce pain, prevent complications, improve mobility, regain strength, enhance range of motion, and promote a swift return to normal activities, ultimately enabling the individual to regain their pre-surgery level of functioning and quality of life.",
          },
        ],
      },
      {
        name: "Respiratory Physiotherapy",
        img: "../photo/kkk7.jpg",
        content:
          "Respiratory physiotherapy, also known as pulmonary physiotherapy or chest...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Respiratory physiotherapy, also known as pulmonary physiotherapy or chest physiotherapy, is a specialized branch of physical therapy focused on the assessment, treatment, and management of respiratory and breathing disorders. It involves a range of techniques and interventions aimed at improving lung function, enhancing breathing mechanics, and promoting effective airway clearance. Respiratory physiotherapy is commonly used for individuals with conditions such as chronic obstructive pulmonary disease (COPD), asthma, cystic fibrosis, bronchiectasis, and post-operative lung complications.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "One key aspect of respiratory physiotherapy is airway clearance techniques. These techniques aim to mobilize and remove mucus and secretions from the airways, which can become thick and difficult to clear in individuals with respiratory conditions. Techniques such as chest percussion, postural drainage, active cycle of breathing, and positive expiratory pressure devices are used to help loosen and facilitate the removal of mucus, improving lung ventilation and reducing the risk of infections.",
          },
        ],
      },
      {
        name: "Electrotherapy",
        img: "../photo/kkk3.jpg",
        content:
          "Electrotherapy refers to the use of electrical stimulation for therapeutic purposes in the...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Electrotherapy refers to the use of electrical stimulation for therapeutic purposes in the field of physical therapy and rehabilitation. It involves the application of controlled electrical currents to specific areas of the body with the aim of promoting healing, reducing pain, improving muscle strength, and enhancing overall functional recovery. Electrotherapy techniques utilize various electrical modalities to achieve these goals, and they are often prescribed by healthcare professionals as part of a comprehensive treatment plan.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "One common form of electrotherapy is Transcutaneous Electrical Nerve Stimulation (TENS), which involves applying electrodes to the skin over areas of pain. TENS delivers low-voltage electrical impulses that can help modulate pain signals and provide pain relief. It is often used for conditions such as chronic pain, musculoskeletal injuries, and postoperative discomfort.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Another example is Electrical Muscle Stimulation (EMS), where electrical currents are used to stimulate muscle contractions. EMS can be beneficial for muscle strengthening, improving muscle tone, and preventing muscle atrophy, especially in cases of injury, immobilization, or neurological conditions.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Interferential Current (IFC) therapy involves the use of two slightly different electrical frequencies applied simultaneously. This technique creates a beat frequency that can penetrate deeper into tissues, providing pain relief, reducing muscle spasms, and promoting circulation.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Electrotherapy is generally considered safe when administered by trained professionals and used within appropriate parameters. However, it should be used with caution and under the guidance of a healthcare provider, as individual responses to electrical stimulation can vary. A thorough assessment and proper treatment planning are essential to ensure the effectiveness and safety of electrotherapy interventions.",
          },
        ],
      },
      {
        name: "Manual Therapy",
        img: "../photo/kkk2.jpg",
        content:
          "Manual therapy is a specialized form of physical therapy that involves hands-on...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Manual therapy is a specialized form of physical therapy that involves hands-on techniques performed by trained healthcare professionals, such as physical therapists or chiropractors, to diagnose, treat, and manage various musculoskeletal and neuromuscular conditions. It encompasses a wide range of skilled manual techniques designed to improve joint mobility, reduce pain, increase flexibility, and enhance overall physical function. Manual therapy is often used in conjunction with other therapeutic approaches and exercises to provide comprehensive care.",
          },
          {
            img: "",
            name: "There are several types of manual therapy techniques, including ",
            ques: "Joint Mobilization ",
            content:
              "This technique involves the therapist gently moving a joint through its available range of motion. It aims to improve joint mobility, reduce stiffness, and alleviate pain. Joint mobilization can be particularly effective for conditions such as osteoarthritis or frozen shoulder.",
          },
          {
            img: "",
            name: "",
            ques: "Soft Tissue Mobilization ",
            content:
              "Soft tissue techniques target muscles, tendons, ligaments, and other soft structures in the body. This can include techniques like massage, myofascial release, and trigger point therapy. Soft tissue mobilization helps relax tense muscles, improve blood flow, and reduce muscular pain and tightness.",
          },
        ],
      },
      {
        name: "Heat and Cold Therapy",
        img: "../photo/kkk4.jpg",
        content:
          "Heat and cold therapy, also known as thermotherapy and cryotherapy, respectively, are...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Heat and cold therapy, also known as thermotherapy and cryotherapy, respectively, are commonly used modalities in physical therapy and medical treatment to manage pain, reduce inflammation, and promote healing. These therapies involve the application of temperature to affected areas of the body, and their selection depends on the type of injury, stage of healing, and the individual's specific condition.",
          },
          {
            img: "",
            name: "Heat Therapy ",
            ques: "",
            content:
              "Heat therapy involves applying warmth to the body, usually through hot packs, warm towels, or heating pads. It aims to dilate blood vessels, increase blood flow, relax muscles, and alleviate pain. Heat therapy is often used for chronic conditions, such as muscle tension, arthritis, and stiffness. The increased blood circulation helps deliver oxygen and nutrients to the tissues, which can aid in healing and promote relaxation. However, heat should not be used for acute injuries or areas with swelling, as it may exacerbate inflammation",
          },
          {
            img: "",
            name: "Cold Therapy ",
            ques: "",
            content:
              "Cold therapy involves the application of cold or ice to the affected area, often using ice packs or cold compresses. Cold therapy constricts blood vessels, reduces blood flow, and decreases tissue metabolism, effectively reducing inflammation and pain. It is particularly useful in the immediate aftermath of an acute injury, such as a sprain, strain, or swelling. Cold therapy helps minimize swelling by limiting the release of inflammatory chemicals, and it can numb the area, providing temporary relief from pain. Cold therapy should be applied in short intervals to prevent frostbite or tissue damage.",
          },
          {
            img: "",
            name: "Alternating Heat and Cold (Contrast Therapy) ",
            ques: "",
            content:
              "In some cases, alternating between heat and cold therapy can be beneficial. This technique is known as contrast therapy. Alternating between heat and cold can help improve circulation, decrease inflammation, and promote the removal of waste products from the tissues. It is often used in rehabilitation settings for injuries that involve both acute and chronic components.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "It's important to consult a healthcare professional before using heat or cold therapy, especially if you have a pre-existing medical condition or are unsure which modality is appropriate for your situation. Proper application and duration are crucial to ensuring the desired therapeutic effects and preventing any adverse reactions.",
          },
        ],
      },
    ],
  },
  {
    name: "Gastroenterology",
    img: "",
    icon: "",
    content:
      "Welcome to Adhventha Hospital, where we take pride in offering comprehensive and specialized Gastroenterology care services to diagnose, treat, and manage digestive health conditions. Our dedicated team of highly skilled and experienced gastroenterologists is committed to providing personalized care that ensures optimal digestive wellness and enhances your overall quality of life.",
    doctor: [
      {
        img: "../../photo/veer.jpg",
        names: "Dr. Girish P. Veeranna",
        quali:
          "Consultant - Gastroenterology, Hepatology and Interventional Endoscopy",
        experi: "13",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Gallbladder Stone Surgery",
        img: "../photo/Gall-Bladder-Surgery-2.jpg",
        content:
          "Gallbladder stone surgery, also known as cholecystectomy, is a procedure to remove the gallbladder due to gallstones or inflammation.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Gallbladder stone surgery, or cholecystectomy, is performed to remove the gallbladder when gallstones cause pain, infection, or blockages. It can be done laparoscopically or through open surgery, depending on the severity of the condition.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laparoscopic cholecystectomy is a minimally invasive approach using small incisions and a camera to remove the gallbladder, promoting quicker recovery. Open cholecystectomy is recommended for complex cases requiring larger incisions.",
          },
        ],
      },
      {
        name: "Hernia Surgery",
        img: "../photo/hernia.jpg",
        content:
          "Hernia surgery is performed to repair a hernia by reinforcing the weakened tissue and preventing further complications.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Hernia surgery involves repairing a bulging organ or tissue through a weak spot in the abdominal wall. It can be performed using open surgery or laparoscopic techniques, depending on the type and severity of the hernia.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The procedure often includes the placement of a mesh to reinforce the weakened area, reducing the risk of recurrence. Patients typically recover faster with laparoscopic surgery compared to open surgery.",
          },
        ],
      },
      {
        name: "Liver Surgery",
        img: "../photo/Liver.webp",
        content:
          "Liver surgery is performed to remove tumors, cysts, or damaged portions of the liver to improve function and overall health.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Liver surgery is used to treat various conditions, including liver cancer, cirrhosis, and tumors. It involves partial hepatectomy (removal of part of the liver) or liver transplantation in severe cases.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Minimally invasive liver surgeries allow for faster recovery, while complex cases may require open surgery. The liver has remarkable regenerative abilities, aiding post-surgery recovery.",
          },
        ],
      },
      {
        name: "Piles / Fissure / Fistula Surgery",
        img: "../photo/Fistula.png",
        content:
          "Surgical treatments for piles, fissures, and fistulas aim to relieve pain, reduce swelling, and restore normal bowel function.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Piles (hemorrhoids), anal fissures, and fistulas are common anorectal conditions treated through surgical interventions like hemorrhoidectomy, sphincterotomy, or fistulotomy to reduce discomfort and prevent complications.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laser and minimally invasive procedures are increasingly used for faster healing and reduced postoperative pain. The choice of surgery depends on the severity and recurrence of the condition.",
          },
        ],
      },
      {
        name: "Appendix Surgery",
        img: "../photo/Appendix.jpg",
        content:
          "Appendix surgery, or appendectomy, is a procedure to remove an inflamed appendix, preventing rupture and serious complications.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Appendectomy is the standard treatment for appendicitis, a condition in which the appendix becomes inflamed and may rupture if not treated promptly. It can be performed as an open or laparoscopic surgery.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laparoscopic appendectomy is the preferred method due to its minimal invasiveness, leading to faster recovery and reduced postoperative pain.",
          },
        ],
      },
      {
        name: "Esophageal Surgery",
        img: "../photo/Esophageal.webp",
        content:
          "Esophageal surgery is performed to treat conditions such as GERD, esophageal cancer, and strictures that affect swallowing.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Esophageal surgery includes procedures like fundoplication for GERD, esophagectomy for cancer, and dilation for strictures, improving swallowing and digestion.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Minimally invasive esophageal surgeries allow for quicker recovery, reducing complications and ensuring better long-term outcomes for patients.",
          },
        ],
      },
      {
        name: "Cholecystectomy",
        img: "../photo/Cholecystectomy.png",
        content:
          "Cholecystectomy is the surgical removal of the gallbladder, usually due to gallstones or chronic gallbladder disease.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Cholecystectomy is performed to remove the gallbladder when gallstones or inflammation cause severe pain or blockages in the bile ducts. The procedure can be done laparoscopically or as an open surgery.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Laparoscopic cholecystectomy is a common and minimally invasive procedure that ensures faster healing and minimal scarring.",
          },
        ],
      },
      {
        name: "Pancreatic Surgery",
        img: "../photo/Pancreatic.jpg",
        content:
          "Pancreatic surgery treats pancreatic cancer, cysts, or chronic pancreatitis to improve digestive function and prevent complications.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Pancreatic surgery includes procedures like the Whipple procedure, distal pancreatectomy, or total pancreatectomy, depending on the condition affecting the pancreas.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Minimally invasive techniques are being adopted for pancreatic surgeries to reduce recovery time and postoperative complications.",
          },
        ],
      },
      {
        name: "Acid Reflux Surgery",
        img: "../photo/Acid.jpg",
        content:
          "Acid reflux surgery, such as fundoplication, is performed to prevent acid from flowing back into the esophagus, reducing GERD symptoms.",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Acid reflux surgery is commonly performed for severe GERD cases that do not respond to medication. Fundoplication tightens the lower esophageal sphincter to prevent acid reflux.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Minimally invasive laparoscopic procedures are preferred for acid reflux surgery, offering faster recovery and improved symptom relief.",
          },
        ],
      },
    ],
  },
  {
    name: "Urology",
    img: "",
    icon: "",
    content:
      "At our hospital, we provide comprehensive urology care with advanced diagnostics, minimally invasive surgeries, and personalized treatment plans, prioritizing patient well-being and optimal outcomes.",
    doctor: [
      {
        img: "../../photo/doctoro.png",
        names: "Dr. Manjunatha.S",
        quali: "MBBS, MS, MCH",
        experi: "10",
      },
    ],
    infoline: "",
    serviceline: "",
    service: [
      {
        name: "Urinary Stone",
        img: "../photo/ggg1.jpg",
        content:
          "Urinary stones, also known as kidney stones or renal calculi, are solid crystalline...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary stones, also known as kidney stones or renal calculi, are solid crystalline structures that form within the urinary tract. These stones can develop anywhere along the urinary system, including the kidneys, ureters, bladder, and urethra. They can vary in size and composition, with common types being calcium oxalate, calcium phosphate, uric acid, and struvite stones.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The formation of urinary stones often results from the concentration of certain minerals and salts in the urine, coupled with inadequate fluid intake. These crystals can aggregate and grow over time, leading to the formation of stones that can cause significant pain, discomfort, and various urinary symptoms. Symptoms may include severe flank pain, lower abdominal pain, frequent urination, blood in the urine, and pain during urination.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Treatment for urinary stones depends on factors such as the size, location, and composition of the stone, as well as the severity of symptoms. Small stones can sometimes pass on their own with increased fluid intake and pain management. However, larger stones or those causing severe symptoms may require intervention. Modern approaches include minimally invasive techniques like extracorporeal shock wave lithotripsy (ESWL), ureteroscopy, and percutaneous nephrolithotomy (PCNL). These methods utilize specialized equipment to break down or remove stones, often avoiding the need for open surgery.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Prevention strategies are crucial in managing urinary stones, and these often involve dietary modifications, increased fluid intake, and medications to adjust urine composition. Consulting with a urologist is essential to determine the appropriate treatment and prevention plan based on the individual's condition. Our hospital offers comprehensive care, from diagnosis to treatment and prevention, using cutting-edge technology and a patient-centered approach to improve the quality of life for individuals affected by urinary stones.",
          },
        ],
      },
      {
        name: "Enlarged Prostate",
        img: "../photo/ggg2.jpg",
        content:
          "Enlarged prostate, also known as benign prostatic hyperplasia (BPH), is a common...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Enlarged prostate, also known as benign prostatic hyperplasia (BPH), is a common condition that affects aging men. It involves the non-cancerous growth of the prostate gland, a walnut-sized organ located around the urethra. As the prostate enlarges, it can squeeze and narrow the urethra, leading to a range of urinary symptoms. These symptoms can include frequent urination, urgency, weak urine stream, difficulty starting and stopping urination, and the feeling of incomplete bladder emptying.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Enlarged prostate is a natural part of the aging process and is not linked to prostate cancer. While the exact cause is not fully understood, hormonal changes, particularly the influence of dihydrotestosterone (DHT), play a significant role in the development of BPH. Although the condition is generally not life-threatening, it can significantly impact the quality of life by affecting urinary function and causing discomfort. Treatment options range from lifestyle changes, such as reducing caffeine and alcohol intake, to medications that help relax the prostate and improve urine flow. In more severe cases, minimally invasive procedures or surgery might be considered to alleviate symptoms and improve urinary function. It's important for men experiencing urinary symptoms to consult a healthcare professional, as they can provide an accurate diagnosis and recommend the most suitable treatment plan based on the individual's condition and preferences.",
          },
        ],
      },
      {
        name: "Kidney Cancer",
        img: "../photo/ggg3.jpg",
        content:
          "Kidney cancer, also known as renal cell carcinoma (RCC), is a type of cancer that...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Kidney cancer, also known as renal cell carcinoma (RCC), is a type of cancer that originates in the cells of the kidneys, the bean-shaped organs responsible for filtering and removing waste products from the blood to form urine. Kidney cancer can develop in one or both kidneys and is often asymptomatic in its early stages. However, as the cancer advances, it may cause symptoms such as blood in the urine, persistent back pain or discomfort, unexplained weight loss, fatigue, and abdominal pain.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The exact cause of kidney cancer is not always clear, but certain risk factors such as smoking, obesity, high blood pressure, and a family history of the disease can increase the likelihood of its development. There are different subtypes of kidney cancer, with clear cell carcinoma being the most common. Diagnosing kidney cancer typically involves a combination of imaging tests like CT scans and MRIs, along with biopsy to confirm the presence of cancerous cells.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Treatment options for kidney cancer depend on factors such as the stage and size of the tumor, the patient's overall health, and the specific subtype of the cancer. Surgery to remove the tumor or the entire affected kidney is often the primary treatment for localized kidney cancer. In cases where the cancer has spread beyond the kidney, targeted therapies, immunotherapies, and chemotherapy may be employed to slow its progression. Early detection and prompt intervention are crucial for better treatment outcomes. If kidney cancer is suspected, consulting a medical professional for proper evaluation and diagnosis is essential to determine the most appropriate treatment plan based on individual circumstances.",
          },
        ],
      },
      {
        name: "Prostate Cancer",
        img: "../photo/ggg4.jpg",
        content:
          "Prostate cancer is a type of cancer that forms in the cells of the prostate gland, a small...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Prostate cancer is a type of cancer that forms in the cells of the prostate gland, a small walnut-sized organ located below the bladder in men. The prostate gland produces seminal fluid that nourishes and transports sperm. Prostate cancer is one of the most common cancers in men, and it usually grows slowly. In some cases, however, it can be aggressive and spread to other parts of the body.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The risk factors for prostate cancer include age, family history, race (African American men are at a higher risk), and certain genetic mutations. Early prostate cancer may not cause noticeable symptoms, but as the cancer progresses, symptoms can include difficulty urinating, weak or interrupted urine flow, blood in the urine or semen, discomfort in the pelvic area, bone pain, and erectile dysfunction.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis often involves a combination of a digital rectal exam (DRE), prostate-specific antigen (PSA) blood test, and imaging studies like ultrasound and MRI. A biopsy is typically performed to confirm the presence of cancerous cells. Treatment options for prostate cancer vary based on the stage and aggressiveness of the cancer, as well as the patient's overall health. Treatment can include active surveillance (monitoring without immediate intervention), surgery to remove the prostate (prostatectomy), radiation therapy, hormone therapy, chemotherapy, and newer treatments like immunotherapy and targeted therapies.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Prostate cancer treatment decisions should be made in consultation with healthcare professionals, considering factors such as the patient's age, overall health, and the potential benefits and risks of each treatment option. Early detection through regular check-ups and screening is essential for improved prognosis and successful management of prostate cancer.",
          },
        ],
      },
      {
        name: "Urinary Bladder Cancer",
        img: "../photo/ggg5.jpg",
        content:
          "Urinary bladder cancer is a malignancy that originates in the cells lining the bladder, the...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary bladder cancer is a malignancy that originates in the cells lining the bladder, the hollow organ responsible for storing urine. This type of cancer often begins in the innermost layer of the bladder and can spread to deeper layers and nearby tissues if left untreated. Bladder cancer can be classified into different types, with urothelial carcinoma (transitional cell carcinoma) being the most common.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Risk factors for bladder cancer include smoking, exposure to certain chemicals, chronic bladder inflammation, and a history of radiation therapy. Symptoms of bladder cancer may include blood in the urine (hematuria), frequent urination, pain or discomfort during urination, and lower abdominal or back pain.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis involves various tests, including urine cytology, imaging studies (such as CT scans or MRI), and cystoscopy—a procedure in which a thin tube with a camera is inserted through the urethra to examine the bladder. Biopsy samples may be taken to confirm the presence of cancerous cells.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Treatment options depend on factors such as the stage and grade of the cancer, the patient's overall health, and their preferences. Treatment may include surgery to remove the tumor, chemotherapy, radiation therapy, immunotherapy, or targeted therapy. For early-stage bladder cancer, the tumor might be removed through a procedure called transurethral resection of bladder tumor (TURBT), which involves using a cystoscope to remove the tumor through the urethra.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The prognosis for bladder cancer varies based on the stage at diagnosis and the effectiveness of treatment. Early detection and prompt treatment are key to better outcomes. If bladder cancer is suspected, seeking medical attention and consulting with a urologist or oncologist is essential to determine the most appropriate diagnostic and treatment plan tailored to the individual's condition.",
          },
        ],
      },
      {
        name: "Ureteric Stricture",
        img: "../photo/ggg6.jpg",
        content:
          "A ureteric stricture refers to a narrowing or constriction of the ureter, which is the tube...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A ureteric stricture refers to a narrowing or constriction of the ureter, which is the tube that carries urine from the kidneys to the bladder. Ureteric strictures can result from various causes, including inflammation, scarring, trauma, infections, previous surgeries, or certain medical conditions. These strictures can partially or completely block the flow of urine, leading to discomfort, urinary tract infections, kidney damage, and impaired kidney function.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Symptoms of ureteric strictures may include pain or discomfort in the flank or lower abdomen, frequent urinary tract infections, difficulty urinating, blood in the urine, and swelling of the kidney (hydronephrosis) due to urine backup. Depending on the severity and location of the stricture, symptoms can range from mild to severe.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis often involves imaging tests such as CT scans, ultrasounds, and ureteroscopy, which is a procedure that uses a thin tube with a camera to visualize the ureter and identify the location and extent of the stricture. Treatment options for ureteric strictures depend on the underlying cause, the degree of constriction, and the overall health of the patient.",
          },
        ],
      },
      {
        name: "Urethral Stricture",
        img: "../photo/ggg7.jpg",
        content:
          "A urethral stricture refers to the narrowing of the urethra, the tube that carries urine...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A urethral stricture refers to the narrowing of the urethra, the tube that carries urine from the bladder out of the body. This narrowing can occur due to scar tissue formation, inflammation, or other factors that cause the urethra to become constricted. Urethral strictures can lead to various urinary symptoms and complications, as they impede the normal flow of urine. They are more common in men and can result from infections, trauma, medical procedures, or even certain congenital conditions.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Symptoms of urethral strictures can include difficulty starting or maintaining urination, a weak or slow urine stream, urinary retention (inability to fully empty the bladder), frequent urination, pain or discomfort during urination, and sometimes blood in the urine. In severe cases, complete blockage of the urethra can occur, leading to acute urinary retention, a medical emergency requiring immediate attention.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis involves a thorough medical history, physical examination, and tests such as imaging studies (such as retrograde urethrography) and cystoscopy—a procedure using a thin tube with a camera to visualize the inside of the urethra. Treatment options for urethral strictures depend on their severity and location. Mild strictures might be managed with observation, while more significant strictures often require intervention. Treatment options include dilation (gradual stretching of the narrowed area), urethrotomy (surgical incision to widen the stricture), or urethroplasty (reconstruction of the urethra).",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "If you suspect you have a urethral stricture or are experiencing urinary symptoms, it's crucial to consult with a urologist. They can diagnose the condition, determine its cause, and recommend the most appropriate treatment plan tailored to your needs. Early intervention can help alleviate symptoms, prevent complications, and improve overall urinary function.",
          },
        ],
      },
      {
        name: "Erectile Dysfunction",
        img: "../photo/ggg8.jpg",
        content:
          "Erectile dysfunction (ED), also known as impotence, is a medical condition characterized...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Erectile dysfunction (ED), also known as impotence, is a medical condition characterized by the consistent inability to achieve or maintain an erection firm enough for sexual intercourse. It can have various underlying causes, including physical, psychological, or a combination of both. Physical factors that contribute to ED include cardiovascular diseases, diabetes, hormonal imbalances, nerve damage, and certain medications. Psychological factors such as stress, anxiety, depression, and relationship issues can also play a significant role in causing or exacerbating ED.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "ED can have a profound impact on a man's quality of life, self-esteem, and relationships. While occasional difficulties with erections are normal, persistent or recurring ED may require medical attention. Diagnosis often involves a detailed medical history, physical examination, and sometimes additional tests like blood tests, ultrasound, or nocturnal penile tumescence testing to determine the cause of the problem.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Treatment options for ED vary based on the underlying cause and severity of the condition. Lifestyle changes such as improving diet, exercising, managing stress, and avoiding smoking and excessive alcohol consumption can be helpful. Medications like phosphodiesterase type 5 (PDE5) inhibitors (e.g., Viagra, Cialis) are commonly prescribed to enhance blood flow to the penis and facilitate erections. Other treatment modalities include vacuum erection devices, penile injections, and surgical implants. Consulting with a healthcare provider or urologist is essential for a proper diagnosis and to develop a tailored treatment plan that addresses the specific needs and concerns of the individual.",
          },
        ],
      },
      {
        name: "Male Infertility",
        img: "../photo/ggg9.jpg",
        content:
          "Male infertility refers to the inability of a man to achieve pregnancy in a fertile female...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Male infertility refers to the inability of a man to achieve pregnancy in a fertile female partner. It is often diagnosed when a couple has been actively trying to conceive for a certain period without success. Male infertility can result from various factors that impact sperm production, function, or delivery. Common causes include low sperm count (oligospermia), poor sperm motility, abnormal sperm morphology, hormonal imbalances, genetic factors, and certain medical conditions.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Symptoms of male infertility may not be obvious, but in some cases, they can include changes in sexual desire, erectile difficulties, pain or swelling in the testicles, and issues with ejaculation or ejaculation disorders. Diagnosis involves a comprehensive evaluation, including a medical history, physical examination, semen analysis, and possibly hormonal tests to assess sperm quality and quantity.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Treatment options for male infertility depend on the specific underlying cause. Lifestyle changes like improving diet, managing stress, and avoiding smoking and excessive alcohol use can have a positive impact on sperm health. Medical interventions may involve addressing hormonal imbalances, treating infections or other medical conditions, and using assisted reproductive techniques such as intrauterine insemination (IUI) or in vitro fertilization (IVF). In some cases, surgical procedures can correct anatomical issues or blockages that may be causing infertility.",
          },
        ],
      },
      {
        name: "Female Urology",
        img: "../photo/ggg10.jpg",
        content:
          "Female urology is a specialized branch of medicine that focuses on the diagnosis and...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Female urology is a specialized branch of medicine that focuses on the diagnosis and treatment of urinary tract and pelvic floor disorders specific to women. These conditions can encompass a wide range of issues affecting the urinary system and reproductive organs. Female urologists are experts who address concerns such as urinary incontinence, urinary tract infections, pelvic organ prolapse, bladder dysfunction, interstitial cystitis, and other conditions that can impact women's quality of life.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Female urology recognizes the unique anatomical and physiological differences in the female urinary and reproductive systems. It also encompasses the study and treatment of conditions that often result from childbirth, hormonal changes, and aging. Common treatments and interventions within female urology include conservative therapies, medications, behavioral modifications, minimally invasive procedures, and surgeries designed to alleviate symptoms and improve urinary and pelvic health.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Patients seeking care in female urology may experience symptoms such as urinary leakage, recurrent infections, pelvic pain, discomfort during intercourse, and other urinary or reproductive issues. Seeking the expertise of a female urologist or a urologist specializing in female urology can provide personalized evaluation, diagnosis, and treatment plans tailored to the specific needs of women, helping them regain comfort, confidence, and optimal urinary and pelvic function.",
          },
        ],
      },
      {
        name: "Female Urinary Incontinence",
        img: "../photo/ggg11.jpg",
        content:
          "Female urinary incontinence refers to the unintentional leakage of urine, a common...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Female urinary incontinence refers to the unintentional leakage of urine, a common condition that primarily affects women. It can occur due to weakened pelvic muscles, damaged pelvic nerves, or changes in the structures supporting the bladder and urethra. There are several types of urinary incontinence, including stress incontinence, urge incontinence, and mixed incontinence.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Stress incontinence occurs when physical activities like laughing, sneezing, coughing, or lifting put pressure on the bladder and lead to urine leakage. Urge incontinence, on the other hand, is characterized by a strong, sudden urge to urinate followed by involuntary urine release. Mixed incontinence is a combination of both stress and urge incontinence. This condition can have a significant impact on a woman's quality of life, affecting her social interactions, physical activities, and emotional well-being.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Management of female urinary incontinence depends on the type and severity of the condition. Treatments may range from lifestyle modifications such as dietary changes, pelvic floor exercises (Kegels), and weight management to medication, bladder training, and minimally invasive procedures. Surgical interventions may be considered for more severe cases. Consulting a healthcare provider or a urologist specializing in female urology is essential for proper evaluation and personalized treatment strategies that aim to improve urinary control and enhance overall quality of life.",
          },
        ],
      },
      {
        name: "Urinary Incontinence",
        img: "../photo/ggg12.jpg",
        content:
          "Urinary incontinence refers to the involuntary loss of urine, leading to unwanted...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary incontinence refers to the involuntary loss of urine, leading to unwanted leakage. This condition can vary in severity and impact, affecting both men and women. It can result from factors such as weakened pelvic muscles, nerve damage, hormonal changes, and underlying health conditions. There are different types of urinary incontinence, including stress incontinence (leakage during activities like coughing or sneezing), urge incontinence (sudden strong urge to urinate), overflow incontinence (frequent dribbling), and mixed incontinence (combination of different types).",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary incontinence can have a profound impact on a person's daily life, leading to embarrassment, social withdrawal, and reduced quality of life. It's important to seek medical evaluation if experiencing symptoms, as effective treatments are available. Management approaches can include lifestyle modifications, pelvic floor exercises, behavioral strategies, medications, and in some cases, surgical interventions. Consulting a healthcare professional or a urologist can help determine the most suitable treatment plan based on the specific type and causes of urinary incontinence, aiming to improve bladder control and overall well-being.",
          },
        ],
      },
      {
        name: "Vesicovaginal Fistula",
        img: "../photo/ggg13.jpg",
        content:
          "A vesicovaginal fistula (VVF) is an abnormal passageway that forms between the urinary...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A vesicovaginal fistula (VVF) is an abnormal passageway that forms between the urinary bladder and the vagina, allowing urine to leak into the vaginal canal. This condition can result from a variety of causes, including complications during childbirth, surgical procedures in the pelvic area, radiation therapy, or inflammatory conditions. VVF can have significant physical and emotional consequences, as it leads to continuous urinary leakage, foul odor, and potential infections, greatly impacting a woman's quality of life.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Childbirth-related VVF often occurs when prolonged or obstructed labor damages the tissues between the bladder and vagina, creating a hole. Women in resource-limited settings are particularly vulnerable due to lack of access to proper medical care during labor. Surgical or trauma-related VVF can result from procedures such as hysterectomy or pelvic surgery. The condition can lead to isolation, social stigma, and emotional distress due to the embarrassment and hygiene challenges associated with constant urine leakage.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis of VVF involves a medical history, physical examination, and often imaging tests such as cystoscopy or intravenous pyelogram to visualize the abnormal passage. Surgical repair, known as VVF repair, is the primary treatment. It involves closing the fistula and restoring the integrity of the bladder and vaginal walls. The surgical approach can vary depending on the size, location, and complexity of the fistula. Rehabilitation and postoperative care are crucial to ensure proper healing and bladder function. Early intervention, skilled surgical care, and comprehensive support are essential for successfully addressing VVF and improving the affected woman's well-being.",
          },
        ],
      },
      {
        name: "Overactive Bladder",
        img: "../photo/ggg14.jpg",
        content:
          "Overactive bladder (OAB) is a urinary condition characterized by a sudden and...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Overactive bladder (OAB) is a urinary condition characterized by a sudden and involuntary urge to urinate, often resulting in frequent trips to the bathroom. It can cause urinary urgency, frequency, and sometimes urinary incontinence. OAB occurs due to the muscles of the bladder contracting inappropriately, even when the bladder isn't full, leading to the strong urge to urinate.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Common symptoms of OAB include a sudden and intense urge to urinate that is difficult to control, frequent urination both during the day and night (nocturia), and sometimes urinary incontinence. OAB can significantly impact a person's quality of life, causing disruption in daily activities, social interactions, and sleep patterns.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis involves a medical history, physical examination, and possibly urodynamic testing to assess bladder function. Treatment options for OAB may include lifestyle modifications such as managing fluid intake, dietary changes, and bladder training. Medications that help relax the bladder muscles and nerve stimulation therapies can also be effective. Behavioral interventions, pelvic floor exercises, and sometimes surgical procedures might be considered for more severe cases that don't respond to conservative treatments.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "If you suspect you have overactive bladder or are experiencing OAB symptoms, consulting a healthcare provider or a urologist can help determine the appropriate diagnosis and treatment plan tailored to your needs.",
          },
        ],
      },
      {
        name: "Post Delivery Urinary Problems",
        img: "../photo/ggg15.jpg",
        content:
          "Post-delivery urinary problems refer to the issues women may experience with their...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Post-delivery urinary problems refer to the issues women may experience with their urinary system after giving birth. Childbirth, especially vaginal delivery, can lead to changes in the pelvic anatomy, muscles, and nerves, which might affect bladder and urinary function. Common post-delivery urinary problems include urinary incontinence, urgency, frequency, and difficulty emptying the bladder.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary incontinence is a significant concern, with stress incontinence being particularly common. This occurs when activities like sneezing, coughing, or lifting put pressure on the bladder and cause urine leakage. Post-delivery, these muscles and supportive structures may become weakened, leading to incontinence.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary urgency and frequency can also result from the stretching and pressure on the bladder during pregnancy and childbirth. Some women might have difficulty emptying their bladder completely, which can lead to urinary tract infections. These post-delivery urinary problems can have a considerable impact on a woman's daily life and well-being.",
          },
        ],
      },
      {
        name: "Interstitial Cystitis",
        img: "../photo/ggg16.jpg",
        content:
          "Interstitial cystitis (IC), also known as painful bladder syndrome, is a chronic condition...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Interstitial cystitis (IC), also known as painful bladder syndrome, is a chronic condition characterized by discomfort or pain in the bladder and pelvic region. It's a complex disorder that primarily affects the urinary system but can also have broader effects on overall well-being. The exact cause of IC is not fully understood, but it's believed to involve a combination of factors, including bladder inflammation, nerve dysfunction, and immune system abnormalities.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Symptoms of interstitial cystitis can vary in intensity and may include pelvic pain, pressure, or discomfort, as well as urinary urgency, frequency, and pain during urination. These symptoms can mimic those of urinary tract infections, making diagnosis challenging. IC can significantly impact a person's quality of life, leading to disruptions in daily activities, sleep disturbances, and emotional distress.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis often involves ruling out other possible causes of symptoms and may include a medical history, physical examination, urine tests, cystoscopy (visualization of the bladder using a thin tube with a camera), and possibly bladder biopsies. Treatment for IC is individualized and can involve a combination of approaches. Lifestyle modifications, dietary changes, stress management, and pelvic floor physical therapy might be recommended. Medications, such as those for pain relief or to reduce bladder inflammation, can also be prescribed. In more severe cases, treatments like bladder instillations (placing medications directly into the bladder) or neuromodulation therapies might be considered.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A healthcare provider, urologist, or specialist in pelvic pain can help diagnose and manage interstitial cystitis. Since IC is a chronic condition, a comprehensive and patient-centered approach is crucial to provide effective symptom relief and improve the individual's overall well-being.",
          },
        ],
      },
      {
        name: "Voiding Difficulty",
        img: "../photo/ggg17.jpg",
        content:
          "Voiding difficulty, also known as urinary retention, refers to the inability to completely...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Voiding difficulty, also known as urinary retention, refers to the inability to completely empty the bladder during urination. It can be caused by various factors that affect the muscles, nerves, or structures involved in the urinary process. Voiding difficulty can result in a feeling of incomplete bladder emptying, frequent trips to the bathroom, weak urine stream, or even no urine output at all.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "There are two main types of urinary retention: acute and chronic. Acute urinary retention is a sudden inability to urinate and is often painful. It requires immediate medical attention. Chronic urinary retention develops over time and might not cause immediate discomfort. It can lead to complications such as urinary tract infections, bladder stones, and damage to the bladder and kidneys.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "The causes of voiding difficulty can vary widely, including benign prostatic hyperplasia (enlarged prostate), nerve dysfunction, medications, constipation, urinary tract obstructions, and certain medical conditions. Diagnosis involves a medical history, physical examination, and often tests like ultrasound, urinary flow tests, and post-void residual measurement (checking how much urine remains in the bladder after urination). Treatment depends on the underlying cause. Catheterization might be needed for acute urinary retention. For chronic retention, treatment may involve addressing the underlying cause, medications, behavioral interventions, and in some cases, surgical procedures. Consulting a healthcare provider or urologist is essential to determine the cause and appropriate management plan for voiding difficulty.",
          },
        ],
      },
      {
        name: "Urinary Tract Infections",
        img: "../photo/ggg18.jpg",
        content:
          "Urinary tract infections (UTIs) are bacterial infections that can affect any part of the...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Urinary tract infections (UTIs) are bacterial infections that can affect any part of the urinary system, including the kidneys, bladder, ureters, and urethra. UTIs are more common in women than men and can cause a range of symptoms. The majority of UTIs are caused by bacteria from the digestive tract entering the urinary system through the urethra.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Symptoms of a UTI can include a strong and persistent urge to urinate, a burning sensation during urination, passing frequent small amounts of urine, cloudy or strong-smelling urine, and pelvic pain or discomfort. In more severe cases, UTIs can cause fever, chills, and lower back pain if the infection reaches the kidneys.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis of UTIs involves a medical history, physical examination, and usually a urine culture to identify the specific bacteria causing the infection. Treatment typically involves antibiotics prescribed by a healthcare provider. It's important to complete the full course of antibiotics even if symptoms improve to prevent the infection from recurring or developing antibiotic-resistant bacteria.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Prevention strategies for UTIs include staying hydrated, urinating regularly, wiping from front to back after using the toilet, and maintaining good hygiene. For individuals who experience frequent UTIs, additional preventive measures might be recommended, such as cranberry products, post-coital voiding, and consulting a healthcare provider for further evaluation.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Prompt treatment and preventive measures are essential to manage UTIs and prevent complications. If you suspect you have a UTI or experience symptoms, seeking medical attention and consulting with a healthcare provider can ensure timely diagnosis and appropriate treatment.",
          },
        ],
      },
      {
        name: "Painful Bladder Syndrome",
        img: "../photo/ggg19.jpg",
        content:
          "Painful Bladder Syndrome (PBS), also known as interstitial cystitis (IC), is a chronic...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Painful Bladder Syndrome (PBS), also known as interstitial cystitis (IC), is a chronic condition characterized by persistent pelvic pain, discomfort, and urgency in the bladder region. It involves inflammation and dysfunction of the bladder lining, leading to symptoms such as frequent urination, pain during urination, and discomfort in the lower abdomen. PBS/IC can significantly impact a person's quality of life, causing disruptions in daily activities, sleep disturbances, and emotional distress. The exact cause is not fully understood, and diagnosis involves ruling out other conditions. Treatment strategies focus on symptom management and may include lifestyle changes, dietary adjustments, medications, and specialized therapies to alleviate discomfort and improve well-being.",
          },
        ],
      },
      {
        name: "Neurogenic Bladder",
        img: "../photo/ggg20.jpg",
        content:
          "A neurogenic bladder is a condition in which the normal functioning of the bladder is...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "A neurogenic bladder is a condition in which the normal functioning of the bladder is disrupted due to nerve damage or neurological disorders. Nerves play a crucial role in coordinating the bladder's storage and emptying functions. When these nerves are impaired, the bladder's ability to contract and relax properly is compromised, leading to problems with urination.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Neurogenic bladder can result from conditions such as spinal cord injuries, multiple sclerosis, Parkinson's disease, stroke, and other neurological disorders that affect the nervous system. Depending on the nature and severity of the nerve damage, individuals with neurogenic bladder might experience urinary retention (inability to fully empty the bladder), urinary incontinence (unintended urine leakage), frequent urination, urgency, and difficulty initiating or stopping urination.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Management of neurogenic bladder requires a comprehensive approach that addresses both the underlying neurological condition and the bladder dysfunction. Treatment options may include catheterization to empty the bladder, medications to relax or stimulate bladder muscles, techniques to enhance bladder emptying, and behavioral strategies such as scheduled voiding. In some cases, surgical interventions might be considered to improve bladder function and overall quality of life.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Consulting a healthcare provider, urologist, or specialist experienced in neurogenic bladder is essential to receive proper evaluation, diagnosis, and personalized treatment strategies that aim to optimize bladder control and urinary function for individuals with this condition.",
          },
        ],
      },
      {
        name: "Cystoscopy",
        img: "../photo/ggg21.jpg",
        content:
          "Cystoscopy is a medical procedure in which a thin, flexible tube with a camera and light...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Cystoscopy is a medical procedure in which a thin, flexible tube with a camera and light at its tip, called a cystoscope, is inserted into the urethra and then navigated through the urinary tract to visually examine the bladder's interior lining. Cystoscopy allows healthcare professionals, typically urologists, to closely inspect the bladder and urethra for abnormalities, diagnose conditions like urinary tract infections, bladder stones, and tumors, as well as guide certain treatments or interventions directly within the urinary system.",
          },
        ],
      },
      {
        name: "Female Sexual Dysfunction",
        img: "../photo/ggg22.jpg",
        content:
          "Female sexual dysfunction (FSD) refers to a range of persistent difficulties or changes in...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Female sexual dysfunction (FSD) refers to a range of persistent difficulties or changes in sexual desire, arousal, satisfaction, or pain that impact a woman's overall sexual experience and well-being. FSD can encompass various factors, both physical and psychological, that affect a woman's ability to enjoy a satisfying and fulfilling sexual life. Common types of FSD include hypoactive sexual desire disorder (low sexual desire), female sexual arousal disorder (difficulty becoming sexually aroused), orgasmic disorder (difficulty achieving orgasm), and genito-pelvic pain/penetration disorder (pain during intercourse).",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "FSD can result from a variety of causes, including hormonal changes, medical conditions, medications, psychological factors such as stress or past trauma, relationship issues, and lifestyle factors. The symptoms can have a significant impact on a woman's self-esteem, body image, and overall quality of life, affecting not only her sexual well-being but also her emotional and mental health.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Diagnosis of FSD involves a comprehensive assessment that considers physical, psychological, and relationship aspects. Treatment approaches vary depending on the underlying causes and the specific type of dysfunction. Strategies can include counseling, sex therapy, communication skills training, lifestyle modifications, hormone therapy, and medication. Addressing FSD often requires a holistic and multidisciplinary approach, involving healthcare providers, therapists, and sometimes couples counseling to provide tailored solutions and support for women experiencing sexual difficulties.",
          },
        ],
      },
      {
        name: "Non-surgical conservative treatments",
        img: "../photo/ggg23.jpg",
        content: "Non-surgical conservative treatments refer to therapeutic...",
        bodyes: [
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Non-surgical conservative treatments refer to therapeutic approaches that do not involve surgical procedures and are aimed at managing various medical conditions or health concerns. These treatments focus on minimizing symptoms, improving well-being, and enhancing quality of life without the need for invasive interventions. Non-surgical conservative treatments are often considered as initial options or complementary strategies to surgery, particularly for conditions that can be managed effectively through other means.",
          },
          {
            img: "",
            name: "Examples of non-surgical conservative treatments include",
            ques: "1) Medications ",
            content:
              "Prescription or over-the-counter medications are commonly used to manage a wide range of conditions, from pain and inflammation to chronic diseases and infections.",
          },
          {
            img: "",
            name: "",
            ques: "2) Lifestyle Modifications ",
            content:
              "Changes in diet, exercise routines, sleep habits, stress management, and other lifestyle factors can have a positive impact on health and can help manage conditions like obesity, diabetes, and hypertension.",
          },
          {
            img: "",
            name: "",
            ques: "3) Physical Therapy ",
            content:
              "Physical therapists provide targeted exercises, stretches, and techniques to improve mobility, strength, and function for various musculoskeletal and neurological conditions.",
          },
          {
            img: "",
            name: "",
            ques: "4) Rehabilitation ",
            content:
              "Rehabilitation programs help patients recover from injuries, surgeries, or medical conditions by combining exercises, therapies, and education to restore physical and functional abilities.",
          },
          {
            img: "",
            name: "",
            ques: "",
            content:
              "Non-surgical conservative treatments are designed to tailor interventions to the individual's needs, promoting effective management of conditions and minimizing the need for surgical interventions when possible. It's important to consult a healthcare provider to determine the most appropriate treatment plan based on the specific condition and individual circumstances.",
          },
        ],
      },
    ],
  },
];
export default Fileofservice;
